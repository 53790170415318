.Navbar {
    width: 100%;
    height: 50px;
    box-shadow: 0 1px 5px 0px #0000003b;
    display: flex;
    .header {
        color: #4c6094;
        font-size: 18px;
        padding: 13px 18px;
        letter-spacing: 2px;
    }
    .username, .logout {
        font-size: 14px;
        color: #4964a8;
        display: flex;
        position: absolute;
        right: 80px;
        top: 13px;
        .c-icon {
            margin-left: 5px;
        }
    }
    .logout{
        position: absolute;
        right: 30px;
        top: 13px;
        cursor: pointer;
    }

}