$font-stack: 'Source Sans Pro', sans-serif;

@mixin width() {
  //iterate over all the columns
  @for $i from 1 through 100 {
      // generate column specific css class
      // width computed in percentage
      .w-#{$i} {
          width: unquote(($i) + '%');
      }
  }
}
@include width();

.app-container {
  height: 100%;
  display: flex;
  font-family: $font-stack;
  overflow: hidden;
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    .header {
      text-align: left;
      justify-content: left;
      width: 60%;
      h3 {
        
        margin: 0;
      }
    }
    .text-field {
      margin: 20px 0;
      width: 60%;
      .MuiFormControl-root {
        width: 100%;
      }
    }
    .btn-holder{
      text-align: center;
    }
  }
  .page-header {
    font-weight: 600;
    color: #48375e;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .btn {
    padding: 10px 20px;
    background: white;
    border: 0.5px solid #eae6e6;
    font-size: 12px;
    border-radius: 4px;
    margin: 0 10px;
    align-items: center;
    &.primary {
      background: #462365;
      color: white;
    }
  }
  .btn::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 100%;
    margin-left: -5px;
    vertical-align: middle;
}
  .d-flex {
    display: flex;
    flex-wrap: wrap;
  }
  [class*='-card'] {
    background: white;
    box-sizing:border-box;
    border-radius: 4px;
    box-shadow: 1px 1px 5px 1px #eae6e6cf;
    padding: 10px 15px;
  }
  .Data-Table {
    background: white;
    margin: 15px;
    width: calc(100% - 30px);
    box-shadow: 2px 2px 7px 1px #efebeb;
    border-collapse: collapse;
    border-radius: 5px;
    thead {
        color: #98aec7;
        th {
            width: calc(100%/8);
            font-weight: 600;
            font-size: 12px;
            padding: 20px 15px;
            text-align: left;
        }
    }
    tbody {
        td {
            padding: 15px;
            color: #45598f;
            font-size: 15px;
            cursor: pointer;
            width: fit-content;
        }
        tr:hover{
            background-color: #f7f8f9;
        } 
    }
    tr {
        border-bottom: 1px solid #ecf1f9;
    }
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  z-index: 998;
  $dotSize: 8px;
  &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.75);
      z-index: 1;
  }
  &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: calc(50% - (#{$dotSize} / 2));
      left: calc(50% - (#{$dotSize} / 2));
      margin-top: -.5em;
      width: $dotSize;
      height: $dotSize;
      vertical-align: middle;
      font-size: 10px;
      background: transparent;
      -webkit-animation: spinner 1s infinite linear;
      -moz-animation: spinner 1s infinite linear;
      -ms-animation: spinner 1s infinite linear;
      -o-animation: spinner 1s infinite linear;
      animation: spinner 1s infinite linear;
      border-radius: 100%;
      z-index: 2;
  }
}
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
      @content;
  }
  @-moz-keyframes #{$name} {
      @content;
  }
  @-ms-keyframes #{$name} {
      @content;
  }
  @keyframes #{$name} {
      @content;
  }
}

@include keyframes(spinner) {
  0% {
      -webkit-box-shadow: rgba(0, 0, 0, 0.80) 1.5em 0 0 0, rgba(0, 0, 0, 0.10) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.20) 0 1.5em 0 0, rgba(0, 0, 0, 0.30) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.40) -1.5em 0 0 0, rgba(0, 0, 0, 0.50) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.60) 0 -1.5em 0 0, rgba(0, 0, 0, 0.70) 1.1em -1.1em 0 0;
      box-shadow: rgba(0, 0, 0, 0.80) 1.5em 0 0 0, rgba(0, 0, 0, 0.10) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.20) 0 1.5em 0 0, rgba(0, 0, 0, 0.30) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.40) -1.5em 0 0 0, rgba(0, 0, 0, 0.50) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.60) 0 -1.5em 0 0, rgba(0, 0, 0, 0.70) 1.1em -1.1em 0 0;
  }
  14.3% {
      -webkit-box-shadow: rgba(0, 0, 0, 0.70) 1.5em 0 0 0, rgba(0, 0, 0, 0.80) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.10) 0 1.5em 0 0, rgba(0, 0, 0, 0.20) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.30) -1.5em 0 0 0, rgba(0, 0, 0, 0.40) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.50) 0 -1.5em 0 0, rgba(0, 0, 0, 0.60) 1.1em -1.1em 0 0;
      box-shadow: rgba(0, 0, 0, 0.70) 1.5em 0 0 0, rgba(0, 0, 0, 0.80) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.10) 0 1.5em 0 0, rgba(0, 0, 0, 0.20) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.30) -1.5em 0 0 0, rgba(0, 0, 0, 0.40) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.50) 0 -1.5em 0 0, rgba(0, 0, 0, 0.60) 1.1em -1.1em 0 0;
  }
  28.6% {
      -webkit-box-shadow: rgba(0, 0, 0, 0.60) 1.5em 0 0 0, rgba(0, 0, 0, 0.70) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.80) 0 1.5em 0 0, rgba(0, 0, 0, 0.10) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.20) -1.5em 0 0 0, rgba(0, 0, 0, 0.30) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.40) 0 -1.5em 0 0, rgba(0, 0, 0, 0.50) 1.1em -1.1em 0 0;
      box-shadow: rgba(0, 0, 0, 0.60) 1.5em 0 0 0, rgba(0, 0, 0, 0.70) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.80) 0 1.5em 0 0, rgba(0, 0, 0, 0.10) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.20) -1.5em 0 0 0, rgba(0, 0, 0, 0.30) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.40) 0 -1.5em 0 0, rgba(0, 0, 0, 0.50) 1.1em -1.1em 0 0;
  }
  42.9% {
      -webkit-box-shadow: rgba(0, 0, 0, 0.50) 1.5em 0 0 0, rgba(0, 0, 0, 0.60) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.70) 0 1.5em 0 0, rgba(0, 0, 0, 0.80) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.10) -1.5em 0 0 0, rgba(0, 0, 0, 0.20) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.30) 0 -1.5em 0 0, rgba(0, 0, 0, 0.40) 1.1em -1.1em 0 0;
      box-shadow: rgba(0, 0, 0, 0.50) 1.5em 0 0 0, rgba(0, 0, 0, 0.60) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.70) 0 1.5em 0 0, rgba(0, 0, 0, 0.80) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.10) -1.5em 0 0 0, rgba(0, 0, 0, 0.20) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.30) 0 -1.5em 0 0, rgba(0, 0, 0, 0.40) 1.1em -1.1em 0 0;
  }
  57.2% {
      -webkit-box-shadow: rgba(0, 0, 0, 0.40) 1.5em 0 0 0, rgba(0, 0, 0, 0.50) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.60) 0 1.5em 0 0, rgba(0, 0, 0, 0.70) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.80) -1.5em 0 0 0, rgba(0, 0, 0, 0.10) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.20) 0 -1.5em 0 0, rgba(0, 0, 0, 0.30) 1.1em -1.1em 0 0;
      box-shadow: rgba(0, 0, 0, 0.40) 1.5em 0 0 0, rgba(0, 0, 0, 0.50) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.60) 0 1.5em 0 0, rgba(0, 0, 0, 0.70) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.80) -1.5em 0 0 0, rgba(0, 0, 0, 0.10) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.20) 0 -1.5em 0 0, rgba(0, 0, 0, 0.30) 1.1em -1.1em 0 0;
  }
  71.5% {
      -webkit-box-shadow: rgba(0, 0, 0, 0.30) 1.5em 0 0 0, rgba(0, 0, 0, 0.40) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.50) 0 1.5em 0 0, rgba(0, 0, 0, 0.60) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.70) -1.5em 0 0 0, rgba(0, 0, 0, 0.80) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.10) 0 -1.5em 0 0, rgba(0, 0, 0, 0.20) 1.1em -1.1em 0 0;
      box-shadow: rgba(0, 0, 0, 0.30) 1.5em 0 0 0, rgba(0, 0, 0, 0.40) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.50) 0 1.5em 0 0, rgba(0, 0, 0, 0.60) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.70) -1.5em 0 0 0, rgba(0, 0, 0, 0.80) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.10) 0 -1.5em 0 0, rgba(0, 0, 0, 0.20) 1.1em -1.1em 0 0;
  }
  85.8% {
      -webkit-box-shadow: rgba(0, 0, 0, 0.20) 1.5em 0 0 0, rgba(0, 0, 0, 0.30) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.40) 0 1.5em 0 0, rgba(0, 0, 0, 0.50) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.60) -1.5em 0 0 0, rgba(0, 0, 0, 0.70) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.80) 0 -1.5em 0 0, rgba(0, 0, 0, 0.10) 1.1em -1.1em 0 0;
      box-shadow: rgba(0, 0, 0, 0.20) 1.5em 0 0 0, rgba(0, 0, 0, 0.30) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.40) 0 1.5em 0 0, rgba(0, 0, 0, 0.50) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.60) -1.5em 0 0 0, rgba(0, 0, 0, 0.70) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.80) 0 -1.5em 0 0, rgba(0, 0, 0, 0.10) 1.1em -1.1em 0 0;
  }
  100% {
      -webkit-box-shadow: rgba(0, 0, 0, 0.10) 1.5em 0 0 0, rgba(0, 0, 0, 0.20) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.30) 0 1.5em 0 0, rgba(0, 0, 0, 0.40) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.50) -1.5em 0 0 0, rgba(0, 0, 0, 0.60) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.70) 0 -1.5em 0 0, rgba(0, 0, 0, 0.80) 1.1em -1.1em 0 0;
      box-shadow: rgba(0, 0, 0, 0.10) 1.5em 0 0 0, rgba(0, 0, 0, 0.20) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.30) 0 1.5em 0 0, rgba(0, 0, 0, 0.40) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.50) -1.5em 0 0 0, rgba(0, 0, 0, 0.60) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.70) 0 -1.5em 0 0, rgba(0, 0, 0, 0.80) 1.1em -1.1em 0 0;
  }
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}