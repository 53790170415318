.Toastify__toast-container {
    min-width: 500px;
}
.Toastify__toast-container--top-center {
  top: 4em;
}
.Toastify__toast {
  min-height: 0!important;
  height: 40px!important;
  padding: 0!important;
  min-width: 500px;
  border-radius: 5px;
}
.Toastify__toast-body {
  margin: 0!important;
}
.toast {
  display: flex;
  height: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #19a4ff, #1e80f4);
  .toast--center {
    display: flex;
    align-items: center;
  }
  .toast__message {
    width: 83%;
    padding: 0 10px;
    font-size: 14px;
  }
  .toast__message {
    color: white;
  }
  .c-icon {
    width: 1.3em;
    height: 1.3em;
    .icon {
      background: white;
    }
  }
  &.info {
    background: linear-gradient(to right, #4f547f, #222960);
  }
  &.error {
    background: linear-gradient(to right, #ff5050, #ff0000);
  }
  .toast--close {
    width: 10%;
  }
}
