.add-user {
  padding: 20px 9%;

  // position: relative;
  .content-area {
    display: flex;
    .form-navbar {
      width: 30%;
      // position: absolute;
      .navbar-info {
        color: #a5a5a5;
        font-size: 12px;
        padding-bottom: 10px;
        border-bottom: 0.5px solid #ececec;
      }
      .csv-dropzone {
        height: 185px;
        border: 2px dashed rgb(221, 221, 221);
        .drop-zone {
          padding: 50px 30px;
          .upload-instruction {
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
      .csv-dropzone-solid {
        height: 185px;
        display: flex;
        img {
          width: 220px;
        }
        .c-icon {
          height: 3em;
          width: 3em;
          margin-top: 33px;
        }
        .file-content {
          margin-top: 45px;
        }
        .close {
          background: #3f4054;
          /* margin-top: 38px; */
          height: 7px;
          width: 7px;
          padding: 7px;
          border-radius: 20px;
          cursor: pointer;
          position: relative;
          left: -24px;
          cursor: pointer;
          .c-icon {
            margin-top: 0;
            width: 21px;
            height: 17px;
            position: relative;
            top: -7px;
          }
        }
      }
    }
    .form-area {
      // position: relative;
      // left: 30%;
      width: 70%;
      padding: 0 20px;
      .form-holder {
        background: white;
        padding: 20px;
        box-shadow: 1px 1px 5px 1px #eae6e6cf;
        margin-bottom: 25px;
        height: 'fit-content';
        .form-holder-header {
          color: #46305c;
          font-weight: 600;
          font-size: 14px;
          padding-bottom: 15px;
          border-bottom: 0.5px solid #efefef;
          margin-bottom: 10px;
        }
        .MuiFormControl-root {
          width: 48%;
          margin: 2% 1%;
        }
        .MuiFormLabel-root {
          color: #959595;
          font-size: 13px;
        }
        .add-extra-value {
          width: 100%;
          display: inline-block;
          button {
            float: right;
          }
        }
      }
    }
  }
  .button-holder {
    width: 70%;
    margin-left: 30%;
    margin-bottom: 15px;
    text-align: center;
  }
  .id__card {
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    width: 70%;
  }
}
