body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (prefers-reduced-motion: reduce) {
	.animated {
		animation: none !important;
	}
}

html {
	zoom: var(--rzf, 1);
	font-size: max(calc(min(var(--1vw, 1vw), 13.66px) * var(--rfso, 1)), var(--minfs, 0px));
	-webkit-text-size-adjust: 100%;
	scroll-behavior: smooth;
}

body {
	font-size: calc(1rem * var(--bfso, 1));
}

body,
html,
p,
ul,
ol,
li {
	margin: 0;
	padding: 0;
	font-synthesis: none;
	font-kerning: none;
	font-variant-ligatures: none;
	font-feature-settings: "kern" 0, "calt" 0, "liga" 0, "clig" 0, "dlig" 0, "hlig" 0;
	font-family: unset;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: geometricprecision;
	white-space: normal;
}

li {
	text-align: unset;
}

a {
	text-decoration: none;
	color: inherit;
}

img {
	-webkit-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
	-webkit-touch-callout: none;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: tamil1;
	src: url(fonts/BAMINI-Tamil46.ttf);
}
@font-face {
	font-family: tamil2;
	src: url(fonts/BAMINI-Tamil07.ttf);
}
@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YACgEZulLoA-0;
	src: url(fonts/0f08785b1afcac54573a110a7a6093b4.woff2);
	font-style: italic;
	font-weight: 900;
}

/* @font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YAF7tdKaM0U-0;
	src: url(fonts/5bf812d99c38e8032343a3a809c06b05.woff);
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/909d79c023e08588616f54ccedbeff8b.woff2);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/5eaa1f75db5780490f062b34ca329e3c.woff2);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/391d1d817dabc982d4eeb61445e74bd3.woff2);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/184e323bd609d58952bbf6ef4d5c029b.woff2);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/79189c17fe5fc7c57cd0ad44a5c240f2.woff2);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/a535601aac029687b86e2ab6a84d2ac8.woff2);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/79189c17fe5fc7c57cd0ad44a5c240f2.woff2);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/a535601aac029687b86e2ab6a84d2ac8.woff2);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/909d79c023e08588616f54ccedbeff8b.woff2);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/391d1d817dabc982d4eeb61445e74bd3.woff2);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/7a6ff51c2c4dbd5694b8f012df585b40.woff2);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/afc41ac69ffa0929c6477c77fb7364f7.woff2);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/7a6ff51c2c4dbd5694b8f012df585b40.woff2);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/afc41ac69ffa0929c6477c77fb7364f7.woff2);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/5b2e989d3fbda56d0e9089d41d98a0d1.woff2);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/839fb8c1ed23ef4219b474ebda9696ba.woff2);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/2602fce6f3ec66d466764f0d76def0d7.woff2);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YAFdJpYuLss-0;
	src: url(fonts/7ab1c334a3ab8ced39f0b202ce734ef6.woff2);
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/5505f71211e4b034e8c772ad6db3aad7.woff2);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/78e74164a4ee09afd0188f20b378a44f.woff2);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/eef94b629faf5f33099e30d44d73719a.woff2);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/59f4bb6e7f28db8920c69494d4d715f2.woff2);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/5505f71211e4b034e8c772ad6db3aad7.woff2);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/eef94b629faf5f33099e30d44d73719a.woff2);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/5505f71211e4b034e8c772ad6db3aad7.woff2);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/eef94b629faf5f33099e30d44d73719a.woff2);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/5505f71211e4b034e8c772ad6db3aad7.woff2);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/eef94b629faf5f33099e30d44d73719a.woff2);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/5505f71211e4b034e8c772ad6db3aad7.woff2);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/eef94b629faf5f33099e30d44d73719a.woff2);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/78e74164a4ee09afd0188f20b378a44f.woff2);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/59f4bb6e7f28db8920c69494d4d715f2.woff2);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/78e74164a4ee09afd0188f20b378a44f.woff2);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/59f4bb6e7f28db8920c69494d4d715f2.woff2);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/78e74164a4ee09afd0188f20b378a44f.woff2);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YAEnTI0o408-0;
	src: url(fonts/59f4bb6e7f28db8920c69494d4d715f2.woff2);
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/88a36cab6d7cfa75bff7e49535b9642a.woff2);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/c19f8e9cab364abc00a750036f731e21.woff2);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/2939e26d0be644944cd621be7bec5f5e.woff2);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/267ca74019c66328f093f6614fe3106e.woff2);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/22ff9d894b6467b3ddab0f1989a2cb4d.woff2);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/aeba314cd216c72b97019a6e8c17c9a5.woff2);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/22ff9d894b6467b3ddab0f1989a2cb4d.woff2);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/aeba314cd216c72b97019a6e8c17c9a5.woff2);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/22ff9d894b6467b3ddab0f1989a2cb4d.woff2);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/aeba314cd216c72b97019a6e8c17c9a5.woff2);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/6f953f3e7ac9d894a97d59a06afcfa7b.woff2);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/b146a0e8f0e873592940bcc60a355c0c.woff2);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/3dae3800fab4aad097008bba4dfa94c1.woff2);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/0f51da86a058aef1bb585a64cf664ab7.woff2);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/c19f8e9cab364abc00a750036f731e21.woff2);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/267ca74019c66328f093f6614fe3106e.woff2);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/71d66fa3f70c070b9427b4baf1980061.woff2);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YAFdJs2qTWQ-0;
	src: url(fonts/1aaf8c95b881644a76961b6db0059fc0.woff2);
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YAF7tSm1pQk-0;
	src: url(fonts/ff5b86182ab65863f7421eada7725718.woff);
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/0d6b73825ffb53723442c5660e87b4d4.woff2);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/d769594df7501703a01b15c58fc23317.woff2);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/0d6b73825ffb53723442c5660e87b4d4.woff2);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/d769594df7501703a01b15c58fc23317.woff2);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/0d6b73825ffb53723442c5660e87b4d4.woff2);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/0d6b73825ffb53723442c5660e87b4d4.woff2);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/0d6b73825ffb53723442c5660e87b4d4.woff2);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/0d6b73825ffb53723442c5660e87b4d4.woff2);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/0d6b73825ffb53723442c5660e87b4d4.woff2);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/0d6b73825ffb53723442c5660e87b4d4.woff2);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/0d6b73825ffb53723442c5660e87b4d4.woff2);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/0d6b73825ffb53723442c5660e87b4d4.woff2);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/d769594df7501703a01b15c58fc23317.woff2);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/d769594df7501703a01b15c58fc23317.woff2);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/d769594df7501703a01b15c58fc23317.woff2);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/d769594df7501703a01b15c58fc23317.woff2);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/d769594df7501703a01b15c58fc23317.woff2);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YAEnl21zi4U-0;
	src: url(fonts/d769594df7501703a01b15c58fc23317.woff2);
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/127f1f8810cbf9b7088c5a9bd8005fe3.woff2);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/569086aaaf15784082758d6a39803022.woff2);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/7dc66bd302ac04c9bf96320650ec1e6d.woff2);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/86d1bc8611ae370c27a8dc6f32c38066.woff2);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/127f1f8810cbf9b7088c5a9bd8005fe3.woff2);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/7dc66bd302ac04c9bf96320650ec1e6d.woff2);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/127f1f8810cbf9b7088c5a9bd8005fe3.woff2);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/7dc66bd302ac04c9bf96320650ec1e6d.woff2);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/127f1f8810cbf9b7088c5a9bd8005fe3.woff2);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/7dc66bd302ac04c9bf96320650ec1e6d.woff2);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/127f1f8810cbf9b7088c5a9bd8005fe3.woff2);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/7dc66bd302ac04c9bf96320650ec1e6d.woff2);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/569086aaaf15784082758d6a39803022.woff2);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/86d1bc8611ae370c27a8dc6f32c38066.woff2);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/569086aaaf15784082758d6a39803022.woff2);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/86d1bc8611ae370c27a8dc6f32c38066.woff2);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/569086aaaf15784082758d6a39803022.woff2);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YAEp1FLgsF4-0;
	src: url(fonts/86d1bc8611ae370c27a8dc6f32c38066.woff2);
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/fcad22b874bce0ba133e1b1e9797c3e3.woff2);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/6b462484c686aa8f47e71be42e6f26f1.woff2);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/dbf66f33848d8315d3fa51e99aee937a.woff2);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/ceee53df4fd787bb99eb1046e552b74f.woff2);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/00c4688d4b467f8dfcc5d8ece4195d41.woff2);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/64241596f82ab473af75a637ffde07a6.woff2);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/00c4688d4b467f8dfcc5d8ece4195d41.woff2);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/64241596f82ab473af75a637ffde07a6.woff2);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/fcad22b874bce0ba133e1b1e9797c3e3.woff2);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/dbf66f33848d8315d3fa51e99aee937a.woff2);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/60e4070f365761ce27b9a7704adb9eab.woff2);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/4c4749741d1c0ff30b4579b4fa1dae34.woff2);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/60e4070f365761ce27b9a7704adb9eab.woff2);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/4c4749741d1c0ff30b4579b4fa1dae34.woff2);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/6b462484c686aa8f47e71be42e6f26f1.woff2);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/ceee53df4fd787bb99eb1046e552b74f.woff2);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/6b462484c686aa8f47e71be42e6f26f1.woff2);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YAFdJgT0SHI-0;
	src: url(fonts/ceee53df4fd787bb99eb1046e552b74f.woff2);
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/4b6890e1422453d3538b732a24753ed0.woff2);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/a063712d2d6b6c2872c8724cdbb1efb2.woff2);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/82cd42a609ba2c92272aa783d5d1e719.woff2);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/e55231b0b04c4c3b32e17fcae1a25477.woff2);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/f210a598c81acb1d4f7ce01caa275605.woff2);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/a06fa221d1d2a3079fbfbd4ba38e5256.woff2);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/f210a598c81acb1d4f7ce01caa275605.woff2);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/a06fa221d1d2a3079fbfbd4ba38e5256.woff2);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/4b6890e1422453d3538b732a24753ed0.woff2);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/0b6d942c174e3c058f8c4d2ca6b45ab3.woff2);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/4b6890e1422453d3538b732a24753ed0.woff2);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/82cd42a609ba2c92272aa783d5d1e719.woff2);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/a063712d2d6b6c2872c8724cdbb1efb2.woff2);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/e55231b0b04c4c3b32e17fcae1a25477.woff2);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/32972da5276b387beae185e05b074628.woff2);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/c53415d55d8cade60fcf8723f74e8c38.woff2);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/32972da5276b387beae185e05b074628.woff2);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YAFdJrHPPtU-0;
	src: url(fonts/c53415d55d8cade60fcf8723f74e8c38.woff2);
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/13abc4aa428d934969f83c15587bfc3e.woff2);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/cb59888f099d89d51e2ed6762a679388.woff2);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/33738800351bea07c2dc36014d7ffe9d.woff2);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/492a3949a13c21311cce376ef8ced128.woff2);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/0c5466a6eb40c17fbdc7460391fd4f27.woff2);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/ab84b76d6de61d51149fd4ea44a98c61.woff2);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/33738800351bea07c2dc36014d7ffe9d.woff2);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/3eb361279dd6cd4aa51b9d1976b1f9c0.woff2);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/33738800351bea07c2dc36014d7ffe9d.woff2);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/3eb361279dd6cd4aa51b9d1976b1f9c0.woff2);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/13abc4aa428d934969f83c15587bfc3e.woff2);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/33738800351bea07c2dc36014d7ffe9d.woff2);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/cb59888f099d89d51e2ed6762a679388.woff2);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/492a3949a13c21311cce376ef8ced128.woff2);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/868177480f9f833dd0ce337cac894d25.woff2);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/73484a006eb24289afe33612e2cd5252.woff2);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/57e4bac473055a2ca5a20bfd316cdb3d.woff2);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YAFdJkQTgbI-0;
	src: url(fonts/4f615b6ab1e876ba0c3d7077924e5696.woff2);
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/881b8da5ad9b82b143ab37dcdf069c4c.woff2);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/527cd5a6be21d4e008281f52ae03e6de.woff2);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/e8e51b9875286101e41224d1f8f57146.woff2);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/ccfa3463500b1d2425324b3a1a304119.woff2);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/b9d3823184fbb6d5a9d5804d114b70ba.woff2);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/b33f6386bd461ec492dd56577e13136c.woff2);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/b09eaaf9341f54a150766b674dd4dd66.woff2);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/c076bd8c37058ebdedabdda5c2d6b111.woff2);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/9f211f1e580dd0c34c98242b67b454a1.woff2);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/2ffd130498e37c9f925c671e0342f1e6.woff2);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/b13d468f88f904752a71651083120b9b.woff2);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/2f9ca4e3b153c05839a1665e2deae5bd.woff2);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/10bae089b2dee90878d1d146921cad11.woff2);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/5300a700676e268371e3693408559b01.woff2);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/5da2f7e7b5289e2ae1f44d0ac2886371.woff2);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/b76554414c3fa738aad9e680f54c765d.woff2);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/5c07b773952ecd9eb4715de302811a45.woff2);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YAFdtQi73Xs-0;
	src: url(fonts/82e327672f883fc5445ed4de237127fd.woff2);
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/f8f199f09526f79e87644ed227e0f651.woff2);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/98c4d2c0223fc8474641c77f923528e9.woff2);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/d257a7100844bc3f98c9021168b6249e.woff2);
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/1060345c54d396e76d73f1da7ee200bd.woff2);
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/f8f199f09526f79e87644ed227e0f651.woff2);
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/d257a7100844bc3f98c9021168b6249e.woff2);
	font-style: italic;
	font-weight: 100;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/f8f199f09526f79e87644ed227e0f651.woff2);
	font-style: normal;
	font-weight: 200;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/d257a7100844bc3f98c9021168b6249e.woff2);
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/f8f199f09526f79e87644ed227e0f651.woff2);
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/d257a7100844bc3f98c9021168b6249e.woff2);
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/f8f199f09526f79e87644ed227e0f651.woff2);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/d257a7100844bc3f98c9021168b6249e.woff2);
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/98c4d2c0223fc8474641c77f923528e9.woff2);
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/1060345c54d396e76d73f1da7ee200bd.woff2);
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/98c4d2c0223fc8474641c77f923528e9.woff2);
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/1060345c54d396e76d73f1da7ee200bd.woff2);
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/98c4d2c0223fc8474641c77f923528e9.woff2);
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: YACgEZ1cb1Q-0;
	src: url(fonts/1060345c54d396e76d73f1da7ee200bd.woff2);
	font-style: italic;
	font-weight: 900;
} */

@media (max-width: 375px) {
	#z2W0t9LO5y0FgQWr {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#SL9cu5l5y0CTwRB4 {
		grid-template-columns: 0 417.3338694%;
		left: -158.6669347%;
		grid-template-rows: 0 100%;
	}

	#iPU6c5lFlFiI4WQx {
		font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), calc(2.84445333em - var(--ffsd)));
	}

	#SQw9hne1KHFmnPeO {
		--first-font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), 2.84445333em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#qGOK3VZjCeecOc2P {
		min-width: 15.11121613rem;
	}

	#NJRAWD1XDTwmEvpu {
		grid-area: 2 / 12 / 4 / 16;
		position: relative;
	}

	#NG5fK23CeU4hquvT {
		font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), calc(2.84445333em - var(--ffsd)));
	}

	#RYk5nRdTfy6ghNrP {
		--first-font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), 2.84445333em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#yXwa2mb3SldnynWe {
		min-width: 21.16831828rem;
	}

	#lGhmYDkXcM7f0GRU {
		grid-area: 3 / 17 / 5 / 24;
		position: relative;
	}

	#X18nBTuSzk3edEEL {
		font-size: calc(2.84445333em - var(--ffsd));
	}

	#u8V3KhdVr5emw0QB {
		--first-font-size: 2.84445333em;
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#h55kBAYDwPDbvnyF {
		min-width: 11.73644711rem;
	}

	#n5vKQHfYbPI0DpT4 {
		grid-area: 6 / 10 / 8 / 13;
		position: relative;
	}

	#uGUwMlcLNrFRmbfk {
		font-size: calc(2.84445333em - var(--ffsd));
	}

	#gUi6RKbWsjEA1j6H {
		--first-font-size: 2.84445333em;
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#TACHjxHiq8ZGGcif {
		min-width: 52.92199978rem;
	}

	#N7rN3NSSYMuShTjx {
		grid-area: 7 / 11 / 9 / 26;
		position: relative;
	}

	#Du8Ium0Vz4SQucN5 {
		grid-area: 10 / 14 / 11 / 23;
		position: relative;
	}

	#mpywhKD1DfgwfA57 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.40444267em - var(--ffsd)));
	}

	#LJWAD7W2Vy2MyEXR {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.40444267em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#pWiO0UiOKN5cFTrY {
		min-width: 91.6rem;
	}

	#GK7xmNvRcJq3bFP7 {
		grid-area: 12 / 2 / 13 / 27;
		position: relative;
	}

	#p9KIzIrs63ntBWqD {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.4em - var(--ffsd)));
	}

	#cCeEHicukaekaMlL {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.4em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#JDp9Rf0T8htahMtZ {
		min-width: 91.6rem;
	}

	#i9oe8d3Evx1TMcmk {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#WA8YdHf3se5bPZ2S {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.52079961em - var(--ffsd)));
	}

	#jxiPMGfYUmhtOpQ7 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.52079961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#rE7BUE1FKSY2WRFm {
		min-width: 91.6rem;
	}

	#lvv5wDelwCqsVXyx {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#QXaeiBUaUbPUHBcN {
		grid-area: 14 / 3 / 15 / 28;
		grid-template-columns: 0 0 91.46666667rem 0;
		grid-template-rows: 0 minmax(7.46666667rem, max-content) minmax(6.4rem, max-content) minmax(7.77838239rem, max-content);
	}

	#TxIwfBhHAvKZFMNQ {
		grid-area: 14 / 3 / 15 / 28;
		position: relative;
	}

	#iY93JfbuXazRIRcr {
		grid-area: 16 / 15 / 17 / 22;
		position: relative;
	}

	#HmV1sKHCsxFDFLKN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(12.8em - var(--ffsd)));
	}

	#zDiUMWCVlE67rNVl {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 12.8em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.068));
		margin-top: calc(var(--first-font-size) * -0.125);
		margin-bottom: calc(var(--last-font-size) * -0.125);
	}

	#vpFqBZe5ZomsHCMJ {
		min-width: 91.6rem;
	}

	#LStX5ZKEr57y950h {
		grid-area: 18 / 4 / 19 / 29;
		position: relative;
	}

	#i8lQhV5BBOWvqgZN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(12.8em - var(--ffsd)));
	}

	#tLI0tPlrLmGOu16t {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 12.8em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.0405));
		margin-top: calc(var(--first-font-size) * -0.125);
		margin-bottom: calc(var(--last-font-size) * -0.125);
	}

	#G1G7iCYeTi4AzcZC {
		min-width: 91.6rem;
	}

	#bIOwFZ0ypWmOBhXD {
		grid-area: 20 / 5 / 21 / 30;
		position: relative;
	}

	#YAmPSU77N2RJl4eA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.97778667em - var(--ffsd)));
	}

	#Oa8LdBBjIm5fsdV8 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.97778667em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#lp7dJl6SjNX87ORs {
		min-width: 50.72916667rem;
	}

	#VdkX9cESQH4i2k40 {
		grid-area: 23 / 6 / 24 / 19;
		position: relative;
	}

	#jNYg8u9bxzq2JDKz {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.11112em - var(--ffsd)));
	}

	#ls0rVOMmpEllDERc {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.11112em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#NAlqVZoTiEShReua {
		min-width: 19.95914635rem;
	}

	#BoTYHdTPWrLpcyE5 {
		grid-area: 22 / 20 / 25 / 25;
		position: relative;
	}

	#FC92Yx3X7HcMrmUQ {
		grid-area: 26 / 9 / 30 / 31;
		position: relative;
	}

	#WsUk8J16AAC9Ru2i {
		grid-area: 27 / 7 / 29 / 18;
		position: relative;
	}

	#yvFwVFLgHWnfiHHi {
		grid-area: 28 / 8 / 31 / 21;
		position: relative;
	}

	#io8xHR69844PRI5U {
		grid-template-columns: 4.26666667rem 0 0 0 0 0 6.18968093rem 7.43040931rem 2.21248567rem 7.01284839rem 1.68147516rem 2.90879022rem 9.33560738rem 0.5220199rem 2.21146529rem 6.4rem 0.44891527rem 4.24213581rem 0 1.94754512rem 1.62997124rem 0.5220199rem 12.24439761rem 3.48187915rem 5.2124444rem 15.83257591rem 0 0 0 0 4.26666667rem;
		grid-template-rows: minmax(5.24023728rem, max-content) 0 minmax(3.22371378rem, max-content) 0 minmax(6.4rem, max-content) 0 minmax(3.22371378rem, max-content) 0 minmax(7.72831834rem, max-content) minmax(17.98889738rem, max-content) minmax(6.4rem, max-content) minmax(3.73317183rem, max-content) minmax(1.31521755rem, max-content) minmax(21.64504906rem, max-content) minmax(6.4rem, max-content) minmax(16.10599243rem, max-content) minmax(13.77811579rem, max-content) minmax(15.2rem, max-content) minmax(6.4rem, max-content) minmax(15.2rem, max-content) minmax(2.02750757rem, max-content) minmax(1.33333333rem, max-content) minmax(5.86666667rem, max-content) minmax(1.33333333rem, max-content) minmax(6.4rem, max-content) minmax(12.51345851rem, max-content) minmax(0.27569409rem, max-content) minmax(46.33222847rem, max-content) minmax(0.27569409rem, max-content) 0 minmax(5.24023728rem, max-content);
	}

	#u3SFqEqUiq3Yd9AS {
		min-height: calc(8.81956255 * var(--1vh, 1vh));
	}

	#fSeIbSOK25LfAyr9 {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#oxBQLPEYTzQnRNow {
		grid-template-columns: 0 306.64760969%;
		left: -103.32380485%;
		grid-template-rows: 0 100%;
	}

	#K4GjhCJlLhRsOHpE {
		grid-area: 2 / 8 / 3 / 10;
		position: relative;
	}

	#LjGKy3aqwD5f0kvO {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.03282667em - var(--ffsd)));
	}

	#Cy3Bx7uQW1irPtaW {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.03282667em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.015));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Xfkrn6HXr1JZUQoh {
		min-width: 84.05358037rem;
	}

	#ldgibYz5xXvUb0Rt {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#zzVPHxti09lpPp1q {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.69322667em - var(--ffsd)));
	}

	#S4VRXPXyi8rvnwCL {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.69322667em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#LLDeg5iIHwe3XlTN {
		min-width: 84.05358037rem;
	}

	#ckBv54l5HJrE5emB {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#hC7hJ5xZDnCcU29U {
		grid-template-columns: 0 0 83.92024704rem 0;
		grid-template-rows: 0 minmax(8.19343467rem, max-content) minmax(3.11940612rem, max-content) minmax(6.594688rem, max-content);
	}

	#GZRDoTechanZzenm {
		grid-area: 4 / 5 / 5 / 12;
		position: relative;
	}

	#BAjQbjJ9VkJtrsgg {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.40444267em - var(--ffsd)));
	}

	#YzmjkTygXoFPnsZE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.40444267em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#J55E6XMFl1MtYrvm {
		min-width: 91.6rem;
	}

	#QSANUOBl4gQFbcpZ {
		grid-area: 6 / 2 / 7 / 13;
		position: relative;
	}

	#RhjdCAi1nq6z8qTW {
		grid-area: 8 / 3 / 9 / 14;
		position: relative;
	}

	#AKeXGtwqL9JUwDFh {
		grid-area: 10 / 7 / 11 / 9;
		position: relative;
	}

	#YAWsPgICRzktSn4r {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.03282667em - var(--ffsd)));
	}

	#SN3hAOnt3jCuw0R6 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.03282667em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.015));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#wWkeSV5YmODu4QGB {
		min-width: 71.86252529rem;
	}

	#tqIWfLEWsUWNb7Md {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#N70HGv8vxl1N2K22 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.69322667em - var(--ffsd)));
	}

	#o4iJlppSTa6Pl1cE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.69322667em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#CDqOtP9HmgK4cCL5 {
		min-width: 71.86252529rem;
	}

	#bpR1AVV9gcXSUIxq {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#W7BJ9Ea8WABbO7iA {
		grid-template-columns: 0 0 71.72919196rem 0;
		grid-template-rows: 0 minmax(8.19343467rem, max-content) minmax(3.11940612rem, max-content) minmax(6.594688rem, max-content);
	}

	#P9GlGLj3YwKgduri {
		grid-area: 12 / 6 / 13 / 11;
		position: relative;
	}

	#v2sELbSjXLn37lbM {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.40444267em - var(--ffsd)));
	}

	#MKClK3FDAeuDU0wa {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.40444267em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#v2QlpyFCJWkdSM91 {
		min-width: 91.6rem;
	}

	#x17FaXp1woE5u6dY {
		grid-area: 14 / 4 / 15 / 15;
		position: relative;
	}

	#Zpybw2QrKoj7XLAc {
		grid-template-columns: 4.26666667rem 0 0 3.77320981rem 5.58605036rem 14.25203461rem 0.50947718rem 42.71564555rem 0.50947718rem 13.74255744rem 6.60500472rem 3.77320981rem 0 0 4.26666667rem;
		grid-template-rows: minmax(11.52685622rem, max-content) minmax(68.72354369rem, max-content) minmax(5.50873796rem, max-content) minmax(17.90752879rem, max-content) minmax(3.73333333rem, max-content) minmax(12.46311933rem, max-content) minmax(9.376986rem, max-content) minmax(1.60066667rem, max-content) minmax(13.80648067rem, max-content) minmax(68.72354369rem, max-content) minmax(5.50873796rem, max-content) minmax(17.90752879rem, max-content) minmax(3.73333333rem, max-content) minmax(15.98879856rem, max-content) minmax(11.52685622rem, max-content);
	}

	#xONz65JYPuhP63HY {
		min-height: calc(10.20794021 * var(--1vh, 1vh));
	}

	#WMNyFVvdW8JDT3mq {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#lNDWuvQZOk10eZIb {
		grid-template-columns: 0 787.53529895%;
		left: -343.76764948%;
		grid-template-rows: 0 100%;
	}

	#GWKBOWWbMlMRE34y {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.902224em - var(--ffsd)));
	}

	#gGhy3uiz4vkiQuRd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.902224em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#fQuImrbrFCP5809S {
		min-width: 91.6rem;
	}

	#KuCcgaAAVItGKIlN {
		grid-area: 2 / 2 / 3 / 19;
		position: relative;
	}

	#w38lT8HHxpTCtpO0 {
		grid-area: 4 / 15 / 5 / 17;
		position: relative;
	}

	#PzoWd5qDgVaLmUDZ {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.27114586em - var(--ffsd)));
	}

	#QPGnTaypMoAPltwt {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.27114586em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#hzvp0OYD4RCo7uEv {
		min-width: 91.6rem;
	}

	#IrHTNhp1QPNAKOhS {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#B9n7zRcs29V0crDQ {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5743722em - var(--ffsd)));
	}

	#NbIX2BmwIHowCLEB {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5743722em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#iBASHVu12sWYgEA5 {
		min-width: 91.6rem;
	}

	#rObDKWScOEXbYZWx {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#d2nPwGoAmEq2u0uY {
		grid-area: 6 / 3 / 7 / 20;
		grid-template-columns: 0 0 91.46666667rem 0;
		grid-template-rows: 0 minmax(7.36856046rem, max-content) minmax(1.89036625rem, max-content) minmax(6.46272857rem, max-content);
	}

	#FwOEASN75NL9LIvV {
		grid-area: 6 / 3 / 7 / 20;
		position: relative;
	}

	#ZSRJPzhhA4UvXUXg {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#fumR30kYPtTqjQ7h {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.27114586em - var(--ffsd)));
	}

	#eJ0nuio3tOE3eLlK {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.27114586em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#fiDbBM8aiSeoBZTJ {
		min-width: 91.6rem;
	}

	#vwLPE9pIxvZev4hm {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#N5r1DfopvjC11lWp {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5743722em - var(--ffsd)));
	}

	#cbrdsrvGgor9AVFQ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5743722em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#wRhGvAHLO2UPdJVv {
		min-width: 91.6rem;
	}

	#K8i8CthCuAW2RNVt {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#ubW4cp95SqILM3ze {
		grid-area: 8 / 4 / 9 / 21;
		grid-template-columns: 0 0 22.63933906rem 46.18798854rem 22.63933906rem 0;
		grid-template-rows: 0 minmax(46.18780379rem, max-content) minmax(6.4rem, max-content) minmax(7.36856046rem, max-content) minmax(1.89036625rem, max-content) minmax(6.46272857rem, max-content);
	}

	#C9SXFJ76ZOq9YNLA {
		grid-area: 8 / 4 / 9 / 21;
		position: relative;
	}

	#sz2N5padSkeqtao1 {
		grid-area: 10 / 16 / 11 / 18;
		position: relative;
	}

	#ouKG2QSRbQ1FNpl5 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.27114586em - var(--ffsd)));
	}

	#F24kL3rMx9bV6DWO {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.27114586em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#SAmtaymZzgUuj19C {
		min-width: 91.6rem;
	}

	#FMbqVJ1UD4kpThwJ {
		grid-area: 12 / 5 / 13 / 22;
		position: relative;
	}

	#LkfhKA5raUua92yA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5743722em - var(--ffsd)));
	}

	#qTRcZhmLjAMOPuFK {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5743722em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#X79zQPuPWaOeSbE0 {
		min-width: 91.6rem;
	}

	#EUF1plnsAfhFY5RZ {
		grid-area: 14 / 6 / 15 / 23;
		position: relative;
	}

	#pFXXZ92FZMvZaXz8 {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#CxXylKhIAu0bJefE {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.27114586em - var(--ffsd)));
	}

	#tkVhyhsakD1AvZFY {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.27114586em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#PXhSK1K0HwBWdIqu {
		min-width: 91.6rem;
	}

	#UVCSVdyuM0GGAXVs {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#l6ITaRwTVeHdFnXq {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5743722em - var(--ffsd)));
	}

	#di4U2TujvKayvhoE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5743722em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#JJWbfo5XyQBWA34h {
		min-width: 91.6rem;
	}

	#LWy405G9NYzJL9s0 {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#NZGOgBKl8st2sJvf {
		grid-area: 16 / 7 / 17 / 24;
		grid-template-columns: 0 0 22.63933906rem 46.18798854rem 22.63933906rem 0;
		grid-template-rows: 0 minmax(46.18780379rem, max-content) minmax(6.4rem, max-content) minmax(7.36856046rem, max-content) minmax(1.89036625rem, max-content) minmax(6.46272857rem, max-content);
	}

	#hg1OQdqVYKdTpgJ6 {
		grid-area: 16 / 7 / 17 / 24;
		position: relative;
	}

	#EwKJj23G47qe68By {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#tCQQkKsjqoO3PcjD {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.27114586em - var(--ffsd)));
	}

	#wrAhpxnibQN5XUU9 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.27114586em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#qbKRJZpydbRkKZTi {
		min-width: 91.6rem;
	}

	#EQ7LVJMnL1stNRz9 {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#qCVKgoHQmemlevzc {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5743722em - var(--ffsd)));
	}

	#rkHryV8anWx6KcaB {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5743722em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#NOstk5h63Mb5TskG {
		min-width: 91.6rem;
	}

	#P27AihfcFDm7QolQ {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#SRp2YwMGW6KaXDqO {
		grid-area: 18 / 8 / 19 / 25;
		grid-template-columns: 0 0 22.63933906rem 46.18798854rem 22.63933906rem 0;
		grid-template-rows: 0 minmax(46.18780379rem, max-content) minmax(6.4rem, max-content) minmax(7.36856046rem, max-content) minmax(1.89036625rem, max-content) minmax(6.46272857rem, max-content);
	}

	#n3fwGSOdmcnKv76e {
		grid-area: 18 / 8 / 19 / 25;
		position: relative;
	}

	#QwdmCYYrcB9VsoEs {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#ro5hkpCIhXWtLKAk {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.27114586em - var(--ffsd)));
	}

	#nO52FgQ0qwqpzMAV {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.27114586em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#tcIDBuAJ0zGrAvGU {
		min-width: 91.6rem;
	}

	#ak4Oe9anQe2Mqjfh {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#DbDreVUMXOPDATC9 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5743722em - var(--ffsd)));
	}

	#IY332BYlxOCtW5Tz {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5743722em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Ypqp8yRB1YpgaSeB {
		min-width: 91.6rem;
	}

	#PEd3GzvZJTdwOH13 {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#GYVW5frJXIOIEqLY {
		grid-area: 20 / 9 / 21 / 26;
		grid-template-columns: 0 0 22.63933906rem 46.18798854rem 22.63933906rem 0;
		grid-template-rows: 0 minmax(46.18780379rem, max-content) minmax(6.4rem, max-content) minmax(7.36856046rem, max-content) minmax(1.89036625rem, max-content) minmax(6.46272857rem, max-content);
	}

	#FvOvPrzOppzjtJfX {
		grid-area: 20 / 9 / 21 / 26;
		position: relative;
	}

	#lzsWmCKR22NVpshS {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#S5Q960MpVpDBtBWM {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.27114586em - var(--ffsd)));
	}

	#l88ff6BreOyRZtkh {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.27114586em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#bpRWcRq4en3NAnPE {
		min-width: 91.6rem;
	}

	#a2wC9qJJyef20GhG {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#MNMuiPNzwRISHc8i {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5743722em - var(--ffsd)));
	}

	#bvqwQ1cXjbvHUCUd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5743722em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#x1iKD5GVybBnVqmr {
		min-width: 91.6rem;
	}

	#UtvVMqVRRA3ZTwkM {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#helZKrihA0xCp3xD {
		grid-area: 22 / 10 / 23 / 27;
		grid-template-columns: 0 0 22.63933906rem 46.18798854rem 22.63933906rem 0;
		grid-template-rows: 0 minmax(46.18780379rem, max-content) minmax(6.4rem, max-content) minmax(7.36856046rem, max-content) minmax(1.89036625rem, max-content) minmax(6.46272857rem, max-content);
	}

	#DW1PWvIrj05MYTzA {
		grid-area: 22 / 10 / 23 / 27;
		position: relative;
	}

	#eaYJnouYhH8AeTXs {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#isxsKvHrpBiedV0f {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.27114586em - var(--ffsd)));
	}

	#UoamxoILsO10EG5q {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.27114586em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#tgdsaEtbxSUDVtjW {
		min-width: 91.6rem;
	}

	#lPBGxfXq4O81nS1I {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#K2Xke32ojoiwTvRX {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5743722em - var(--ffsd)));
	}

	#addOTvYUpqkiGMBY {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5743722em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#cn95RVlW3BppVxbB {
		min-width: 91.6rem;
	}

	#qt6Fy2LMKhfmHiWO {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#CcYiAMGi67m1j3jt {
		grid-area: 24 / 11 / 25 / 28;
		grid-template-columns: 0 0 22.63933906rem 46.18798854rem 22.63933906rem 0;
		grid-template-rows: 0 minmax(46.18780379rem, max-content) minmax(6.4rem, max-content) minmax(7.36856046rem, max-content) minmax(1.89036625rem, max-content) minmax(6.46272857rem, max-content);
	}

	#bKS3hojd0vWCsG3G {
		grid-area: 24 / 11 / 25 / 28;
		position: relative;
	}

	#LCgfLLbcF7R1C5Ky {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#RRlSofbn2exyjhSo {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.27114586em - var(--ffsd)));
	}

	#pIV69ietZi13B1dT {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.27114586em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#K1yEwqXyFsPf1HK2 {
		min-width: 91.6rem;
	}

	#yGXLNKXlE0Lx4hng {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#Z1FtDDBo5h4wMoRV {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5743722em - var(--ffsd)));
	}

	#Y6bPOOQDE0l0RJ3I {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5743722em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#oJGsidyPS9GBmdjA {
		min-width: 91.6rem;
	}

	#WCYVRqjPYrdeqHy2 {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#MCREdEQaeq1a3hMP {
		grid-area: 26 / 12 / 27 / 29;
		grid-template-columns: 0 0 22.63933906rem 46.18798854rem 22.63933906rem 0;
		grid-template-rows: 0 minmax(46.18780379rem, max-content) minmax(6.4rem, max-content) minmax(7.36856046rem, max-content) minmax(1.89036625rem, max-content) minmax(6.46272857rem, max-content);
	}

	#QLcfpqVrH3H1mvpy {
		grid-area: 26 / 12 / 27 / 29;
		position: relative;
	}

	#mU73NrSzR8LyStZH {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#u4CN5ZtTl2KDneKG {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.27114586em - var(--ffsd)));
	}

	#WvRywGLPll6BU7wk {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.27114586em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Zzvv5ImtUTITuzQn {
		min-width: 91.6rem;
	}

	#xBm3VRHfGheG2x42 {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#LnpNv0pmu6wvkbvS {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5743722em - var(--ffsd)));
	}

	#UMsUl7dcFKO4BRG1 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5743722em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#BX9VZoh3BmRroik7 {
		min-width: 91.6rem;
	}

	#FvMjt8b0eWx8TBYx {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#sZHhnQgWODExXYwG {
		grid-area: 28 / 13 / 29 / 30;
		grid-template-columns: 0 0 22.63933906rem 46.18798854rem 22.63933906rem 0;
		grid-template-rows: 0 minmax(46.18780379rem, max-content) minmax(6.4rem, max-content) minmax(7.36856046rem, max-content) minmax(1.89036625rem, max-content) minmax(6.46272857rem, max-content);
	}

	#otiEaCfYPF6XZBOs {
		grid-area: 28 / 13 / 29 / 30;
		position: relative;
	}

	#pzMKaFw2GamEqzIy {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#hNUWSf5vHG23PS3z {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.27114586em - var(--ffsd)));
	}

	#TQtVepH5l0TIWSzI {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.27114586em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#vYc7XNQkg7SIdiVs {
		min-width: 91.6rem;
	}

	#cctJXIHRRhmwZh6O {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#aUveYXKfXDgnTBey {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5743722em - var(--ffsd)));
	}

	#piQVsorWHZwkaZOq {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5743722em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#YER3aBDStQlIEHTA {
		min-width: 91.6rem;
	}

	#ajwEh7aIxhCT1eVz {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#ViYwSgKN4trQebbn {
		grid-area: 30 / 14 / 31 / 31;
		grid-template-columns: 0 0 22.63933906rem 46.18798854rem 22.63933906rem 0;
		grid-template-rows: 0 minmax(46.18780379rem, max-content) minmax(6.4rem, max-content) minmax(7.36856046rem, max-content) minmax(1.89036625rem, max-content) minmax(6.46272857rem, max-content);
	}

	#r5IyhZrlvmrMTAxN {
		grid-area: 30 / 14 / 31 / 31;
		position: relative;
	}

	#n4a0cHUfK1HSTCGF {
		grid-template-columns: 4.26666667rem 0 0 0 0 0 0 0 0 0 0 0 0 22.63933906rem 0 46.18798854rem 0 22.63933906rem 0 0 0 0 0 0 0 0 0 0 0 0 4.26666667rem;
		grid-template-rows: minmax(10.93333333rem, max-content) minmax(6.54552718rem, max-content) minmax(10.99053407rem, max-content) minmax(46.18780379rem, max-content) minmax(6.4rem, max-content) minmax(15.72165528rem, max-content) minmax(6.4rem, max-content) minmax(68.30945907rem, max-content) minmax(10.93333333rem, max-content) minmax(46.18780379rem, max-content) minmax(6.4rem, max-content) minmax(7.36856046rem, max-content) minmax(1.89036625rem, max-content) minmax(6.46272857rem, max-content) minmax(6.4rem, max-content) minmax(68.30945907rem, max-content) minmax(10.93333333rem, max-content) minmax(68.30945907rem, max-content) minmax(6.4rem, max-content) minmax(68.30945907rem, max-content) minmax(10.93333333rem, max-content) minmax(68.30945907rem, max-content) minmax(6.4rem, max-content) minmax(68.30945907rem, max-content) minmax(10.93333333rem, max-content) minmax(68.30945907rem, max-content) minmax(6.4rem, max-content) minmax(68.30945907rem, max-content) minmax(10.93333333rem, max-content) minmax(68.30945907rem, max-content) minmax(10.93333333rem, max-content);
	}

	#wX2VRa0JC2UPOxM0 {
		min-height: calc(33.41787947 * var(--1vh, 1vh));
	}

	#S8kA0ZtQhjOQOKwj {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#YnBX1w065wi4uN4L {
		grid-template-columns: 0 870.1874464%;
		left: -385.0937232%;
		grid-template-rows: 0 100%;
	}

	#BIyN9FNt9OkkECvl {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(12.33027655em - var(--ffsd)));
	}

	#RwCegoRE1rKV4jS1 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 12.33027655em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.01));
		margin-top: calc(var(--first-font-size) * 0.05);
		margin-bottom: calc(var(--last-font-size) * 0.05);
	}

	#KhQaKh5oM3fMkcAn {
		min-width: 91.6rem;
	}

	#NFmxBSeb5ywYlty6 {
		grid-area: 2 / 2 / 3 / 6;
		position: relative;
	}

	#kj3ETsDcUZlOhSvk {
		display: none;
	}

	#MsSDKJfvbQdX1ZDD {
		display: block;
	}

	#t9ODRQGA8cKjRez5 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#ZuDkwwAUgk04AJ2w {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#CF1kYwEbzZQkz1dz {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#gZibwNpUF5S5So4n {
		grid-template-columns: 0 2.27869771rem 66.41659526rem 2.27869771rem;
		grid-template-rows: 0 minmax(2.27883054rem, max-content) minmax(66.4163296rem, max-content) minmax(2.27883054rem, max-content);
	}

	#DGdFCTYYFCGRfuLt {
		grid-template-columns: 0 70.97399068rem;
		grid-template-rows: 0 minmax(70.97399068rem, max-content);
	}

	#fxObjboe2MIoW7di {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.34190923em - var(--ffsd)));
	}

	#W9H8dVYPp2W8dmjw {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.34190923em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#gEQRasMlCYlwIzfa {
		min-width: 78.8rem;
	}

	#QFPWF4UKRxWTTLdT {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#SeypdGqfELB5RHl9 {
		grid-area: 4 / 3 / 5 / 7;
		grid-template-columns: 0 6.4rem 3.01389617rem 70.97399068rem 4.67877981rem 6.4rem;
		grid-template-rows: 0 minmax(24.11849011rem, max-content) minmax(70.97399068rem, max-content) minmax(8.45524021rem, max-content) minmax(7.56499173rem, max-content) minmax(24.11849011rem, max-content);
	}

	#XIFNPdWiGGPXVg6A {
		grid-area: 4 / 3 / 5 / 7;
		position: relative;
	}

	#CKMIPKAXamZbG3Go {
		display: none;
	}

	#k86WiYPZcta16CE9 {
		display: block;
	}

	#i7KgHSW2144mi1Li {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#AUVv7WdTmylWb1yh {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#UQoc6JyZzEgKohPS {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#BPky5TaRtu6hUNtl {
		grid-template-columns: 0 2.27869771rem 66.41659526rem 2.27869771rem;
		grid-template-rows: 0 minmax(2.27883054rem, max-content) minmax(66.4163296rem, max-content) minmax(2.27883054rem, max-content);
	}

	#fvh4EVCDc8wFidp6 {
		grid-template-columns: 0 70.97399068rem;
		grid-template-rows: 0 minmax(70.97399068rem, max-content);
	}

	#BTHywGdE1TJza1E2 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.34190923em - var(--ffsd)));
	}

	#lVDcLMcaMgEIaSmp {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.34190923em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#O59aprSV0Sz3FX7f {
		min-width: 78.8rem;
	}

	#MJ7fklYwLMVRwUVd {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#SmtITIiN4OkX9KGF {
		grid-area: 6 / 4 / 7 / 8;
		grid-template-columns: 0 6.4rem 3.84633799rem 70.97399068rem 3.84633799rem 6.4rem;
		grid-template-rows: 0 minmax(19.85182344rem, max-content) minmax(70.97399068rem, max-content) minmax(6.58857354rem, max-content) minmax(16.3983653rem, max-content) minmax(19.85182344rem, max-content);
	}

	#njrbbX2MIZ8ACucA {
		grid-area: 6 / 4 / 7 / 8;
		position: relative;
	}

	#BJ56qU9IrfHKWEXS {
		display: none;
	}

	#fP9uOK7sNZdwTEie {
		display: block;
	}

	#ONnBplxbFRBxwQWD {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#OyJrHIjj9L40dH67 {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#nFBTSV5Qg3f3G0yu {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#DJumzARuTyfZ5TcT {
		grid-template-columns: 0 2.27869771rem 66.41659526rem 2.27869771rem;
		grid-template-rows: 0 minmax(2.27883054rem, max-content) minmax(66.4163296rem, max-content) minmax(2.27883054rem, max-content);
	}

	#QYluzNfhXHF1wj7k {
		grid-template-columns: 0 70.97399068rem;
		grid-template-rows: 0 minmax(70.97399068rem, max-content);
	}

	#Y5R0b5dzA8Q6in1f {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.34190923em - var(--ffsd)));
	}

	#UL33FtBMoc9uKClH {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.34190923em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#xIQ052HO4W1UWtzn {
		min-width: 78.8rem;
	}

	#EjvTHHYfq6J4DxNd {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#rhBP5TtNEKB13PR5 {
		grid-area: 8 / 5 / 9 / 9;
		grid-template-columns: 0 6.4rem 3.84633799rem 70.97399068rem 3.84633799rem 6.4rem;
		grid-template-rows: 0 minmax(14.91849011rem, max-content) minmax(70.97399068rem, max-content) minmax(6.05524021rem, max-content) minmax(25.23173887rem, max-content) minmax(14.91849011rem, max-content);
	}

	#wP5w8HIdiGqngKxo {
		grid-area: 8 / 5 / 9 / 9;
		position: relative;
	}

	#ylGXbSvVUSJkKvSi {
		grid-template-columns: 4.26666667rem 0 0 0 91.46666667rem 0 0 0 4.26666667rem;
		grid-template-rows: minmax(8.45261174rem, max-content) minmax(14.66419777rem, max-content) minmax(13.30080372rem, max-content) minmax(135.23120283rem, max-content) minmax(6.4rem, max-content) minmax(133.66457641rem, max-content) minmax(6.4rem, max-content) minmax(132.09794998rem, max-content) minmax(8.45261174rem, max-content);
	}

	#trPu9NDNn3GsxH2i {
		min-height: calc(17.46785255 * var(--1vh, 1vh));
	}

	#kHTr4UMRKuBnmI2s {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#Ss1yn1mETT9jLgzw {
		grid-template-columns: 0 304.95840405%;
		left: -102.47920202%;
		grid-template-rows: 0 100%;
	}

	#yOyv0g931HWP8K5w {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(11.49049125em - var(--ffsd)));
	}

	#adtT21cbrs3OWsBi {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(11.49049125em - var(--ffsd)));
	}

	#U2o7tnUjIVGm7rT4 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 11.49049125em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#zVyrooSEco4MLXWd {
		min-width: 91.6rem;
	}

	#Ty2xMlXlERNrWhyE {
		grid-area: 2 / 2 / 3 / 39;
		position: relative;
	}

	#oqCLFGq0U0ONLB4j {
		stroke-width: calc(100rem * 2.1719654534524064 / 375.0);
	}

	#Z79YFQyvL6A7jGzp {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#Z5ZTkUL0Ig3DZLjq {
		font-size: calc(3.34643888em - var(--ffsd));
	}

	#GdcocGV83UV2GJAx {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#s8AJLcuBl5zakdRa {
		min-width: 10.27715539rem;
	}

	#DI5Uu8GnvsTdMf3D {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#T8d5WFIxemZpAfwI {
		grid-template-columns: 0 0.67572259rem 10.14382205rem 0.67572259rem;
		grid-template-rows: 0 minmax(0.67572259rem, max-content) minmax(5.65748438rem, max-content) minmax(0.67572259rem, max-content);
	}

	#n7J1efHR2vqf1rmi {
		grid-area: 4 / 7 / 15 / 11;
		grid-template-columns: 0 11.49526722rem;
		grid-template-rows: 0 minmax(7.00892956rem, max-content);
	}

	#lBKAzfbKtQiOdvnt {
		grid-area: 4 / 7 / 15 / 11;
		position: relative;
	}

	#fd7DnM6VGWagSWZz {
		stroke-width: calc(100rem * 2.171965453452402 / 375.0);
	}

	#ZkEx1ZIMnCrNc8xV {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#VZFNOjVakBwpupN5 {
		font-size: calc(3.34643888em - var(--ffsd));
	}

	#ZlhIdLtFTKa2t6CN {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#p1jBVyW4SVentMnI {
		min-width: 10.27715539rem;
	}

	#QwE6X2n2GBgM7VkT {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#OGSoeKRLoF8GZsJ0 {
		grid-template-columns: 0 0.67572259rem 10.14382205rem 0.67572259rem;
		grid-template-rows: 0 minmax(0.67572259rem, max-content) minmax(5.65110179rem, max-content) minmax(0.67572259rem, max-content);
	}

	#FdjeAv9k94ie8Hm7 {
		grid-area: 5 / 14 / 14 / 22;
		grid-template-columns: 0 11.49526722rem;
		grid-template-rows: 0 minmax(7.00254696rem, max-content);
	}

	#GtJfgCuMkrhj1Q8V {
		grid-area: 5 / 14 / 14 / 22;
		position: relative;
	}

	#OgCJtlbWkRiQYND9 {
		stroke-width: calc(100rem * 2.1719654534524078 / 375.0);
	}

	#yp9yqr9m3tlFfmJE {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#WcJrTy8ZzZLbIWkM {
		font-size: calc(3.34643888em - var(--ffsd));
	}

	#u2WknSR2ixrKnkck {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#kKel268v0eLrEjK5 {
		min-width: 10.27715539rem;
	}

	#VuraA5m92j3CCFSt {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#C98T03WjsWcp9Naq {
		grid-template-columns: 0 0.67572259rem 10.14382205rem 0.67572259rem;
		grid-template-rows: 0 minmax(0.67572259rem, max-content) minmax(5.65110179rem, max-content) minmax(0.67572259rem, max-content);
	}

	#n17msLyZGzQZY6fS {
		grid-area: 6 / 26 / 16 / 33;
		grid-template-columns: 0 11.49526722rem;
		grid-template-rows: 0 minmax(7.00254696rem, max-content);
	}

	#NiwwiVOybqaY1G8L {
		grid-area: 6 / 26 / 16 / 33;
		position: relative;
	}

	#GhJoiNdyc2YK5h9U {
		grid-area: 7 / 20 / 11 / 27;
		position: relative;
	}

	#sl7v8yV5XWSPHbhU {
		grid-area: 8 / 9 / 12 / 15;
		position: relative;
	}

	#Nrsym2i8EB1IfRhY {
		grid-area: 9 / 36 / 31 / 40;
		position: relative;
	}

	#uCXPiiT1S0JJBJ5s {
		grid-area: 10 / 31 / 13 / 37;
		position: relative;
	}

	#lIBf666LxGtI2SbB {
		font-size: calc(3.34643888em - var(--ffsd));
	}

	#aWqmrTrjK6vno2h5 {
		--first-font-size: 3.34643888em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#p58b2GPPiCvdMdNp {
		min-width: 26.72784366rem;
	}

	#uQtFKQYrNz0iB93Z {
		grid-area: 17 / 3 / 22 / 13;
		position: relative;
	}

	#e6wXpRmulXDPT0u0 {
		font-size: calc(3.34643888em - var(--ffsd));
	}

	#fDc8qWFKZszTq4Yj {
		--first-font-size: 3.34643888em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#kzR5Fx3oJ6GLRhRT {
		min-width: 26.72784366rem;
	}

	#johfP1Pxott9vEEM {
		grid-area: 18 / 16 / 20 / 25;
		position: relative;
	}

	#TmT1hYbB6GVMF1ld {
		font-size: calc(3.34643888em - var(--ffsd));
	}

	#ZXPctumbRe0FlOvq {
		--first-font-size: 3.34643888em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#wRVdpMXlteyerwko {
		min-width: 27.99427322rem;
	}

	#QfubwHlgOUkIDLKo {
		grid-area: 19 / 28 / 23 / 35;
		position: relative;
	}

	#mE358ak8TuBfPFmW {
		font-size: calc(2.0593418em - var(--ffsd));
	}

	#FZfpR6RMLnUi7Vdn {
		--first-font-size: 2.0593418em;
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Ai0HO0vCRAKv5G3L {
		min-width: 26.23425417rem;
	}

	#gGj211OtpCfkR6kL {
		grid-area: 21 / 17 / 24 / 24;
		position: relative;
	}

	#uS6qp7fPuFhX50yO {
		stroke-width: calc(100rem * 2.1719654534524055 / 375.0);
	}

	#vocqBlV3eDAZhjKe {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#zK3sCCVcD7dmKKm2 {
		font-size: calc(3.34643888em - var(--ffsd));
	}

	#KbIIjrYbW7veptRY {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#mFh0wHMPNvn6BCF5 {
		min-width: 10.27715539rem;
	}

	#QyApyhorWpS58HkO {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#ox3LXDzAhHnOZYgN {
		grid-template-columns: 0 0.67572259rem 10.14382205rem 0.67572259rem;
		grid-template-rows: 0 minmax(0.67572259rem, max-content) minmax(5.65110179rem, max-content) minmax(0.67572259rem, max-content);
	}

	#T82aFK7WnfXqzfpW {
		grid-area: 25 / 8 / 35 / 12;
		grid-template-columns: 0 11.49526722rem;
		grid-template-rows: 0 minmax(7.00254696rem, max-content);
	}

	#OO4yQCeKx191j6uX {
		grid-area: 25 / 8 / 35 / 12;
		position: relative;
	}

	#LqaJvKuc3Dk7x7QU {
		stroke-width: calc(100rem * 2.1719654534524055 / 375.0);
	}

	#orI8ZDwTqchi8NYh {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#d8nsYxyPh7lcHoTK {
		font-size: calc(3.34643888em - var(--ffsd));
	}

	#ELugkCrhsZAmxoaj {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#ueRrGfE4wVgueoGt {
		min-width: 10.27715539rem;
	}

	#aXZUTVOOdAdtC3Vw {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#XxiLMbXAvwIanOG5 {
		grid-template-columns: 0 0.67572259rem 10.14382205rem 0.67572259rem;
		grid-template-rows: 0 minmax(0.67572259rem, max-content) minmax(5.65110179rem, max-content) minmax(0.67572259rem, max-content);
	}

	#tS81KE5Rcn2QKSxO {
		grid-area: 26 / 29 / 36 / 34;
		grid-template-columns: 0 11.49526722rem;
		grid-template-rows: 0 minmax(7.00254696rem, max-content);
	}

	#S7qU5KJYk1eVtJA5 {
		grid-area: 26 / 29 / 36 / 34;
		position: relative;
	}

	#KrqNnFZ0UIxhION3 {
		stroke-width: calc(100rem * 2.171965453452404 / 375.0);
	}

	#OsISqtUJstEsYRfG {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#WscMkLBpM8dD8UiW {
		font-size: calc(3.34643888em - var(--ffsd));
	}

	#RKcG0Qp5pw5GyuIV {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#BNLm32gEMenNBav0 {
		min-width: 10.27715539rem;
	}

	#EN1LHn87g78DtoPK {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#nTOFQixz6fgxoAav {
		grid-template-columns: 0 0.67572259rem 10.14382205rem 0.67572259rem;
		grid-template-rows: 0 minmax(0.67572259rem, max-content) minmax(5.65110179rem, max-content) minmax(0.67572259rem, max-content);
	}

	#CtnzpmscK8aNO1rt {
		grid-area: 27 / 18 / 37 / 23;
		grid-template-columns: 0 11.49526722rem;
		grid-template-rows: 0 minmax(7.00254696rem, max-content);
	}

	#mRfiAAgVSN6DXzlS {
		grid-area: 27 / 18 / 37 / 23;
		position: relative;
	}

	#q08wngjnlMogU6O7 {
		grid-area: 28 / 32 / 32 / 38;
		position: relative;
	}

	#meBqLPJvjQ8guX8N {
		grid-area: 29 / 10 / 33 / 19;
		position: relative;
	}

	#yT6e7k3zwC2ZhEZB {
		grid-area: 30 / 21 / 34 / 30;
		position: relative;
	}

	#bjiwT6n0eEvP3AL2 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(9.24445333em - var(--ffsd)));
	}

	#XgZ35s1n4uX7dHq7 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 9.24445333em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#LnWGuNxmhGGIADzm {
		min-width: 91.6rem;
	}

	#bLIpGnn9dfE3fTZL {
		grid-area: 38 / 4 / 39 / 41;
		position: relative;
	}

	#ZAT3ONIo6ol1EBRK {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(9.24445333em - var(--ffsd)));
	}

	#KlNpbQ0JZO8toHNj {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 9.24445333em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#Xn2JAL6I0PxjoTrM {
		min-width: 91.6rem;
	}

	#sAebCSIQptDj5wa4 {
		grid-area: 40 / 5 / 41 / 42;
		position: relative;
	}

	#kgS5Q5htFjObvksW {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(9.24445333em - var(--ffsd)));
	}

	#BWJudQgPUxg1KJdG {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 9.24445333em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#l1oqJzovwzrxiZCh {
		min-width: 91.6rem;
	}

	#MKXkDi6tk3E1t8T7 {
		grid-area: 42 / 6 / 43 / 43;
		position: relative;
	}

	#GhY6Ca0LFKImBmOZ {
		grid-template-columns: 4.26666667rem 0 0 0 0 1.0226772rem 0 0.05035291rem 6.91079952rem 4.5341148rem 0 14.07656591rem 3.67375327rem 0 0.19018755rem 0 0.97972566rem 1.11378829rem 2.0976786rem 2.96288376rem 4.15100336rem 1.16991322rem 13.62592794rem 0.4935895rem 3.67106661rem 0 0.19020531rem 0.97970791rem 0.28672165rem 4.52480966rem 1.26643458rem 4.24738813rem 1.16991322rem 15.38596475rem 2.49842979rem 0.00000502rem 0.09652677rem 0.0965318rem 0 0 0 0 4.26666667rem;
		grid-template-rows: minmax(17.19347342rem, max-content) minmax(25.00163187rem, max-content) minmax(18.95148714rem, max-content) 0 minmax(0.03002397rem, max-content) minmax(2.84379284rem, max-content) minmax(0.00000003rem, max-content) minmax(0.0101868rem, max-content) minmax(0.01139296rem, max-content) minmax(0.17412067rem, max-content) minmax(0.0056904rem, max-content) minmax(0.01453094rem, max-content) minmax(3.91280835rem, max-content) minmax(0.0063826rem, max-content) minmax(0.02364137rem, max-content) minmax(2.29312178rem, max-content) 0 0 minmax(3.95780372rem, max-content) minmax(1.14233647rem, max-content) minmax(2.81546724rem, max-content) 0 minmax(2.37150868rem, max-content) minmax(8.55176039rem, max-content) 0 minmax(0.1441036rem, max-content) minmax(2.77600659rem, max-content) 0 0 minmax(0.09781022rem, max-content) minmax(0.09525338rem, max-content) minmax(0.00127842rem, max-content) 0 minmax(3.88809476rem, max-content) 0 minmax(0.1441036rem, max-content) minmax(6.08781987rem, max-content) minmax(10.93333333rem, max-content) minmax(6.4rem, max-content) minmax(17.56345967rem, max-content) minmax(6.4rem, max-content) minmax(17.56345967rem, max-content) minmax(17.19347342rem, max-content);
	}

	#gZet15UmlwsvfI1A {
		min-height: calc(6.80181476 * var(--1vh, 1vh));
	}

	#jRCRvtwjSMdf7dIv {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#rUzZEQ49fMRslBaD {
		grid-template-columns: 0 778.71268829%;
		left: -339.35634415%;
		grid-template-rows: 0 100%;
	}

	#pERlgE2a6DOamg6l {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.47203189em - var(--ffsd)));
	}

	#BEYU8ezFNskJdWkQ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.47203189em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#IbJmVidWel9B0IPZ {
		min-width: 91.6rem;
	}

	#T4fZl6W85JwWkmDH {
		grid-area: 2 / 2 / 3 / 6;
		position: relative;
	}

	#rYbzezNQVmwN8rwy {
		stroke-width: calc(100rem * 1.7818181818181822 / 375.0);
	}

	#PDFkidM4k8ElFqJF {
		display: none;
	}

	#MUtTzzmY3wYNkqu9 {
		stroke-width: calc(100rem * 1.7818181818181822 / 375.0);
	}

	#QtX0JYR7w7rndoZ5 {
		display: block;
	}

	#OTGewPxk07Zd90R6 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#oncK24U5jVqFCrdR {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.73496914em - var(--ffsd)));
	}

	#ZDFzxS6yqsQjtUYd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.73496914em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#zbMFIGzzq8ZFzfcn {
		min-width: 78.8rem;
	}

	#zQSnqfE5AUwppsqQ {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#ONx5rCSDHvJt6fyo {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(16.78222971em - var(--ffsd)));
	}

	#hfaM6HCpkz1KTcrg {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 16.78222971em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#LTgRR7rIEa4qPPSg {
		min-width: 78.8rem;
	}

	#IdK3IYQ5gJ2E9vGc {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#wue0L8NHiWgYHjue {
		grid-area: 4 / 3 / 5 / 7;
		grid-template-columns: 0 6.4rem 0 78.66666667rem 0 6.4rem;
		grid-template-rows: 0 minmax(45.80084794rem, max-content) minmax(5.57110141rem, max-content) minmax(10.78803803rem, max-content) minmax(20.00380952rem, max-content) minmax(45.80084794rem, max-content);
	}

	#lvxty16L5kF1dCCb {
		grid-area: 4 / 3 / 5 / 7;
		position: relative;
	}

	#YjTkzhc6SZ0L6Avv {
		stroke-width: calc(100rem * 1.7818181818181822 / 375.0);
	}

	#AdUikw940oRh1NmJ {
		display: none;
	}

	#vwWQnIHe3A9wyvst {
		stroke-width: calc(100rem * 1.7818181818181822 / 375.0);
	}

	#kOCJPLER4qSmdRM7 {
		display: block;
	}

	#l88C7xESGFGWgt0W {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#cFsKtSFex865II4H {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.73496914em - var(--ffsd)));
	}

	#HHbP8BOU0jGyE06x {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.73496914em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#a1LUNrAChs6Vb2uV {
		min-width: 78.8rem;
	}

	#U6h52VD3Dj4WZdWw {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#eER1JQoWpDTt7q37 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(16.78222971em - var(--ffsd)));
	}

	#UJ961bvHYI3OriqG {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 16.78222971em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#TGSKVP6ICt7lSThL {
		min-width: 78.8rem;
	}

	#btkfbwdo0qn6m1Zt {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#RMgCheHCmyErjAQe {
		grid-area: 6 / 4 / 7 / 8;
		grid-template-columns: 0 6.4rem 0 78.66666667rem 0 6.4rem;
		grid-template-rows: 0 minmax(45.80084794rem, max-content) minmax(5.57110141rem, max-content) minmax(10.78803803rem, max-content) minmax(20.00380952rem, max-content) minmax(45.80084794rem, max-content);
	}

	#Yfrnbcageco4Gcuq {
		grid-area: 6 / 4 / 7 / 8;
		position: relative;
	}

	#DIu6XcaVsIuQQPBK {
		stroke-width: calc(100rem * 1.7818181818181822 / 375.0);
	}

	#GcQ4LsbvJsRrMHSn {
		display: none;
	}

	#K1TmHAQ3NTsS6CIe {
		stroke-width: calc(100rem * 1.7818181818181822 / 375.0);
	}

	#Q8N5fGVL069S5m2o {
		display: block;
	}

	#sjN2e2nPj0nSVyq7 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#CBHM7m8mUtjuOrEs {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.73496914em - var(--ffsd)));
	}

	#XXZxi89lGk9b8ejT {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.73496914em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#SLjhKolKiVzXVrsT {
		min-width: 78.8rem;
	}

	#hYLhMa3KXE2s1Jbu {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#yNzssimMPjkn4EDf {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(16.78222971em - var(--ffsd)));
	}

	#P1ktvJOBoIXGIm5k {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 16.78222971em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#TNKIZVhxUC29gE96 {
		min-width: 78.8rem;
	}

	#UYh7MZfXbNvpGa1x {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#bUFcaRcAwsloJUS1 {
		grid-area: 8 / 5 / 9 / 9;
		grid-template-columns: 0 6.4rem 0 78.66666667rem 0 6.4rem;
		grid-template-rows: 0 minmax(45.80084794rem, max-content) minmax(5.57110141rem, max-content) minmax(10.78803803rem, max-content) minmax(20.00380952rem, max-content) minmax(45.80084794rem, max-content);
	}

	#ctNvYifBfbOcadsC {
		grid-area: 8 / 5 / 9 / 9;
		position: relative;
	}

	#GUXT0duiXwA8EfRP {
		grid-template-columns: 4.26666667rem 0 0 0 91.46666667rem 0 0 0 4.26666667rem;
		grid-template-rows: minmax(9.41684579rem, max-content) minmax(7.66424325rem, max-content) minmax(14.62024238rem, max-content) minmax(127.96464484rem, max-content) minmax(6.4rem, max-content) minmax(127.96464484rem, max-content) minmax(6.4rem, max-content) minmax(127.96464484rem, max-content) minmax(9.41684579rem, max-content);
	}

	#aPvYMDHfcpxuVvZR {
		min-height: calc(16.67372668 * var(--1vh, 1vh));
	}

	#iPa1wvEJHh7MXB61 {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#L7Ny0815ayHKHP5q {
		grid-template-columns: 0 308.76383779%;
		left: -104.38191889%;
		grid-template-rows: 0 100%;
	}

	#jGOsWVsi010iQYBh {
		grid-area: 2 / 2 / 3 / 5;
		position: relative;
	}

	#O8BHPgjUtD0pmoqA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.45659434em - var(--ffsd)));
	}

	#d5AggWJARNgm3nDZ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.45659434em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#O64IhSkVydVH3KqG {
		min-width: 91.6rem;
	}

	#dQU1i5c0LdjIwoHb {
		grid-area: 4 / 3 / 5 / 6;
		position: relative;
	}

	#GeGJujlNl6iyMkqN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.4em - var(--ffsd)));
	}

	#H1by1XvAIV7Avdbp {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.4em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#B7wY3lpXLCMJPR6r {
		min-width: 91.6rem;
	}

	#tiOrR4NtAKmPXQ6Z {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#ObHuCP0zAYEk9Y0Q {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.91112em - var(--ffsd)));
	}

	#H9F8tLkkfPNO9nNe {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.91112em - var(--ffsd)));
	}

	#PejqGcvSTOEQWUub {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.91112em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.25);
		margin-bottom: calc(var(--last-font-size) * -0.25);
	}

	#CcbMq47PBBoURPM4 {
		min-width: 91.6rem;
	}

	#y8ZVVD6eAwbW5CAN {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#cqrdRbtvLdMhz6JK {
		grid-area: 6 / 4 / 7 / 7;
		grid-template-columns: 0 0 91.46666667rem 0;
		grid-template-rows: 0 minmax(6.4rem, max-content) minmax(6.4rem, max-content) minmax(15.23874605rem, max-content);
	}

	#TSeRaD7xZMnUgP0y {
		grid-area: 6 / 4 / 7 / 7;
		position: relative;
	}

	#YMYaoqrtVtrsfzDu {
		grid-template-columns: 4.26666667rem 0 0 91.46666667rem 0 0 4.26666667rem;
		grid-template-rows: minmax(12.28505027rem, max-content) minmax(91.46666667rem, max-content) minmax(6.4rem, max-content) minmax(7.69027075rem, max-content) minmax(15.42911162rem, max-content) minmax(28.03874605rem, max-content) minmax(12.28505027rem, max-content);
	}

	#nuN8I7IXOi48cTmn {
		min-height: calc(6.61122378 * var(--1vh, 1vh));
	}
}

@media (min-width: 375.05px) and (max-width: 480px) {
	#z2W0t9LO5y0FgQWr {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#SL9cu5l5y0CTwRB4 {
		grid-template-columns: 0 310.87923248%;
		left: -105.43961624%;
		grid-template-rows: 0 100%;
	}

	#iPU6c5lFlFiI4WQx {
		font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), calc(2.22222917em - var(--ffsd)));
	}

	#SQw9hne1KHFmnPeO {
		--first-font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), 2.22222917em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#qGOK3VZjCeecOc2P {
		min-width: 11.8056376rem;
	}

	#NJRAWD1XDTwmEvpu {
		grid-area: 2 / 2 / 6 / 9;
		position: relative;
	}

	#NG5fK23CeU4hquvT {
		font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), calc(2.22222917em - var(--ffsd)));
	}

	#RYk5nRdTfy6ghNrP {
		--first-font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), 2.22222917em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#yXwa2mb3SldnynWe {
		min-width: 16.53774865rem;
	}

	#lGhmYDkXcM7f0GRU {
		grid-area: 3 / 11 / 7 / 12;
		position: relative;
	}

	#X18nBTuSzk3edEEL {
		font-size: calc(2.22222917em - var(--ffsd));
	}

	#u8V3KhdVr5emw0QB {
		--first-font-size: 2.22222917em;
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#h55kBAYDwPDbvnyF {
		min-width: 9.1690993rem;
	}

	#n5vKQHfYbPI0DpT4 {
		grid-area: 4 / 13 / 8 / 21;
		position: relative;
	}

	#uGUwMlcLNrFRmbfk {
		font-size: calc(2.22222917em - var(--ffsd));
	}

	#gUi6RKbWsjEA1j6H {
		--first-font-size: 2.22222917em;
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#TACHjxHiq8ZGGcif {
		min-width: 41.34531233rem;
	}

	#N7rN3NSSYMuShTjx {
		grid-area: 5 / 20 / 9 / 28;
		position: relative;
	}

	#Du8Ium0Vz4SQucN5 {
		grid-area: 10 / 18 / 11 / 25;
		position: relative;
	}

	#mpywhKD1DfgwfA57 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.22222083em - var(--ffsd)));
	}

	#LJWAD7W2Vy2MyEXR {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.22222083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#pWiO0UiOKN5cFTrY {
		min-width: 93.4375rem;
	}

	#GK7xmNvRcJq3bFP7 {
		grid-area: 12 / 3 / 13 / 29;
		position: relative;
	}

	#p9KIzIrs63ntBWqD {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5em - var(--ffsd)));
	}

	#cCeEHicukaekaMlL {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#JDp9Rf0T8htahMtZ {
		min-width: 93.4375rem;
	}

	#i9oe8d3Evx1TMcmk {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#WA8YdHf3se5bPZ2S {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.83333333em - var(--ffsd)));
	}

	#jxiPMGfYUmhtOpQ7 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.83333333em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#rE7BUE1FKSY2WRFm {
		min-width: 93.4375rem;
	}

	#lvv5wDelwCqsVXyx {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#QXaeiBUaUbPUHBcN {
		grid-area: 14 / 4 / 15 / 30;
		grid-template-columns: 0 0 93.33333333rem 0;
		grid-template-rows: 0 minmax(5.83333333rem, max-content) minmax(5rem, max-content) minmax(6.95833333rem, max-content);
	}

	#TxIwfBhHAvKZFMNQ {
		grid-area: 14 / 4 / 15 / 30;
		position: relative;
	}

	#iY93JfbuXazRIRcr {
		grid-area: 16 / 19 / 17 / 23;
		position: relative;
	}

	#HmV1sKHCsxFDFLKN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(10em - var(--ffsd)));
	}

	#zDiUMWCVlE67rNVl {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 10em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.068));
		margin-top: calc(var(--first-font-size) * -0.125);
		margin-bottom: calc(var(--last-font-size) * -0.125);
	}

	#vpFqBZe5ZomsHCMJ {
		min-width: 38.77506936rem;
	}

	#LStX5ZKEr57y950h {
		grid-area: 18 / 5 / 20 / 14;
		position: relative;
	}

	#i8lQhV5BBOWvqgZN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(10em - var(--ffsd)));
	}

	#tLI0tPlrLmGOu16t {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 10em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.0405));
		margin-top: calc(var(--first-font-size) * -0.125);
		margin-bottom: calc(var(--last-font-size) * -0.125);
	}

	#G1G7iCYeTi4AzcZC {
		min-width: 33.2386704rem;
	}

	#bIOwFZ0ypWmOBhXD {
		grid-area: 19 / 15 / 21 / 27;
		position: relative;
	}

	#YAmPSU77N2RJl4eA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.88889583em - var(--ffsd)));
	}

	#Oa8LdBBjIm5fsdV8 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.88889583em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#lp7dJl6SjNX87ORs {
		min-width: 39.63216146rem;
	}

	#VdkX9cESQH4i2k40 {
		grid-area: 23 / 6 / 24 / 16;
		position: relative;
	}

	#jNYg8u9bxzq2JDKz {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.5555625em - var(--ffsd)));
	}

	#ls0rVOMmpEllDERc {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.5555625em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#NAlqVZoTiEShReua {
		min-width: 15.59308309rem;
	}

	#BoTYHdTPWrLpcyE5 {
		grid-area: 22 / 17 / 25 / 26;
		position: relative;
	}

	#FC92Yx3X7HcMrmUQ {
		grid-area: 26 / 10 / 30 / 31;
		position: relative;
	}

	#WsUk8J16AAC9Ru2i {
		grid-area: 27 / 7 / 29 / 22;
		position: relative;
	}

	#yvFwVFLgHWnfiHHi {
		grid-area: 28 / 8 / 31 / 24;
		position: relative;
	}

	#io8xHR69844PRI5U {
		grid-template-columns: 3.33333333rem 0 0 0 0 0 6.31600095rem 5.38546998rem 2.19658034rem 0.96509504rem 16.43358199rem 4.87684285rem 2.49733155rem 0 0.8570921rem 0 0.13708105rem 0.40782804rem 1.57945506rem 3.58614483rem 2.06118758rem 5.9607381rem 0.35526285rem 0.05256519rem 1.34865372rem 16.78849521rem 11.08809818rem 10.43982873rem 0 0 3.33333333rem;
		grid-template-rows: minmax(4.09393538rem, max-content) 0 0 0 minmax(2.51852639rem, max-content) 0 0 0 minmax(6.0377487rem, max-content) minmax(14.05382608rem, max-content) minmax(5rem, max-content) minmax(3.80935901rem, max-content) minmax(1.02751371rem, max-content) minmax(17.79166667rem, max-content) minmax(5rem, max-content) minmax(12.58280658rem, max-content) minmax(10.76415296rem, max-content) 0 minmax(11.875rem, max-content) 0 minmax(1.58399029rem, max-content) minmax(1.04166667rem, max-content) minmax(4.58333333rem, max-content) minmax(1.04166667rem, max-content) minmax(5rem, max-content) minmax(12.76883521rem, max-content) minmax(0.2813205rem, max-content) minmax(47.27778415rem, max-content) minmax(0.2813205rem, max-content) 0 minmax(4.09393538rem, max-content);
	}

	#u3SFqEqUiq3Yd9AS {
		min-height: calc(8.31456269 * var(--1vh, 1vh));
	}

	#fSeIbSOK25LfAyr9 {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#oxBQLPEYTzQnRNow {
		grid-template-columns: 0 247.79112546%;
		left: -73.89556273%;
		grid-template-rows: 0 100%;
	}

	#K4GjhCJlLhRsOHpE {
		grid-area: 2 / 8 / 3 / 10;
		position: relative;
	}

	#LjGKy3aqwD5f0kvO {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.49439583em - var(--ffsd)));
	}

	#Cy3Bx7uQW1irPtaW {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.49439583em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.015));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Xfkrn6HXr1JZUQoh {
		min-width: 65.66685967rem;
	}

	#ldgibYz5xXvUb0Rt {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#zzVPHxti09lpPp1q {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44783333em - var(--ffsd)));
	}

	#S4VRXPXyi8rvnwCL {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44783333em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#LLDeg5iIHwe3XlTN {
		min-width: 65.66685967rem;
	}

	#ckBv54l5HJrE5emB {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#hC7hJ5xZDnCcU29U {
		grid-template-columns: 0 0 65.562693rem 0;
		grid-template-rows: 0 minmax(6.40112083rem, max-content) minmax(2.43703603rem, max-content) minmax(5.1521rem, max-content);
	}

	#GZRDoTechanZzenm {
		grid-area: 4 / 5 / 5 / 12;
		position: relative;
	}

	#BAjQbjJ9VkJtrsgg {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.22222083em - var(--ffsd)));
	}

	#YzmjkTygXoFPnsZE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.22222083em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#J55E6XMFl1MtYrvm {
		min-width: 93.4375rem;
	}

	#QSANUOBl4gQFbcpZ {
		grid-area: 6 / 2 / 7 / 13;
		position: relative;
	}

	#RhjdCAi1nq6z8qTW {
		grid-area: 8 / 3 / 9 / 14;
		position: relative;
	}

	#AKeXGtwqL9JUwDFh {
		grid-area: 10 / 7 / 11 / 9;
		position: relative;
	}

	#YAWsPgICRzktSn4r {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.49439583em - var(--ffsd)));
	}

	#SN3hAOnt3jCuw0R6 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.49439583em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.015));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#wWkeSV5YmODu4QGB {
		min-width: 56.14259789rem;
	}

	#tqIWfLEWsUWNb7Md {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#N70HGv8vxl1N2K22 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44783333em - var(--ffsd)));
	}

	#o4iJlppSTa6Pl1cE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44783333em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#CDqOtP9HmgK4cCL5 {
		min-width: 56.14259789rem;
	}

	#bpR1AVV9gcXSUIxq {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#W7BJ9Ea8WABbO7iA {
		grid-template-columns: 0 0 56.03843122rem 0;
		grid-template-rows: 0 minmax(6.40112083rem, max-content) minmax(2.43703603rem, max-content) minmax(5.1521rem, max-content);
	}

	#P9GlGLj3YwKgduri {
		grid-area: 12 / 6 / 13 / 11;
		position: relative;
	}

	#v2sELbSjXLn37lbM {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.22222083em - var(--ffsd)));
	}

	#MKClK3FDAeuDU0wa {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.22222083em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#v2QlpyFCJWkdSM91 {
		min-width: 93.4375rem;
	}

	#x17FaXp1woE5u6dY {
		grid-area: 14 / 4 / 15 / 15;
		position: relative;
	}

	#Zpybw2QrKoj7XLAc {
		grid-template-columns: 3.33333333rem 0 0 13.88532017rem 3.7994494rem 11.13440204rem 0.96268149rem 32.80694565rem 0.96268149rem 10.17172056rem 5.72481238rem 13.88532017rem 0 0 3.33333333rem;
		grid-template-rows: minmax(9.00535642rem, max-content) minmax(53.69026851rem, max-content) minmax(4.30370154rem, max-content) minmax(13.99025687rem, max-content) minmax(2.91666667rem, max-content) minmax(12.7174687rem, max-content) minmax(7.32577031rem, max-content) minmax(1.63333333rem, max-content) minmax(10.78631302rem, max-content) minmax(53.69026851rem, max-content) minmax(4.30370154rem, max-content) minmax(13.99025687rem, max-content) minmax(2.91666667rem, max-content) minmax(16.31510057rem, max-content) minmax(9.00535642rem, max-content);
	}

	#xONz65JYPuhP63HY {
		min-height: calc(10.43923251 * var(--1vh, 1vh));
	}

	#WMNyFVvdW8JDT3mq {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#lNDWuvQZOk10eZIb {
		grid-template-columns: 0 618.85933349%;
		left: -259.42966674%;
		grid-template-rows: 0 100%;
	}

	#GWKBOWWbMlMRE34y {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.56592845em - var(--ffsd)));
	}

	#gGhy3uiz4vkiQuRd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.56592845em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#fQuImrbrFCP5809S {
		min-width: 93.4375rem;
	}

	#KuCcgaAAVItGKIlN {
		grid-area: 2 / 2 / 3 / 20;
		position: relative;
	}

	#w38lT8HHxpTCtpO0 {
		grid-area: 4 / 15 / 5 / 17;
		position: relative;
	}

	#PzoWd5qDgVaLmUDZ {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.00002083em - var(--ffsd)));
	}

	#QPGnTaypMoAPltwt {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.00002083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#hzvp0OYD4RCo7uEv {
		min-width: 73.03106853rem;
	}

	#IrHTNhp1QPNAKOhS {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#B9n7zRcs29V0crDQ {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44447917em - var(--ffsd)));
	}

	#NbIX2BmwIHowCLEB {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44447917em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#iBASHVu12sWYgEA5 {
		min-width: 73.03106853rem;
	}

	#rObDKWScOEXbYZWx {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#d2nPwGoAmEq2u0uY {
		grid-area: 6 / 14 / 7 / 19;
		grid-template-columns: 0 0 72.92690186rem 0;
		grid-template-rows: 0 minmax(5.87499583rem, max-content) minmax(1.47684863rem, max-content) minmax(5.15277083rem, max-content);
	}

	#FwOEASN75NL9LIvV {
		grid-area: 6 / 14 / 7 / 19;
		position: relative;
	}

	#ZSRJPzhhA4UvXUXg {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#fumR30kYPtTqjQ7h {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.00002083em - var(--ffsd)));
	}

	#eJ0nuio3tOE3eLlK {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.00002083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#fiDbBM8aiSeoBZTJ {
		min-width: 93.4375rem;
	}

	#vwLPE9pIxvZev4hm {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#N5r1DfopvjC11lWp {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44447917em - var(--ffsd)));
	}

	#cbrdsrvGgor9AVFQ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44447917em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#wRhGvAHLO2UPdJVv {
		min-width: 93.4375rem;
	}

	#K8i8CthCuAW2RNVt {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#ubW4cp95SqILM3ze {
		grid-area: 8 / 3 / 9 / 21;
		grid-template-columns: 0 0 28.62448364rem 36.08436605rem 28.62448364rem 0;
		grid-template-rows: 0 minmax(36.08422171rem, max-content) minmax(5rem, max-content) minmax(5.87499583rem, max-content) minmax(1.47684863rem, max-content) minmax(5.15277083rem, max-content);
	}

	#C9SXFJ76ZOq9YNLA {
		grid-area: 8 / 3 / 9 / 21;
		position: relative;
	}

	#sz2N5padSkeqtao1 {
		grid-area: 10 / 16 / 11 / 18;
		position: relative;
	}

	#ouKG2QSRbQ1FNpl5 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.00002083em - var(--ffsd)));
	}

	#F24kL3rMx9bV6DWO {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.00002083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#SAmtaymZzgUuj19C {
		min-width: 93.4375rem;
	}

	#FMbqVJ1UD4kpThwJ {
		grid-area: 12 / 4 / 13 / 22;
		position: relative;
	}

	#LkfhKA5raUua92yA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44447917em - var(--ffsd)));
	}

	#qTRcZhmLjAMOPuFK {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44447917em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#X79zQPuPWaOeSbE0 {
		min-width: 93.4375rem;
	}

	#EUF1plnsAfhFY5RZ {
		grid-area: 14 / 5 / 15 / 23;
		position: relative;
	}

	#pFXXZ92FZMvZaXz8 {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#CxXylKhIAu0bJefE {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.00002083em - var(--ffsd)));
	}

	#tkVhyhsakD1AvZFY {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.00002083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#PXhSK1K0HwBWdIqu {
		min-width: 93.4375rem;
	}

	#UVCSVdyuM0GGAXVs {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#l6ITaRwTVeHdFnXq {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44447917em - var(--ffsd)));
	}

	#di4U2TujvKayvhoE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44447917em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#JJWbfo5XyQBWA34h {
		min-width: 93.4375rem;
	}

	#LWy405G9NYzJL9s0 {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#NZGOgBKl8st2sJvf {
		grid-area: 16 / 6 / 17 / 24;
		grid-template-columns: 0 0 28.62448364rem 36.08436605rem 28.62448364rem 0;
		grid-template-rows: 0 minmax(36.08422171rem, max-content) minmax(5rem, max-content) minmax(5.87499583rem, max-content) minmax(1.47684863rem, max-content) minmax(5.15277083rem, max-content);
	}

	#hg1OQdqVYKdTpgJ6 {
		grid-area: 16 / 6 / 17 / 24;
		position: relative;
	}

	#EwKJj23G47qe68By {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#tCQQkKsjqoO3PcjD {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.00002083em - var(--ffsd)));
	}

	#wrAhpxnibQN5XUU9 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.00002083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#qbKRJZpydbRkKZTi {
		min-width: 93.4375rem;
	}

	#EQ7LVJMnL1stNRz9 {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#qCVKgoHQmemlevzc {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44447917em - var(--ffsd)));
	}

	#rkHryV8anWx6KcaB {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44447917em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#NOstk5h63Mb5TskG {
		min-width: 93.4375rem;
	}

	#P27AihfcFDm7QolQ {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#SRp2YwMGW6KaXDqO {
		grid-area: 18 / 7 / 19 / 25;
		grid-template-columns: 0 0 28.62448364rem 36.08436605rem 28.62448364rem 0;
		grid-template-rows: 0 minmax(36.08422171rem, max-content) minmax(5rem, max-content) minmax(5.87499583rem, max-content) minmax(1.47684863rem, max-content) minmax(5.15277083rem, max-content);
	}

	#n3fwGSOdmcnKv76e {
		grid-area: 18 / 7 / 19 / 25;
		position: relative;
	}

	#QwdmCYYrcB9VsoEs {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#ro5hkpCIhXWtLKAk {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.00002083em - var(--ffsd)));
	}

	#nO52FgQ0qwqpzMAV {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.00002083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#tcIDBuAJ0zGrAvGU {
		min-width: 93.4375rem;
	}

	#ak4Oe9anQe2Mqjfh {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#DbDreVUMXOPDATC9 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44447917em - var(--ffsd)));
	}

	#IY332BYlxOCtW5Tz {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44447917em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Ypqp8yRB1YpgaSeB {
		min-width: 93.4375rem;
	}

	#PEd3GzvZJTdwOH13 {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#GYVW5frJXIOIEqLY {
		grid-area: 20 / 8 / 21 / 26;
		grid-template-columns: 0 0 28.62448364rem 36.08436605rem 28.62448364rem 0;
		grid-template-rows: 0 minmax(36.08422171rem, max-content) minmax(5rem, max-content) minmax(5.87499583rem, max-content) minmax(1.47684863rem, max-content) minmax(5.15277083rem, max-content);
	}

	#FvOvPrzOppzjtJfX {
		grid-area: 20 / 8 / 21 / 26;
		position: relative;
	}

	#lzsWmCKR22NVpshS {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#S5Q960MpVpDBtBWM {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.00002083em - var(--ffsd)));
	}

	#l88ff6BreOyRZtkh {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.00002083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#bpRWcRq4en3NAnPE {
		min-width: 93.4375rem;
	}

	#a2wC9qJJyef20GhG {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#MNMuiPNzwRISHc8i {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44447917em - var(--ffsd)));
	}

	#bvqwQ1cXjbvHUCUd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44447917em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#x1iKD5GVybBnVqmr {
		min-width: 93.4375rem;
	}

	#UtvVMqVRRA3ZTwkM {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#helZKrihA0xCp3xD {
		grid-area: 22 / 9 / 23 / 27;
		grid-template-columns: 0 0 28.62448364rem 36.08436605rem 28.62448364rem 0;
		grid-template-rows: 0 minmax(36.08422171rem, max-content) minmax(5rem, max-content) minmax(5.87499583rem, max-content) minmax(1.47684863rem, max-content) minmax(5.15277083rem, max-content);
	}

	#DW1PWvIrj05MYTzA {
		grid-area: 22 / 9 / 23 / 27;
		position: relative;
	}

	#eaYJnouYhH8AeTXs {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#isxsKvHrpBiedV0f {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.00002083em - var(--ffsd)));
	}

	#UoamxoILsO10EG5q {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.00002083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#tgdsaEtbxSUDVtjW {
		min-width: 93.4375rem;
	}

	#lPBGxfXq4O81nS1I {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#K2Xke32ojoiwTvRX {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44447917em - var(--ffsd)));
	}

	#addOTvYUpqkiGMBY {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44447917em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#cn95RVlW3BppVxbB {
		min-width: 93.4375rem;
	}

	#qt6Fy2LMKhfmHiWO {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#CcYiAMGi67m1j3jt {
		grid-area: 24 / 10 / 25 / 28;
		grid-template-columns: 0 0 28.62448364rem 36.08436605rem 28.62448364rem 0;
		grid-template-rows: 0 minmax(36.08422171rem, max-content) minmax(5rem, max-content) minmax(5.87499583rem, max-content) minmax(1.47684863rem, max-content) minmax(5.15277083rem, max-content);
	}

	#bKS3hojd0vWCsG3G {
		grid-area: 24 / 10 / 25 / 28;
		position: relative;
	}

	#LCgfLLbcF7R1C5Ky {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#RRlSofbn2exyjhSo {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.00002083em - var(--ffsd)));
	}

	#pIV69ietZi13B1dT {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.00002083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#K1yEwqXyFsPf1HK2 {
		min-width: 93.4375rem;
	}

	#yGXLNKXlE0Lx4hng {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#Z1FtDDBo5h4wMoRV {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44447917em - var(--ffsd)));
	}

	#Y6bPOOQDE0l0RJ3I {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44447917em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#oJGsidyPS9GBmdjA {
		min-width: 93.4375rem;
	}

	#WCYVRqjPYrdeqHy2 {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#MCREdEQaeq1a3hMP {
		grid-area: 26 / 11 / 27 / 29;
		grid-template-columns: 0 0 28.62448364rem 36.08436605rem 28.62448364rem 0;
		grid-template-rows: 0 minmax(36.08422171rem, max-content) minmax(5rem, max-content) minmax(5.87499583rem, max-content) minmax(1.47684863rem, max-content) minmax(5.15277083rem, max-content);
	}

	#QLcfpqVrH3H1mvpy {
		grid-area: 26 / 11 / 27 / 29;
		position: relative;
	}

	#mU73NrSzR8LyStZH {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#u4CN5ZtTl2KDneKG {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.00002083em - var(--ffsd)));
	}

	#WvRywGLPll6BU7wk {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.00002083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Zzvv5ImtUTITuzQn {
		min-width: 93.4375rem;
	}

	#xBm3VRHfGheG2x42 {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#LnpNv0pmu6wvkbvS {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44447917em - var(--ffsd)));
	}

	#UMsUl7dcFKO4BRG1 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44447917em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#BX9VZoh3BmRroik7 {
		min-width: 93.4375rem;
	}

	#FvMjt8b0eWx8TBYx {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#sZHhnQgWODExXYwG {
		grid-area: 28 / 12 / 29 / 30;
		grid-template-columns: 0 0 28.62448364rem 36.08436605rem 28.62448364rem 0;
		grid-template-rows: 0 minmax(36.08422171rem, max-content) minmax(5rem, max-content) minmax(5.87499583rem, max-content) minmax(1.47684863rem, max-content) minmax(5.15277083rem, max-content);
	}

	#otiEaCfYPF6XZBOs {
		grid-area: 28 / 12 / 29 / 30;
		position: relative;
	}

	#pzMKaFw2GamEqzIy {
		grid-area: 2 / 4 / 3 / 5;
		position: relative;
	}

	#hNUWSf5vHG23PS3z {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.00002083em - var(--ffsd)));
	}

	#TQtVepH5l0TIWSzI {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.00002083em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#vYc7XNQkg7SIdiVs {
		min-width: 93.4375rem;
	}

	#cctJXIHRRhmwZh6O {
		grid-area: 4 / 2 / 5 / 6;
		position: relative;
	}

	#aUveYXKfXDgnTBey {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.44447917em - var(--ffsd)));
	}

	#piQVsorWHZwkaZOq {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.44447917em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#YER3aBDStQlIEHTA {
		min-width: 93.4375rem;
	}

	#ajwEh7aIxhCT1eVz {
		grid-area: 6 / 3 / 7 / 7;
		position: relative;
	}

	#ViYwSgKN4trQebbn {
		grid-area: 30 / 13 / 31 / 31;
		grid-template-columns: 0 0 28.62448364rem 36.08436605rem 28.62448364rem 0;
		grid-template-rows: 0 minmax(36.08422171rem, max-content) minmax(5rem, max-content) minmax(5.87499583rem, max-content) minmax(1.47684863rem, max-content) minmax(5.15277083rem, max-content);
	}

	#r5IyhZrlvmrMTAxN {
		grid-area: 30 / 13 / 31 / 31;
		position: relative;
	}

	#n4a0cHUfK1HSTCGF {
		grid-template-columns: 3.33333333rem 0 0 0 0 0 0 0 0 0 0 0 10.20321573rem 18.42126791rem 0 36.08436605rem 0 18.42126791rem 10.20321573rem 0 0 0 0 0 0 0 0 0 0 0 3.33333333rem;
		grid-template-rows: minmax(8.54166667rem, max-content) minmax(6.67910937rem, max-content) minmax(8.58635474rem, max-content) minmax(36.08422171rem, max-content) minmax(5rem, max-content) minmax(12.5046153rem, max-content) minmax(5rem, max-content) minmax(53.58883701rem, max-content) minmax(8.54166667rem, max-content) minmax(36.08422171rem, max-content) minmax(5rem, max-content) minmax(5.87499583rem, max-content) minmax(1.47684863rem, max-content) minmax(5.15277083rem, max-content) minmax(5rem, max-content) minmax(53.58883701rem, max-content) minmax(8.54166667rem, max-content) minmax(53.58883701rem, max-content) minmax(5rem, max-content) minmax(53.58883701rem, max-content) minmax(8.54166667rem, max-content) minmax(53.58883701rem, max-content) minmax(5rem, max-content) minmax(53.58883701rem, max-content) minmax(8.54166667rem, max-content) minmax(53.58883701rem, max-content) minmax(5rem, max-content) minmax(53.58883701rem, max-content) minmax(8.54166667rem, max-content) minmax(53.58883701rem, max-content) minmax(8.54166667rem, max-content);
	}

	#wX2VRa0JC2UPOxM0 {
		min-height: calc(33.2341896 * var(--1vh, 1vh));
	}

	#S8kA0ZtQhjOQOKwj {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#YnBX1w065wi4uN4L {
		grid-template-columns: 0 665.45012009%;
		left: -282.72506005%;
		grid-template-rows: 0 100%;
	}

	#BIyN9FNt9OkkECvl {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(10.96232385em - var(--ffsd)));
	}

	#RwCegoRE1rKV4jS1 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 10.96232385em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.01));
		margin-top: calc(var(--first-font-size) * 0.05);
		margin-bottom: calc(var(--last-font-size) * 0.05);
	}

	#KhQaKh5oM3fMkcAn {
		min-width: 93.4375rem;
	}

	#NFmxBSeb5ywYlty6 {
		grid-area: 2 / 2 / 3 / 9;
		position: relative;
	}

	#kj3ETsDcUZlOhSvk {
		display: block;
	}

	#MsSDKJfvbQdX1ZDD {
		display: none;
	}

	#t9ODRQGA8cKjRez5 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#ZuDkwwAUgk04AJ2w {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#CF1kYwEbzZQkz1dz {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#gZibwNpUF5S5So4n {
		grid-template-columns: 0 1.78023259rem 51.88796505rem 1.78023259rem;
		grid-template-rows: 0 minmax(1.78033636rem, max-content) minmax(51.8877575rem, max-content) minmax(1.78033636rem, max-content);
	}

	#DGdFCTYYFCGRfuLt {
		grid-template-columns: 0 55.44843022rem;
		grid-template-rows: 0 minmax(55.44843022rem, max-content);
	}

	#fxObjboe2MIoW7di {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.83333333em - var(--ffsd)));
	}

	#W9H8dVYPp2W8dmjw {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.83333333em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#gEQRasMlCYlwIzfa {
		min-width: 72.46232762rem;
	}

	#QFPWF4UKRxWTTLdT {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#SeypdGqfELB5RHl9 {
		grid-area: 4 / 3 / 5 / 6;
		grid-template-columns: 0 6.31031894rem 6.62502526rem 55.44843022rem 10.28470547rem 2.65063873rem;
		grid-template-rows: 0 minmax(6.77870232rem, max-content) minmax(55.44843022rem, max-content) minmax(6.60565641rem, max-content) minmax(6.95833333rem, max-content) minmax(25.90643847rem, max-content);
	}

	#XIFNPdWiGGPXVg6A {
		grid-area: 4 / 3 / 5 / 6;
		position: relative;
	}

	#CKMIPKAXamZbG3Go {
		display: block;
	}

	#k86WiYPZcta16CE9 {
		display: none;
	}

	#i7KgHSW2144mi1Li {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#AUVv7WdTmylWb1yh {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#UQoc6JyZzEgKohPS {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#BPky5TaRtu6hUNtl {
		grid-template-columns: 0 1.78023259rem 51.88796505rem 1.78023259rem;
		grid-template-rows: 0 minmax(1.78033636rem, max-content) minmax(51.8877575rem, max-content) minmax(1.78033636rem, max-content);
	}

	#fvh4EVCDc8wFidp6 {
		grid-template-columns: 0 55.44843022rem;
		grid-template-rows: 0 minmax(55.44843022rem, max-content);
	}

	#BTHywGdE1TJza1E2 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.83333333em - var(--ffsd)));
	}

	#lVDcLMcaMgEIaSmp {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.83333333em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#O59aprSV0Sz3FX7f {
		min-width: 72.46232762rem;
	}

	#MJ7fklYwLMVRwUVd {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#SmtITIiN4OkX9KGF {
		grid-area: 6 / 4 / 7 / 7;
		grid-template-columns: 0 4.48047884rem 8.45486537rem 55.44843022rem 8.45486537rem 4.48047884rem;
		grid-template-rows: 0 minmax(6.77870232rem, max-content) minmax(55.44843022rem, max-content) minmax(5.14732308rem, max-content) minmax(15.08333333rem, max-content) minmax(19.23977181rem, max-content);
	}

	#njrbbX2MIZ8ACucA {
		grid-area: 6 / 4 / 7 / 7;
		position: relative;
	}

	#BJ56qU9IrfHKWEXS {
		display: block;
	}

	#fP9uOK7sNZdwTEie {
		display: none;
	}

	#ONnBplxbFRBxwQWD {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#OyJrHIjj9L40dH67 {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#nFBTSV5Qg3f3G0yu {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#DJumzARuTyfZ5TcT {
		grid-template-columns: 0 1.78023259rem 51.88796505rem 1.78023259rem;
		grid-template-rows: 0 minmax(1.78033636rem, max-content) minmax(51.8877575rem, max-content) minmax(1.78033636rem, max-content);
	}

	#QYluzNfhXHF1wj7k {
		grid-template-columns: 0 55.44843022rem;
		grid-template-rows: 0 minmax(55.44843022rem, max-content);
	}

	#Y5R0b5dzA8Q6in1f {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.83333333em - var(--ffsd)));
	}

	#UL33FtBMoc9uKClH {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.83333333em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#xIQ052HO4W1UWtzn {
		min-width: 72.46232762rem;
	}

	#EjvTHHYfq6J4DxNd {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#rhBP5TtNEKB13PR5 {
		grid-area: 8 / 5 / 9 / 8;
		grid-template-columns: 0 4.48047884rem 8.45486537rem 55.44843022rem 8.45486537rem 4.48047884rem;
		grid-template-rows: 0 minmax(6.77870232rem, max-content) minmax(55.44843022rem, max-content) minmax(4.73065641rem, max-content) minmax(23.20833333rem, max-content) minmax(11.53143847rem, max-content);
	}

	#wP5w8HIdiGqngKxo {
		grid-area: 8 / 5 / 9 / 8;
		position: relative;
	}

	#ylGXbSvVUSJkKvSi {
		grid-template-columns: 3.33333333rem 6.00710735rem 0 0 81.31911863rem 0 0 6.00710735rem 3.33333333rem;
		grid-template-rows: minmax(6.60360292rem, max-content) minmax(12.05855623rem, max-content) minmax(10.3912529rem, max-content) minmax(101.69756076rem, max-content) minmax(5rem, max-content) minmax(101.69756076rem, max-content) minmax(5rem, max-content) minmax(101.69756076rem, max-content) minmax(6.60360292rem, max-content);
	}

	#trPu9NDNn3GsxH2i {
		min-height: calc(16.90544082 * var(--1vh, 1vh));
	}

	#kHTr4UMRKuBnmI2s {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#Ss1yn1mETT9jLgzw {
		grid-template-columns: 0 251.89574165%;
		left: -75.94787082%;
		grid-template-rows: 0 100%;
	}

	#yOyv0g931HWP8K5w {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(11.72499107em - var(--ffsd)));
	}

	#adtT21cbrs3OWsBi {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(11.72499107em - var(--ffsd)));
	}

	#U2o7tnUjIVGm7rT4 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 11.72499107em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#zVyrooSEco4MLXWd {
		min-width: 93.4375rem;
	}

	#Ty2xMlXlERNrWhyE {
		grid-area: 2 / 2 / 3 / 39;
		position: relative;
	}

	#oqCLFGq0U0ONLB4j {
		stroke-width: calc(100rem * 2.8368528371623283 / 480.0);
	}

	#Z79YFQyvL6A7jGzp {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#Z5ZTkUL0Ig3DZLjq {
		font-size: calc(3.41473355em - var(--ffsd));
	}

	#GdcocGV83UV2GJAx {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#s8AJLcuBl5zakdRa {
		min-width: 10.4550055rem;
	}

	#DI5Uu8GnvsTdMf3D {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#T8d5WFIxemZpAfwI {
		grid-template-columns: 0 0.68951284rem 10.35083883rem 0.68951284rem;
		grid-template-rows: 0 minmax(0.68951284rem, max-content) minmax(5.77294325rem, max-content) minmax(0.68951284rem, max-content);
	}

	#n7J1efHR2vqf1rmi {
		grid-area: 4 / 7 / 15 / 11;
		grid-template-columns: 0 11.72986451rem;
		grid-template-rows: 0 minmax(7.15196893rem, max-content);
	}

	#lBKAzfbKtQiOdvnt {
		grid-area: 4 / 7 / 15 / 11;
		position: relative;
	}

	#fd7DnM6VGWagSWZz {
		stroke-width: calc(100rem * 2.836852837162325 / 480.0);
	}

	#ZkEx1ZIMnCrNc8xV {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#VZFNOjVakBwpupN5 {
		font-size: calc(3.41473355em - var(--ffsd));
	}

	#ZlhIdLtFTKa2t6CN {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#p1jBVyW4SVentMnI {
		min-width: 10.4550055rem;
	}

	#QwE6X2n2GBgM7VkT {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#OGSoeKRLoF8GZsJ0 {
		grid-template-columns: 0 0.68951284rem 10.35083883rem 0.68951284rem;
		grid-template-rows: 0 minmax(0.68951284rem, max-content) minmax(5.7664304rem, max-content) minmax(0.68951284rem, max-content);
	}

	#FdjeAv9k94ie8Hm7 {
		grid-area: 5 / 14 / 14 / 22;
		grid-template-columns: 0 11.72986451rem;
		grid-template-rows: 0 minmax(7.14545608rem, max-content);
	}

	#GtJfgCuMkrhj1Q8V {
		grid-area: 5 / 14 / 14 / 22;
		position: relative;
	}

	#OgCJtlbWkRiQYND9 {
		stroke-width: calc(100rem * 2.8368528371623265 / 480.0);
	}

	#yp9yqr9m3tlFfmJE {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#WcJrTy8ZzZLbIWkM {
		font-size: calc(3.41473355em - var(--ffsd));
	}

	#u2WknSR2ixrKnkck {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#kKel268v0eLrEjK5 {
		min-width: 10.4550055rem;
	}

	#VuraA5m92j3CCFSt {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#C98T03WjsWcp9Naq {
		grid-template-columns: 0 0.68951284rem 10.35083883rem 0.68951284rem;
		grid-template-rows: 0 minmax(0.68951284rem, max-content) minmax(5.7664304rem, max-content) minmax(0.68951284rem, max-content);
	}

	#n17msLyZGzQZY6fS {
		grid-area: 6 / 26 / 16 / 33;
		grid-template-columns: 0 11.72986451rem;
		grid-template-rows: 0 minmax(7.14545608rem, max-content);
	}

	#NiwwiVOybqaY1G8L {
		grid-area: 6 / 26 / 16 / 33;
		position: relative;
	}

	#GhJoiNdyc2YK5h9U {
		grid-area: 7 / 20 / 11 / 27;
		position: relative;
	}

	#sl7v8yV5XWSPHbhU {
		grid-area: 8 / 9 / 12 / 15;
		position: relative;
	}

	#Nrsym2i8EB1IfRhY {
		grid-area: 9 / 36 / 31 / 40;
		position: relative;
	}

	#uCXPiiT1S0JJBJ5s {
		grid-area: 10 / 31 / 13 / 37;
		position: relative;
	}

	#lIBf666LxGtI2SbB {
		font-size: calc(3.41473355em - var(--ffsd));
	}

	#aWqmrTrjK6vno2h5 {
		--first-font-size: 3.41473355em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#p58b2GPPiCvdMdNp {
		min-width: 27.24142211rem;
	}

	#uQtFKQYrNz0iB93Z {
		grid-area: 17 / 3 / 22 / 13;
		position: relative;
	}

	#e6wXpRmulXDPT0u0 {
		font-size: calc(3.41473355em - var(--ffsd));
	}

	#fDc8qWFKZszTq4Yj {
		--first-font-size: 3.41473355em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#kzR5Fx3oJ6GLRhRT {
		min-width: 27.24142211rem;
	}

	#johfP1Pxott9vEEM {
		grid-area: 18 / 16 / 20 / 25;
		position: relative;
	}

	#TmT1hYbB6GVMF1ld {
		font-size: calc(3.41473355em - var(--ffsd));
	}

	#ZXPctumbRe0FlOvq {
		--first-font-size: 3.41473355em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#wRVdpMXlteyerwko {
		min-width: 28.53369716rem;
	}

	#QfubwHlgOUkIDLKo {
		grid-area: 19 / 28 / 23 / 35;
		position: relative;
	}

	#mE358ak8TuBfPFmW {
		font-size: calc(2.10136919em - var(--ffsd));
	}

	#FZfpR6RMLnUi7Vdn {
		--first-font-size: 2.10136919em;
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Ai0HO0vCRAKv5G3L {
		min-width: 26.73775935rem;
	}

	#gGj211OtpCfkR6kL {
		grid-area: 21 / 17 / 24 / 24;
		position: relative;
	}

	#uS6qp7fPuFhX50yO {
		stroke-width: calc(100rem * 2.8368528371623265 / 480.0);
	}

	#vocqBlV3eDAZhjKe {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#zK3sCCVcD7dmKKm2 {
		font-size: calc(3.41473355em - var(--ffsd));
	}

	#KbIIjrYbW7veptRY {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#mFh0wHMPNvn6BCF5 {
		min-width: 10.4550055rem;
	}

	#QyApyhorWpS58HkO {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#ox3LXDzAhHnOZYgN {
		grid-template-columns: 0 0.68951284rem 10.35083883rem 0.68951284rem;
		grid-template-rows: 0 minmax(0.68951284rem, max-content) minmax(5.7664304rem, max-content) minmax(0.68951284rem, max-content);
	}

	#T82aFK7WnfXqzfpW {
		grid-area: 25 / 8 / 35 / 12;
		grid-template-columns: 0 11.72986451rem;
		grid-template-rows: 0 minmax(7.14545608rem, max-content);
	}

	#OO4yQCeKx191j6uX {
		grid-area: 25 / 8 / 35 / 12;
		position: relative;
	}

	#LqaJvKuc3Dk7x7QU {
		stroke-width: calc(100rem * 2.836852837162324 / 480.0);
	}

	#orI8ZDwTqchi8NYh {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#d8nsYxyPh7lcHoTK {
		font-size: calc(3.41473355em - var(--ffsd));
	}

	#ELugkCrhsZAmxoaj {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#ueRrGfE4wVgueoGt {
		min-width: 10.4550055rem;
	}

	#aXZUTVOOdAdtC3Vw {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#XxiLMbXAvwIanOG5 {
		grid-template-columns: 0 0.68951284rem 10.35083883rem 0.68951284rem;
		grid-template-rows: 0 minmax(0.68951284rem, max-content) minmax(5.7664304rem, max-content) minmax(0.68951284rem, max-content);
	}

	#tS81KE5Rcn2QKSxO {
		grid-area: 26 / 29 / 36 / 34;
		grid-template-columns: 0 11.72986451rem;
		grid-template-rows: 0 minmax(7.14545608rem, max-content);
	}

	#S7qU5KJYk1eVtJA5 {
		grid-area: 26 / 29 / 36 / 34;
		position: relative;
	}

	#KrqNnFZ0UIxhION3 {
		stroke-width: calc(100rem * 2.836852837162326 / 480.0);
	}

	#OsISqtUJstEsYRfG {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#WscMkLBpM8dD8UiW {
		font-size: calc(3.41473355em - var(--ffsd));
	}

	#RKcG0Qp5pw5GyuIV {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#BNLm32gEMenNBav0 {
		min-width: 10.4550055rem;
	}

	#EN1LHn87g78DtoPK {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#nTOFQixz6fgxoAav {
		grid-template-columns: 0 0.68951284rem 10.35083883rem 0.68951284rem;
		grid-template-rows: 0 minmax(0.68951284rem, max-content) minmax(5.7664304rem, max-content) minmax(0.68951284rem, max-content);
	}

	#CtnzpmscK8aNO1rt {
		grid-area: 27 / 18 / 37 / 23;
		grid-template-columns: 0 11.72986451rem;
		grid-template-rows: 0 minmax(7.14545608rem, max-content);
	}

	#mRfiAAgVSN6DXzlS {
		grid-area: 27 / 18 / 37 / 23;
		position: relative;
	}

	#q08wngjnlMogU6O7 {
		grid-area: 28 / 32 / 32 / 38;
		position: relative;
	}

	#meBqLPJvjQ8guX8N {
		grid-area: 29 / 10 / 33 / 19;
		position: relative;
	}

	#yT6e7k3zwC2ZhEZB {
		grid-area: 30 / 21 / 34 / 30;
		position: relative;
	}

	#bjiwT6n0eEvP3AL2 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.22222917em - var(--ffsd)));
	}

	#XgZ35s1n4uX7dHq7 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.22222917em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#LnWGuNxmhGGIADzm {
		min-width: 93.4375rem;
	}

	#bLIpGnn9dfE3fTZL {
		grid-area: 38 / 4 / 39 / 41;
		position: relative;
	}

	#ZAT3ONIo6ol1EBRK {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.22222917em - var(--ffsd)));
	}

	#KlNpbQ0JZO8toHNj {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.22222917em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#Xn2JAL6I0PxjoTrM {
		min-width: 93.4375rem;
	}

	#sAebCSIQptDj5wa4 {
		grid-area: 40 / 5 / 41 / 42;
		position: relative;
	}

	#kgS5Q5htFjObvksW {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.22222917em - var(--ffsd)));
	}

	#BWJudQgPUxg1KJdG {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.22222917em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#l1oqJzovwzrxiZCh {
		min-width: 93.4375rem;
	}

	#MKXkDi6tk3E1t8T7 {
		grid-area: 42 / 6 / 43 / 43;
		position: relative;
	}

	#GhY6Ca0LFKImBmOZ {
		grid-template-columns: 3.33333333rem 0 0 0 0 1.04354816rem 0 0.05138052rem 7.05183624rem 4.62664775rem 0 14.36384277rem 3.74872783rem 0 0.19406893rem 0 0.99972007rem 1.13651867rem 2.14048836rem 3.02335077rem 4.23571771rem 1.193789rem 13.90400811rem 0.50366275rem 3.74598633rem 0 0.19408705rem 0.99970194rem 0.29257311rem 4.61715271rem 1.29228018rem 4.33406952rem 1.193789rem 15.69996403rem 2.54941815rem 0.00000513rem 0.09849671rem 0.09850183rem 0 0 0 0 3.33333333rem;
		grid-template-rows: minmax(13.43240111rem, max-content) minmax(25.51186926rem, max-content) minmax(14.80584933rem, max-content) 0 minmax(0.0306367rem, max-content) minmax(2.90182943rem, max-content) minmax(0.00000003rem, max-content) minmax(0.0103947rem, max-content) minmax(0.01162547rem, max-content) minmax(0.17767416rem, max-content) minmax(0.00580653rem, max-content) minmax(0.01482749rem, max-content) minmax(3.99266158rem, max-content) minmax(0.00651285rem, max-content) minmax(0.02412385rem, max-content) minmax(2.33992018rem, max-content) 0 0 minmax(4.03857522rem, max-content) minmax(1.16564946rem, max-content) minmax(2.87292576rem, max-content) 0 minmax(2.41990681rem, max-content) minmax(8.72628612rem, max-content) 0 minmax(0.14704449rem, max-content) minmax(2.83265979rem, max-content) 0 0 minmax(0.09980634rem, max-content) minmax(0.09719733rem, max-content) minmax(0.00130451rem, max-content) 0 minmax(3.96744363rem, max-content) 0 minmax(0.14704449rem, max-content) minmax(4.75610928rem, max-content) minmax(8.54166667rem, max-content) minmax(5rem, max-content) minmax(10.50548735rem, max-content) minmax(5rem, max-content) minmax(10.50548735rem, max-content) minmax(13.43240111rem, max-content);
	}

	#gZet15UmlwsvfI1A {
		min-height: calc(7.11032265 * var(--1vh, 1vh));
	}

	#jRCRvtwjSMdf7dIv {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#rUzZEQ49fMRslBaD {
		grid-template-columns: 0 604.82701466%;
		left: -252.41350733%;
		grid-template-rows: 0 100%;
	}

	#pERlgE2a6DOamg6l {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.60411417em - var(--ffsd)));
	}

	#BEYU8ezFNskJdWkQ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.60411417em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#IbJmVidWel9B0IPZ {
		min-width: 93.4375rem;
	}

	#T4fZl6W85JwWkmDH {
		grid-area: 2 / 2 / 3 / 9;
		position: relative;
	}

	#rYbzezNQVmwN8rwy {
		stroke-width: calc(100rem * 2.0 / 480.0);
	}

	#PDFkidM4k8ElFqJF {
		display: block;
	}

	#MUtTzzmY3wYNkqu9 {
		stroke-width: calc(100rem * 2.0 / 480.0);
	}

	#QtX0JYR7w7rndoZ5 {
		display: none;
	}

	#OTGewPxk07Zd90R6 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#oncK24U5jVqFCrdR {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.388875em - var(--ffsd)));
	}

	#ZDFzxS6yqsQjtUYd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.388875em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#zbMFIGzzq8ZFzfcn {
		min-width: 73.02083333rem;
	}

	#zQSnqfE5AUwppsqQ {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#ONx5rCSDHvJt6fyo {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(15.5555625em - var(--ffsd)));
	}

	#hfaM6HCpkz1KTcrg {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 15.5555625em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#LTgRR7rIEa4qPPSg {
		min-width: 73.02083333rem;
	}

	#IdK3IYQ5gJ2E9vGc {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#wue0L8NHiWgYHjue {
		grid-area: 4 / 3 / 5 / 6;
		grid-template-columns: 0 3.64583333rem 0 72.91666667rem 0 3.64583333rem;
		grid-template-rows: 0 minmax(33.28191245rem, max-content) minmax(5.16389167rem, max-content) minmax(8.42815471rem, max-content) minmax(18.54166667rem, max-content) minmax(33.28191245rem, max-content);
	}

	#lvxty16L5kF1dCCb {
		grid-area: 4 / 3 / 5 / 6;
		position: relative;
	}

	#YjTkzhc6SZ0L6Avv {
		stroke-width: calc(100rem * 2.0 / 480.0);
	}

	#AdUikw940oRh1NmJ {
		display: block;
	}

	#vwWQnIHe3A9wyvst {
		stroke-width: calc(100rem * 2.0 / 480.0);
	}

	#kOCJPLER4qSmdRM7 {
		display: none;
	}

	#l88C7xESGFGWgt0W {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#cFsKtSFex865II4H {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.388875em - var(--ffsd)));
	}

	#HHbP8BOU0jGyE06x {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.388875em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#a1LUNrAChs6Vb2uV {
		min-width: 73.02083333rem;
	}

	#U6h52VD3Dj4WZdWw {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#eER1JQoWpDTt7q37 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(15.5555625em - var(--ffsd)));
	}

	#UJ961bvHYI3OriqG {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 15.5555625em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#TGSKVP6ICt7lSThL {
		min-width: 73.02083333rem;
	}

	#btkfbwdo0qn6m1Zt {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#RMgCheHCmyErjAQe {
		grid-area: 6 / 4 / 7 / 7;
		grid-template-columns: 0 3.64583333rem 0 72.91666667rem 0 3.64583333rem;
		grid-template-rows: 0 minmax(33.28191245rem, max-content) minmax(5.16389167rem, max-content) minmax(8.42815471rem, max-content) minmax(18.54166667rem, max-content) minmax(33.28191245rem, max-content);
	}

	#Yfrnbcageco4Gcuq {
		grid-area: 6 / 4 / 7 / 7;
		position: relative;
	}

	#DIu6XcaVsIuQQPBK {
		stroke-width: calc(100rem * 2.0 / 480.0);
	}

	#GcQ4LsbvJsRrMHSn {
		display: block;
	}

	#K1TmHAQ3NTsS6CIe {
		stroke-width: calc(100rem * 2.0 / 480.0);
	}

	#Q8N5fGVL069S5m2o {
		display: none;
	}

	#sjN2e2nPj0nSVyq7 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#CBHM7m8mUtjuOrEs {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.388875em - var(--ffsd)));
	}

	#XXZxi89lGk9b8ejT {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.388875em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#SLjhKolKiVzXVrsT {
		min-width: 73.02083333rem;
	}

	#hYLhMa3KXE2s1Jbu {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#yNzssimMPjkn4EDf {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(15.5555625em - var(--ffsd)));
	}

	#P1ktvJOBoIXGIm5k {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 15.5555625em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#TNKIZVhxUC29gE96 {
		min-width: 73.02083333rem;
	}

	#UYh7MZfXbNvpGa1x {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#bUFcaRcAwsloJUS1 {
		grid-area: 8 / 5 / 9 / 8;
		grid-template-columns: 0 3.64583333rem 0 72.91666667rem 0 3.64583333rem;
		grid-template-rows: 0 minmax(33.28191245rem, max-content) minmax(5.16389167rem, max-content) minmax(8.42815471rem, max-content) minmax(18.54166667rem, max-content) minmax(33.28191245rem, max-content);
	}

	#ctNvYifBfbOcadsC {
		grid-area: 8 / 5 / 9 / 8;
		position: relative;
	}

	#GUXT0duiXwA8EfRP {
		grid-template-columns: 3.33333333rem 6.5625rem 0 0 80.20833333rem 0 0 6.5625rem 3.33333333rem;
		grid-template-rows: minmax(7.35691077rem, max-content) minmax(7.82065638rem, max-content) minmax(11.42206436rem, max-content) minmax(98.69753795rem, max-content) minmax(5rem, max-content) minmax(98.69753795rem, max-content) minmax(5rem, max-content) minmax(98.69753795rem, max-content) minmax(7.35691077rem, max-content);
	}

	#aPvYMDHfcpxuVvZR {
		min-height: calc(16.38969592 * var(--1vh, 1vh));
	}

	#iPa1wvEJHh7MXB61 {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#L7Ny0815ayHKHP5q {
		grid-template-columns: 0 280.70383176%;
		left: -90.35191588%;
		grid-template-rows: 0 100%;
	}

	#jGOsWVsi010iQYBh {
		grid-area: 2 / 2 / 3 / 5;
		position: relative;
	}

	#O8BHPgjUtD0pmoqA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.58836157em - var(--ffsd)));
	}

	#d5AggWJARNgm3nDZ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.58836157em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#O64IhSkVydVH3KqG {
		min-width: 93.4375rem;
	}

	#dQU1i5c0LdjIwoHb {
		grid-area: 4 / 3 / 5 / 6;
		position: relative;
	}

	#GeGJujlNl6iyMkqN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5em - var(--ffsd)));
	}

	#H1by1XvAIV7Avdbp {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#B7wY3lpXLCMJPR6r {
		min-width: 93.4375rem;
	}

	#tiOrR4NtAKmPXQ6Z {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#ObHuCP0zAYEk9Y0Q {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.0555625em - var(--ffsd)));
	}

	#H9F8tLkkfPNO9nNe {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.0555625em - var(--ffsd)));
	}

	#PejqGcvSTOEQWUub {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.0555625em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.25);
		margin-bottom: calc(var(--last-font-size) * -0.25);
	}

	#CcbMq47PBBoURPM4 {
		min-width: 93.4375rem;
	}

	#y8ZVVD6eAwbW5CAN {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#cqrdRbtvLdMhz6JK {
		grid-area: 6 / 4 / 7 / 7;
		grid-template-columns: 0 0 93.33333333rem 0;
		grid-template-rows: 0 minmax(5rem, max-content) minmax(5rem, max-content) minmax(10.3889125rem, max-content);
	}

	#TSeRaD7xZMnUgP0y {
		grid-area: 6 / 4 / 7 / 7;
		position: relative;
	}

	#YMYaoqrtVtrsfzDu {
		grid-template-columns: 3.33333333rem 0 0 93.33333333rem 0 0 3.33333333rem;
		grid-template-rows: minmax(9.59769552rem, max-content) minmax(93.33333333rem, max-content) minmax(5rem, max-content) minmax(7.84721506rem, max-content) minmax(12.05399345rem, max-content) minmax(20.3889125rem, max-content) minmax(9.59769552rem, max-content);
	}

	#nuN8I7IXOi48cTmn {
		min-height: calc(7.60655582 * var(--1vh, 1vh));
	}
}

@media (min-width: 480.05px) and (max-width: 768px) {
	#z2W0t9LO5y0FgQWr {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#SL9cu5l5y0CTwRB4 {
		grid-template-columns: 0 232.94425459%;
		left: -66.4721273%;
		grid-template-rows: 0 100%;
	}

	#iPU6c5lFlFiI4WQx {
		font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), calc(1.38889323em - var(--ffsd)));
	}

	#SQw9hne1KHFmnPeO {
		--first-font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), 1.38889323em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#qGOK3VZjCeecOc2P {
		min-width: 7.3785235rem;
	}

	#NJRAWD1XDTwmEvpu {
		grid-area: 2 / 2 / 6 / 8;
		position: relative;
	}

	#NG5fK23CeU4hquvT {
		font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), calc(1.38889323em - var(--ffsd)));
	}

	#RYk5nRdTfy6ghNrP {
		--first-font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), 1.38889323em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#yXwa2mb3SldnynWe {
		min-width: 10.33609291rem;
	}

	#lGhmYDkXcM7f0GRU {
		grid-area: 3 / 9 / 7 / 12;
		position: relative;
	}

	#X18nBTuSzk3edEEL {
		font-size: calc(1.38889323em - var(--ffsd));
	}

	#u8V3KhdVr5emw0QB {
		--first-font-size: 1.38889323em;
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#h55kBAYDwPDbvnyF {
		min-width: 5.73068706rem;
	}

	#n5vKQHfYbPI0DpT4 {
		grid-area: 4 / 13 / 8 / 19;
		position: relative;
	}

	#uGUwMlcLNrFRmbfk {
		font-size: calc(1.38889323em - var(--ffsd));
	}

	#gUi6RKbWsjEA1j6H {
		--first-font-size: 1.38889323em;
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#TACHjxHiq8ZGGcif {
		min-width: 25.8408202rem;
	}

	#N7rN3NSSYMuShTjx {
		grid-area: 5 / 18 / 9 / 27;
		position: relative;
	}

	#Du8Ium0Vz4SQucN5 {
		grid-area: 10 / 21 / 11 / 26;
		position: relative;
	}

	#mpywhKD1DfgwfA57 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.13644501em - var(--ffsd)));
	}

	#LJWAD7W2Vy2MyEXR {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.13644501em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#pWiO0UiOKN5cFTrY {
		min-width: 91.73177083rem;
	}

	#GK7xmNvRcJq3bFP7 {
		grid-area: 12 / 3 / 13 / 30;
		position: relative;
	}

	#p9KIzIrs63ntBWqD {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.125em - var(--ffsd)));
	}

	#cCeEHicukaekaMlL {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.125em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#JDp9Rf0T8htahMtZ {
		min-width: 12.24975586rem;
	}

	#i9oe8d3Evx1TMcmk {
		grid-area: 3 / 2 / 4 / 3;
		position: relative;
	}

	#WA8YdHf3se5bPZ2S {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.64583333em - var(--ffsd)));
	}

	#jxiPMGfYUmhtOpQ7 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.64583333em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#rE7BUE1FKSY2WRFm {
		min-width: 51.20487877rem;
	}

	#lvv5wDelwCqsVXyx {
		grid-area: 2 / 4 / 5 / 5;
		position: relative;
	}

	#QXaeiBUaUbPUHBcN {
		grid-area: 14 / 10 / 15 / 29;
		grid-template-columns: 0 12.18465169rem 1.02557233rem 51.13977461rem;
		grid-template-rows: 0 minmax(0.390625rem, max-content) minmax(3.64583333rem, max-content) minmax(0.3125rem, max-content);
	}

	#TxIwfBhHAvKZFMNQ {
		grid-area: 14 / 10 / 15 / 29;
		position: relative;
	}

	#iY93JfbuXazRIRcr {
		grid-area: 16 / 22 / 17 / 25;
		position: relative;
	}

	#HmV1sKHCsxFDFLKN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.25em - var(--ffsd)));
	}

	#zDiUMWCVlE67rNVl {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.25em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.068));
		margin-top: calc(var(--first-font-size) * -0.125);
		margin-bottom: calc(var(--last-font-size) * -0.125);
	}

	#vpFqBZe5ZomsHCMJ {
		min-width: 24.23441835rem;
	}

	#LStX5ZKEr57y950h {
		grid-area: 18 / 4 / 20 / 14;
		position: relative;
	}

	#i8lQhV5BBOWvqgZN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.25em - var(--ffsd)));
	}

	#tLI0tPlrLmGOu16t {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.25em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.0405));
		margin-top: calc(var(--first-font-size) * -0.125);
		margin-bottom: calc(var(--last-font-size) * -0.125);
	}

	#G1G7iCYeTi4AzcZC {
		min-width: 20.774169rem;
	}

	#bIOwFZ0ypWmOBhXD {
		grid-area: 19 / 15 / 21 / 23;
		position: relative;
	}

	#YAmPSU77N2RJl4eA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.4305599em - var(--ffsd)));
	}

	#Oa8LdBBjIm5fsdV8 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.4305599em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#lp7dJl6SjNX87ORs {
		min-width: 24.77010091rem;
	}

	#VdkX9cESQH4i2k40 {
		grid-area: 23 / 5 / 24 / 16;
		position: relative;
	}

	#jNYg8u9bxzq2JDKz {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.47222656em - var(--ffsd)));
	}

	#ls0rVOMmpEllDERc {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.47222656em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#NAlqVZoTiEShReua {
		min-width: 9.74567693rem;
	}

	#BoTYHdTPWrLpcyE5 {
		grid-area: 22 / 17 / 25 / 20;
		position: relative;
	}

	#FC92Yx3X7HcMrmUQ {
		grid-area: 26 / 11 / 30 / 31;
		position: relative;
	}

	#WsUk8J16AAC9Ru2i {
		grid-area: 27 / 6 / 29 / 24;
		position: relative;
	}

	#yvFwVFLgHWnfiHHi {
		grid-area: 28 / 7 / 31 / 28;
		position: relative;
	}

	#io8xHR69844PRI5U {
		grid-template-columns: 4.16666667rem 0 0 0 0 6.20321522rem 1.11020412rem 1.97604711rem 4.2700305rem 0.09037484rem 5.9105834rem 3.04802678rem 1.56083222rem 0 0.53568256rem 0 1.3277276rem 2.24134052rem 6.11150465rem 7.07176956rem 0.25489253rem 3.16614742rem 1.57667499rem 3.49938107rem 0.25489253rem 1.59911278rem 0.84982885rem 25.25122635rem 13.75717109rem 0 4.16666667rem;
		grid-template-rows: minmax(3.60037628rem, max-content) 0 0 0 minmax(1.57407899rem, max-content) 0 0 0 minmax(3.77359294rem, max-content) minmax(8.7836413rem, max-content) minmax(4.16666667rem, max-content) minmax(3.74133474rem, max-content) minmax(0.64219607rem, max-content) minmax(4.34895833rem, max-content) minmax(4.16666667rem, max-content) minmax(7.86425411rem, max-content) minmax(6.7275956rem, max-content) 0 minmax(7.421875rem, max-content) 0 minmax(0.98999393rem, max-content) minmax(0.65104167rem, max-content) minmax(2.86458333rem, max-content) minmax(0.65104167rem, max-content) minmax(4.16666667rem, max-content) minmax(12.5408203rem, max-content) minmax(0.27629692rem, max-content) minmax(46.43353801rem, max-content) minmax(0.27629692rem, max-content) 0 minmax(3.60037628rem, max-content);
	}

	#u3SFqEqUiq3Yd9AS {
		min-height: calc(10.21223243 * var(--1vh, 1vh));
	}

	#fSeIbSOK25LfAyr9 {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#oxBQLPEYTzQnRNow {
		grid-template-columns: 0 169.37185077%;
		left: -34.68592538%;
		grid-template-rows: 0 100%;
	}

	#K4GjhCJlLhRsOHpE {
		grid-area: 2 / 8 / 3 / 10;
		position: relative;
	}

	#LjGKy3aqwD5f0kvO {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.4339974em - var(--ffsd)));
	}

	#Cy3Bx7uQW1irPtaW {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.4339974em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.015));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Xfkrn6HXr1JZUQoh {
		min-width: 41.04178729rem;
	}

	#ldgibYz5xXvUb0Rt {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#zzVPHxti09lpPp1q {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77989583em - var(--ffsd)));
	}

	#S4VRXPXyi8rvnwCL {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77989583em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#LLDeg5iIHwe3XlTN {
		min-width: 41.04178729rem;
	}

	#ckBv54l5HJrE5emB {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#hC7hJ5xZDnCcU29U {
		grid-template-columns: 0 0 40.97668312rem 0;
		grid-template-rows: 0 minmax(4.00070052rem, max-content) minmax(1.52314752rem, max-content) minmax(3.2200625rem, max-content);
	}

	#GZRDoTechanZzenm {
		grid-area: 4 / 5 / 5 / 12;
		position: relative;
	}

	#BAjQbjJ9VkJtrsgg {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.53262704em - var(--ffsd)));
	}

	#YzmjkTygXoFPnsZE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.53262704em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#J55E6XMFl1MtYrvm {
		min-width: 91.73177083rem;
	}

	#QSANUOBl4gQFbcpZ {
		grid-area: 6 / 2 / 7 / 14;
		position: relative;
	}

	#RhjdCAi1nq6z8qTW {
		grid-area: 8 / 4 / 9 / 13;
		position: relative;
	}

	#AKeXGtwqL9JUwDFh {
		grid-area: 10 / 7 / 11 / 9;
		position: relative;
	}

	#YAWsPgICRzktSn4r {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.4339974em - var(--ffsd)));
	}

	#SN3hAOnt3jCuw0R6 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.4339974em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.015));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#wWkeSV5YmODu4QGB {
		min-width: 35.08912368rem;
	}

	#tqIWfLEWsUWNb7Md {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#N70HGv8vxl1N2K22 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77989583em - var(--ffsd)));
	}

	#o4iJlppSTa6Pl1cE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77989583em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#CDqOtP9HmgK4cCL5 {
		min-width: 35.08912368rem;
	}

	#bpR1AVV9gcXSUIxq {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#W7BJ9Ea8WABbO7iA {
		grid-template-columns: 0 0 35.02401951rem 0;
		grid-template-rows: 0 minmax(4.00070052rem, max-content) minmax(1.52314752rem, max-content) minmax(3.2200625rem, max-content);
	}

	#P9GlGLj3YwKgduri {
		grid-area: 12 / 6 / 13 / 11;
		position: relative;
	}

	#v2sELbSjXLn37lbM {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.39897625em - var(--ffsd)));
	}

	#MKClK3FDAeuDU0wa {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.39897625em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#v2QlpyFCJWkdSM91 {
		min-width: 91.73177083rem;
	}

	#x17FaXp1woE5u6dY {
		grid-area: 14 / 3 / 15 / 15;
		position: relative;
	}

	#Zpybw2QrKoj7XLAc {
		grid-template-columns: 4.16666667rem 0 10.26041667rem 15.0845751rem 1.51423311rem 6.95900128rem 1.4620987rem 19.64391826rem 1.4620987rem 5.49690258rem 4.4384305rem 15.0845751rem 10.26041667rem 0 4.16666667rem;
		grid-template-rows: minmax(6.67001443rem, max-content) minmax(33.55641782rem, max-content) minmax(2.68981346rem, max-content) minmax(8.74391054rem, max-content) minmax(1.82291667rem, max-content) minmax(12.49037105rem, max-content) minmax(4.57860645rem, max-content) minmax(1.24505208rem, max-content) minmax(6.74144564rem, max-content) minmax(33.55641782rem, max-content) minmax(2.68981346rem, max-content) minmax(8.74391054rem, max-content) minmax(1.82291667rem, max-content) minmax(16.02375949rem, max-content) minmax(6.67001443rem, max-content);
	}

	#xONz65JYPuhP63HY {
		min-height: calc(11.69620689 * var(--1vh, 1vh));
	}

	#WMNyFVvdW8JDT3mq {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#lNDWuvQZOk10eZIb {
		grid-template-columns: 0 287.46293721%;
		left: -93.7314686%;
		grid-template-rows: 0 100%;
	}

	#GWKBOWWbMlMRE34y {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.46653687em - var(--ffsd)));
	}

	#gGhy3uiz4vkiQuRd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.46653687em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#fQuImrbrFCP5809S {
		min-width: 91.73177083rem;
	}

	#KuCcgaAAVItGKIlN {
		grid-area: 2 / 2 / 3 / 31;
		position: relative;
	}

	#w38lT8HHxpTCtpO0 {
		grid-area: 4 / 3 / 7 / 14;
		position: relative;
	}

	#PzoWd5qDgVaLmUDZ {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.12501302em - var(--ffsd)));
	}

	#QPGnTaypMoAPltwt {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.12501302em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#hzvp0OYD4RCo7uEv {
		min-width: 45.64441783rem;
	}

	#IrHTNhp1QPNAKOhS {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#B9n7zRcs29V0crDQ {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77779948em - var(--ffsd)));
	}

	#NbIX2BmwIHowCLEB {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77779948em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#iBASHVu12sWYgEA5 {
		min-width: 45.64441783rem;
	}

	#rObDKWScOEXbYZWx {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#d2nPwGoAmEq2u0uY {
		grid-area: 5 / 18 / 6 / 30;
		grid-template-columns: 0 0 45.57931366rem 0;
		grid-template-rows: 0 minmax(3.6718724rem, max-content) minmax(0.9230304rem, max-content) minmax(3.22048177rem, max-content);
	}

	#FwOEASN75NL9LIvV {
		grid-area: 5 / 18 / 6 / 30;
		position: relative;
	}

	#ZSRJPzhhA4UvXUXg {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#fumR30kYPtTqjQ7h {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.12501302em - var(--ffsd)));
	}

	#eJ0nuio3tOE3eLlK {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.12501302em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#fiDbBM8aiSeoBZTJ {
		min-width: 45.64441783rem;
	}

	#vwLPE9pIxvZev4hm {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#N5r1DfopvjC11lWp {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77779948em - var(--ffsd)));
	}

	#cbrdsrvGgor9AVFQ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77779948em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#wRhGvAHLO2UPdJVv {
		min-width: 45.64441783rem;
	}

	#K8i8CthCuAW2RNVt {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#ubW4cp95SqILM3ze {
		grid-area: 8 / 4 / 9 / 19;
		grid-template-columns: 0 22.55272878rem 3.30997254rem 0 45.57931366rem 0;
		grid-template-rows: 0 minmax(7.368627rem, max-content) minmax(3.6718724rem, max-content) minmax(0.9230304rem, max-content) minmax(3.22048177rem, max-content) minmax(7.368627rem, max-content);
	}

	#C9SXFJ76ZOq9YNLA {
		grid-area: 8 / 4 / 9 / 19;
		position: relative;
	}

	#sz2N5padSkeqtao1 {
		grid-area: 10 / 5 / 15 / 15;
		position: relative;
	}

	#ouKG2QSRbQ1FNpl5 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.12501302em - var(--ffsd)));
	}

	#F24kL3rMx9bV6DWO {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.12501302em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#SAmtaymZzgUuj19C {
		min-width: 45.64441783rem;
	}

	#FMbqVJ1UD4kpThwJ {
		grid-area: 11 / 16 / 12 / 20;
		position: relative;
	}

	#LkfhKA5raUua92yA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77779948em - var(--ffsd)));
	}

	#qTRcZhmLjAMOPuFK {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77779948em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#X79zQPuPWaOeSbE0 {
		min-width: 45.64441783rem;
	}

	#EUF1plnsAfhFY5RZ {
		grid-area: 13 / 17 / 14 / 21;
		position: relative;
	}

	#pFXXZ92FZMvZaXz8 {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#CxXylKhIAu0bJefE {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.12501302em - var(--ffsd)));
	}

	#tkVhyhsakD1AvZFY {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.12501302em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#PXhSK1K0HwBWdIqu {
		min-width: 45.64441783rem;
	}

	#UVCSVdyuM0GGAXVs {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#l6ITaRwTVeHdFnXq {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77779948em - var(--ffsd)));
	}

	#di4U2TujvKayvhoE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77779948em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#JJWbfo5XyQBWA34h {
		min-width: 45.64441783rem;
	}

	#LWy405G9NYzJL9s0 {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#NZGOgBKl8st2sJvf {
		grid-area: 16 / 6 / 17 / 22;
		grid-template-columns: 0 22.55272878rem 3.30997254rem 0 45.57931366rem 0;
		grid-template-rows: 0 minmax(7.368627rem, max-content) minmax(3.6718724rem, max-content) minmax(0.9230304rem, max-content) minmax(3.22048177rem, max-content) minmax(7.368627rem, max-content);
	}

	#hg1OQdqVYKdTpgJ6 {
		grid-area: 16 / 6 / 17 / 22;
		position: relative;
	}

	#EwKJj23G47qe68By {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#tCQQkKsjqoO3PcjD {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.12501302em - var(--ffsd)));
	}

	#wrAhpxnibQN5XUU9 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.12501302em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#qbKRJZpydbRkKZTi {
		min-width: 45.64441783rem;
	}

	#EQ7LVJMnL1stNRz9 {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#qCVKgoHQmemlevzc {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77779948em - var(--ffsd)));
	}

	#rkHryV8anWx6KcaB {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77779948em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#NOstk5h63Mb5TskG {
		min-width: 45.64441783rem;
	}

	#P27AihfcFDm7QolQ {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#SRp2YwMGW6KaXDqO {
		grid-area: 18 / 7 / 19 / 23;
		grid-template-columns: 0 22.55272878rem 3.30997254rem 0 45.57931366rem 0;
		grid-template-rows: 0 minmax(7.368627rem, max-content) minmax(3.6718724rem, max-content) minmax(0.9230304rem, max-content) minmax(3.22048177rem, max-content) minmax(7.368627rem, max-content);
	}

	#n3fwGSOdmcnKv76e {
		grid-area: 18 / 7 / 19 / 23;
		position: relative;
	}

	#QwdmCYYrcB9VsoEs {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#ro5hkpCIhXWtLKAk {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.12501302em - var(--ffsd)));
	}

	#nO52FgQ0qwqpzMAV {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.12501302em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#tcIDBuAJ0zGrAvGU {
		min-width: 45.64441783rem;
	}

	#ak4Oe9anQe2Mqjfh {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#DbDreVUMXOPDATC9 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77779948em - var(--ffsd)));
	}

	#IY332BYlxOCtW5Tz {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77779948em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Ypqp8yRB1YpgaSeB {
		min-width: 45.64441783rem;
	}

	#PEd3GzvZJTdwOH13 {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#GYVW5frJXIOIEqLY {
		grid-area: 20 / 8 / 21 / 24;
		grid-template-columns: 0 22.55272878rem 3.30997254rem 0 45.57931366rem 0;
		grid-template-rows: 0 minmax(7.368627rem, max-content) minmax(3.6718724rem, max-content) minmax(0.9230304rem, max-content) minmax(3.22048177rem, max-content) minmax(7.368627rem, max-content);
	}

	#FvOvPrzOppzjtJfX {
		grid-area: 20 / 8 / 21 / 24;
		position: relative;
	}

	#lzsWmCKR22NVpshS {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#S5Q960MpVpDBtBWM {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.12501302em - var(--ffsd)));
	}

	#l88ff6BreOyRZtkh {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.12501302em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#bpRWcRq4en3NAnPE {
		min-width: 45.64441783rem;
	}

	#a2wC9qJJyef20GhG {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#MNMuiPNzwRISHc8i {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77779948em - var(--ffsd)));
	}

	#bvqwQ1cXjbvHUCUd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77779948em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#x1iKD5GVybBnVqmr {
		min-width: 45.64441783rem;
	}

	#UtvVMqVRRA3ZTwkM {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#helZKrihA0xCp3xD {
		grid-area: 22 / 9 / 23 / 25;
		grid-template-columns: 0 22.55272878rem 3.30997254rem 0 45.57931366rem 0;
		grid-template-rows: 0 minmax(7.368627rem, max-content) minmax(3.6718724rem, max-content) minmax(0.9230304rem, max-content) minmax(3.22048177rem, max-content) minmax(7.368627rem, max-content);
	}

	#DW1PWvIrj05MYTzA {
		grid-area: 22 / 9 / 23 / 25;
		position: relative;
	}

	#eaYJnouYhH8AeTXs {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#isxsKvHrpBiedV0f {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.12501302em - var(--ffsd)));
	}

	#UoamxoILsO10EG5q {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.12501302em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#tgdsaEtbxSUDVtjW {
		min-width: 45.64441783rem;
	}

	#lPBGxfXq4O81nS1I {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#K2Xke32ojoiwTvRX {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77779948em - var(--ffsd)));
	}

	#addOTvYUpqkiGMBY {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77779948em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#cn95RVlW3BppVxbB {
		min-width: 45.64441783rem;
	}

	#qt6Fy2LMKhfmHiWO {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#CcYiAMGi67m1j3jt {
		grid-area: 24 / 10 / 25 / 26;
		grid-template-columns: 0 22.55272878rem 3.30997254rem 0 45.57931366rem 0;
		grid-template-rows: 0 minmax(7.368627rem, max-content) minmax(3.6718724rem, max-content) minmax(0.9230304rem, max-content) minmax(3.22048177rem, max-content) minmax(7.368627rem, max-content);
	}

	#bKS3hojd0vWCsG3G {
		grid-area: 24 / 10 / 25 / 26;
		position: relative;
	}

	#LCgfLLbcF7R1C5Ky {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#RRlSofbn2exyjhSo {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.12501302em - var(--ffsd)));
	}

	#pIV69ietZi13B1dT {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.12501302em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#K1yEwqXyFsPf1HK2 {
		min-width: 45.64441783rem;
	}

	#yGXLNKXlE0Lx4hng {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#Z1FtDDBo5h4wMoRV {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77779948em - var(--ffsd)));
	}

	#Y6bPOOQDE0l0RJ3I {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77779948em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#oJGsidyPS9GBmdjA {
		min-width: 45.64441783rem;
	}

	#WCYVRqjPYrdeqHy2 {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#MCREdEQaeq1a3hMP {
		grid-area: 26 / 11 / 27 / 27;
		grid-template-columns: 0 22.55272878rem 3.30997254rem 0 45.57931366rem 0;
		grid-template-rows: 0 minmax(7.368627rem, max-content) minmax(3.6718724rem, max-content) minmax(0.9230304rem, max-content) minmax(3.22048177rem, max-content) minmax(7.368627rem, max-content);
	}

	#QLcfpqVrH3H1mvpy {
		grid-area: 26 / 11 / 27 / 27;
		position: relative;
	}

	#mU73NrSzR8LyStZH {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#u4CN5ZtTl2KDneKG {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.12501302em - var(--ffsd)));
	}

	#WvRywGLPll6BU7wk {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.12501302em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Zzvv5ImtUTITuzQn {
		min-width: 45.64441783rem;
	}

	#xBm3VRHfGheG2x42 {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#LnpNv0pmu6wvkbvS {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77779948em - var(--ffsd)));
	}

	#UMsUl7dcFKO4BRG1 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77779948em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#BX9VZoh3BmRroik7 {
		min-width: 45.64441783rem;
	}

	#FvMjt8b0eWx8TBYx {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#sZHhnQgWODExXYwG {
		grid-area: 28 / 12 / 29 / 28;
		grid-template-columns: 0 22.55272878rem 3.30997254rem 0 45.57931366rem 0;
		grid-template-rows: 0 minmax(7.368627rem, max-content) minmax(3.6718724rem, max-content) minmax(0.9230304rem, max-content) minmax(3.22048177rem, max-content) minmax(7.368627rem, max-content);
	}

	#otiEaCfYPF6XZBOs {
		grid-area: 28 / 12 / 29 / 28;
		position: relative;
	}

	#pzMKaFw2GamEqzIy {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#hNUWSf5vHG23PS3z {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.12501302em - var(--ffsd)));
	}

	#TQtVepH5l0TIWSzI {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.12501302em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#vYc7XNQkg7SIdiVs {
		min-width: 45.64441783rem;
	}

	#cctJXIHRRhmwZh6O {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#aUveYXKfXDgnTBey {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.77779948em - var(--ffsd)));
	}

	#piQVsorWHZwkaZOq {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.77779948em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#YER3aBDStQlIEHTA {
		min-width: 45.64441783rem;
	}

	#ajwEh7aIxhCT1eVz {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#ViYwSgKN4trQebbn {
		grid-area: 30 / 13 / 31 / 29;
		grid-template-columns: 0 22.55272878rem 3.30997254rem 0 45.57931366rem 0;
		grid-template-rows: 0 minmax(7.368627rem, max-content) minmax(3.6718724rem, max-content) minmax(0.9230304rem, max-content) minmax(3.22048177rem, max-content) minmax(7.368627rem, max-content);
	}

	#r5IyhZrlvmrMTAxN {
		grid-area: 30 / 13 / 31 / 29;
		position: relative;
	}

	#n4a0cHUfK1HSTCGF {
		grid-template-columns: 4.16666667rem 10.00072735rem 0.11159849rem 0 0 0 0 0 0 0 0 0 22.44113029rem 0.11159849rem 3.30997254rem 0 0.11159849rem 45.46771517rem 0 0 0 0 0 0 0 0 0 0 0.11159849rem 10.00072735rem 4.16666667rem;
		grid-template-rows: minmax(6.38020833rem, max-content) minmax(6.55983956rem, max-content) minmax(5.36647172rem, max-content) minmax(7.368627rem, max-content) minmax(7.81538456rem, max-content) minmax(7.368627rem, max-content) minmax(4.16666667rem, max-content) minmax(22.55263857rem, max-content) minmax(5.33854167rem, max-content) minmax(7.368627rem, max-content) minmax(3.6718724rem, max-content) minmax(0.9230304rem, max-content) minmax(3.22048177rem, max-content) minmax(7.368627rem, max-content) minmax(4.16666667rem, max-content) minmax(22.55263857rem, max-content) minmax(5.33854167rem, max-content) minmax(22.55263857rem, max-content) minmax(4.16666667rem, max-content) minmax(22.55263857rem, max-content) minmax(5.33854167rem, max-content) minmax(22.55263857rem, max-content) minmax(4.16666667rem, max-content) minmax(22.55263857rem, max-content) minmax(5.33854167rem, max-content) minmax(22.55263857rem, max-content) minmax(4.16666667rem, max-content) minmax(22.55263857rem, max-content) minmax(5.33854167rem, max-content) minmax(22.55263857rem, max-content) minmax(6.38020833rem, max-content);
	}

	#wX2VRa0JC2UPOxM0 {
		min-height: calc(25.30439702 * var(--1vh, 1vh));
	}

	#S8kA0ZtQhjOQOKwj {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#YnBX1w065wi4uN4L {
		grid-template-columns: 0 423.81141765%;
		left: -161.90570883%;
		grid-template-rows: 0 100%;
	}

	#BIyN9FNt9OkkECvl {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.85145241em - var(--ffsd)));
	}

	#RwCegoRE1rKV4jS1 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.85145241em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.01));
		margin-top: calc(var(--first-font-size) * 0.05);
		margin-bottom: calc(var(--last-font-size) * 0.05);
	}

	#KhQaKh5oM3fMkcAn {
		min-width: 91.73177083rem;
	}

	#NFmxBSeb5ywYlty6 {
		grid-area: 2 / 2 / 3 / 9;
		position: relative;
	}

	#kj3ETsDcUZlOhSvk {
		display: block;
	}

	#MsSDKJfvbQdX1ZDD {
		display: none;
	}

	#t9ODRQGA8cKjRez5 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#ZuDkwwAUgk04AJ2w {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#CF1kYwEbzZQkz1dz {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#gZibwNpUF5S5So4n {
		grid-template-columns: 0 1.11264537rem 32.42997816rem 1.11264537rem;
		grid-template-rows: 0 minmax(1.11271023rem, max-content) minmax(32.42984844rem, max-content) minmax(1.11271023rem, max-content);
	}

	#DGdFCTYYFCGRfuLt {
		grid-template-columns: 0 34.65526889rem;
		grid-template-rows: 0 minmax(34.65526889rem, max-content);
	}

	#fxObjboe2MIoW7di {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.64583333em - var(--ffsd)));
	}

	#W9H8dVYPp2W8dmjw {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.64583333em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#gEQRasMlCYlwIzfa {
		min-width: 45.28895476rem;
	}

	#QFPWF4UKRxWTTLdT {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#SeypdGqfELB5RHl9 {
		grid-area: 4 / 3 / 5 / 6;
		grid-template-columns: 0 3.94394934rem 4.14064079rem 34.65526889rem 6.42794092rem 1.65664921rem;
		grid-template-rows: 0 minmax(4.23668895rem, max-content) minmax(34.65526889rem, max-content) minmax(4.12853526rem, max-content) minmax(4.34895833rem, max-content) minmax(16.19152405rem, max-content);
	}

	#XIFNPdWiGGPXVg6A {
		grid-area: 4 / 3 / 5 / 6;
		position: relative;
	}

	#CKMIPKAXamZbG3Go {
		display: block;
	}

	#k86WiYPZcta16CE9 {
		display: none;
	}

	#i7KgHSW2144mi1Li {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#AUVv7WdTmylWb1yh {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#UQoc6JyZzEgKohPS {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#BPky5TaRtu6hUNtl {
		grid-template-columns: 0 1.11264537rem 32.42997816rem 1.11264537rem;
		grid-template-rows: 0 minmax(1.11271023rem, max-content) minmax(32.42984844rem, max-content) minmax(1.11271023rem, max-content);
	}

	#fvh4EVCDc8wFidp6 {
		grid-template-columns: 0 34.65526889rem;
		grid-template-rows: 0 minmax(34.65526889rem, max-content);
	}

	#BTHywGdE1TJza1E2 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.64583333em - var(--ffsd)));
	}

	#lVDcLMcaMgEIaSmp {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.64583333em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#O59aprSV0Sz3FX7f {
		min-width: 45.28895476rem;
	}

	#MJ7fklYwLMVRwUVd {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#SmtITIiN4OkX9KGF {
		grid-area: 6 / 4 / 7 / 7;
		grid-template-columns: 0 2.80029927rem 5.28429086rem 34.65526889rem 5.28429086rem 2.80029927rem;
		grid-template-rows: 0 minmax(4.23668895rem, max-content) minmax(34.65526889rem, max-content) minmax(3.21707693rem, max-content) minmax(9.42708333rem, max-content) minmax(12.02485738rem, max-content);
	}

	#njrbbX2MIZ8ACucA {
		grid-area: 6 / 4 / 7 / 7;
		position: relative;
	}

	#BJ56qU9IrfHKWEXS {
		display: block;
	}

	#fP9uOK7sNZdwTEie {
		display: none;
	}

	#ONnBplxbFRBxwQWD {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#OyJrHIjj9L40dH67 {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#nFBTSV5Qg3f3G0yu {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#DJumzARuTyfZ5TcT {
		grid-template-columns: 0 1.11264537rem 32.42997816rem 1.11264537rem;
		grid-template-rows: 0 minmax(1.11271023rem, max-content) minmax(32.42984844rem, max-content) minmax(1.11271023rem, max-content);
	}

	#QYluzNfhXHF1wj7k {
		grid-template-columns: 0 34.65526889rem;
		grid-template-rows: 0 minmax(34.65526889rem, max-content);
	}

	#Y5R0b5dzA8Q6in1f {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.64583333em - var(--ffsd)));
	}

	#UL33FtBMoc9uKClH {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.64583333em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#xIQ052HO4W1UWtzn {
		min-width: 45.28895476rem;
	}

	#EjvTHHYfq6J4DxNd {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#rhBP5TtNEKB13PR5 {
		grid-area: 8 / 5 / 9 / 8;
		grid-template-columns: 0 2.80029927rem 5.28429086rem 34.65526889rem 5.28429086rem 2.80029927rem;
		grid-template-rows: 0 minmax(4.23668895rem, max-content) minmax(34.65526889rem, max-content) minmax(2.95666026rem, max-content) minmax(14.50520833rem, max-content) minmax(7.20714905rem, max-content);
	}

	#wP5w8HIdiGqngKxo {
		grid-area: 8 / 5 / 9 / 8;
		position: relative;
	}

	#ylGXbSvVUSJkKvSi {
		grid-template-columns: 4.16666667rem 20.42110876rem 0 0 50.82444914rem 0 0 20.42110876rem 4.16666667rem;
		grid-template-rows: minmax(5.16891849rem, max-content) minmax(7.53659765rem, max-content) minmax(6.49453306rem, max-content) minmax(63.56097548rem, max-content) minmax(4.16666667rem, max-content) minmax(63.56097548rem, max-content) minmax(4.16666667rem, max-content) minmax(63.56097548rem, max-content) minmax(5.16891849rem, max-content);
	}

	#trPu9NDNn3GsxH2i {
		min-height: calc(17.64837124 * var(--1vh, 1vh));
	}

	#kHTr4UMRKuBnmI2s {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#Ss1yn1mETT9jLgzw {
		grid-template-columns: 0 184.97916667%;
		left: -42.48958333%;
		grid-template-rows: 0 100%;
	}

	#yOyv0g931HWP8K5w {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(8.69825521em - var(--ffsd)));
	}

	#adtT21cbrs3OWsBi {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(8.69825521em - var(--ffsd)));
	}

	#U2o7tnUjIVGm7rT4 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 8.69825521em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#zVyrooSEco4MLXWd {
		min-width: 91.73177083rem;
	}

	#Ty2xMlXlERNrWhyE {
		grid-area: 2 / 2 / 3 / 39;
		position: relative;
	}

	#oqCLFGq0U0ONLB4j {
		stroke-width: calc(100rem * 4.457911601255087 / 768.0);
	}

	#Z79YFQyvL6A7jGzp {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#Z5ZTkUL0Ig3DZLjq {
		font-size: calc(3.35375617em - var(--ffsd));
	}

	#GdcocGV83UV2GJAx {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#s8AJLcuBl5zakdRa {
		min-width: 10.23110659rem;
	}

	#DI5Uu8GnvsTdMf3D {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#T8d5WFIxemZpAfwI {
		grid-template-columns: 0 0.67720011rem 10.16600242rem 0.67720011rem;
		grid-template-rows: 0 minmax(0.67720011rem, max-content) minmax(5.66985498rem, max-content) minmax(0.67720011rem, max-content);
	}

	#n7J1efHR2vqf1rmi {
		grid-area: 4 / 7 / 15 / 11;
		grid-template-columns: 0 11.52040265rem;
		grid-template-rows: 0 minmax(7.0242552rem, max-content);
	}

	#lBKAzfbKtQiOdvnt {
		grid-area: 4 / 7 / 15 / 11;
		position: relative;
	}

	#fd7DnM6VGWagSWZz {
		stroke-width: calc(100rem * 4.457911601255084 / 768.0);
	}

	#ZkEx1ZIMnCrNc8xV {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#VZFNOjVakBwpupN5 {
		font-size: calc(3.35375617em - var(--ffsd));
	}

	#ZlhIdLtFTKa2t6CN {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#p1jBVyW4SVentMnI {
		min-width: 10.23110659rem;
	}

	#QwE6X2n2GBgM7VkT {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#OGSoeKRLoF8GZsJ0 {
		grid-template-columns: 0 0.67720011rem 10.16600242rem 0.67720011rem;
		grid-template-rows: 0 minmax(0.67720011rem, max-content) minmax(5.66345842rem, max-content) minmax(0.67720011rem, max-content);
	}

	#FdjeAv9k94ie8Hm7 {
		grid-area: 5 / 14 / 14 / 22;
		grid-template-columns: 0 11.52040265rem;
		grid-template-rows: 0 minmax(7.01785865rem, max-content);
	}

	#GtJfgCuMkrhj1Q8V {
		grid-area: 5 / 14 / 14 / 22;
		position: relative;
	}

	#OgCJtlbWkRiQYND9 {
		stroke-width: calc(100rem * 4.457911601255082 / 768.0);
	}

	#yp9yqr9m3tlFfmJE {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#WcJrTy8ZzZLbIWkM {
		font-size: calc(3.35375617em - var(--ffsd));
	}

	#u2WknSR2ixrKnkck {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#kKel268v0eLrEjK5 {
		min-width: 10.23110659rem;
	}

	#VuraA5m92j3CCFSt {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#C98T03WjsWcp9Naq {
		grid-template-columns: 0 0.67720011rem 10.16600242rem 0.67720011rem;
		grid-template-rows: 0 minmax(0.67720011rem, max-content) minmax(5.66345842rem, max-content) minmax(0.67720011rem, max-content);
	}

	#n17msLyZGzQZY6fS {
		grid-area: 6 / 26 / 16 / 33;
		grid-template-columns: 0 11.52040265rem;
		grid-template-rows: 0 minmax(7.01785865rem, max-content);
	}

	#NiwwiVOybqaY1G8L {
		grid-area: 6 / 26 / 16 / 33;
		position: relative;
	}

	#GhJoiNdyc2YK5h9U {
		grid-area: 7 / 20 / 11 / 27;
		position: relative;
	}

	#sl7v8yV5XWSPHbhU {
		grid-area: 8 / 9 / 12 / 15;
		position: relative;
	}

	#Nrsym2i8EB1IfRhY {
		grid-area: 9 / 36 / 31 / 40;
		position: relative;
	}

	#uCXPiiT1S0JJBJ5s {
		grid-area: 10 / 31 / 13 / 37;
		position: relative;
	}

	#lIBf666LxGtI2SbB {
		font-size: calc(3.35375617em - var(--ffsd));
	}

	#aWqmrTrjK6vno2h5 {
		--first-font-size: 3.35375617em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#p58b2GPPiCvdMdNp {
		min-width: 26.71776576rem;
	}

	#uQtFKQYrNz0iB93Z {
		grid-area: 17 / 3 / 22 / 13;
		position: relative;
	}

	#e6wXpRmulXDPT0u0 {
		font-size: calc(3.35375617em - var(--ffsd));
	}

	#fDc8qWFKZszTq4Yj {
		--first-font-size: 3.35375617em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#kzR5Fx3oJ6GLRhRT {
		min-width: 26.71776576rem;
	}

	#johfP1Pxott9vEEM {
		grid-area: 18 / 16 / 20 / 25;
		position: relative;
	}

	#TmT1hYbB6GVMF1ld {
		font-size: calc(3.35375617em - var(--ffsd));
	}

	#ZXPctumbRe0FlOvq {
		--first-font-size: 3.35375617em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#wRVdpMXlteyerwko {
		min-width: 27.98696447rem;
	}

	#QfubwHlgOUkIDLKo {
		grid-area: 19 / 28 / 23 / 35;
		position: relative;
	}

	#mE358ak8TuBfPFmW {
		font-size: calc(2.06384474em - var(--ffsd));
	}

	#FZfpR6RMLnUi7Vdn {
		--first-font-size: 2.06384474em;
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Ai0HO0vCRAKv5G3L {
		min-width: 26.22309698rem;
	}

	#gGj211OtpCfkR6kL {
		grid-area: 21 / 17 / 24 / 24;
		position: relative;
	}

	#uS6qp7fPuFhX50yO {
		stroke-width: calc(100rem * 4.457911601255084 / 768.0);
	}

	#vocqBlV3eDAZhjKe {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#zK3sCCVcD7dmKKm2 {
		font-size: calc(3.35375617em - var(--ffsd));
	}

	#KbIIjrYbW7veptRY {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#mFh0wHMPNvn6BCF5 {
		min-width: 10.23110659rem;
	}

	#QyApyhorWpS58HkO {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#ox3LXDzAhHnOZYgN {
		grid-template-columns: 0 0.67720011rem 10.16600242rem 0.67720011rem;
		grid-template-rows: 0 minmax(0.67720011rem, max-content) minmax(5.66345842rem, max-content) minmax(0.67720011rem, max-content);
	}

	#T82aFK7WnfXqzfpW {
		grid-area: 25 / 8 / 35 / 12;
		grid-template-columns: 0 11.52040265rem;
		grid-template-rows: 0 minmax(7.01785865rem, max-content);
	}

	#OO4yQCeKx191j6uX {
		grid-area: 25 / 8 / 35 / 12;
		position: relative;
	}

	#LqaJvKuc3Dk7x7QU {
		stroke-width: calc(100rem * 4.457911601255082 / 768.0);
	}

	#orI8ZDwTqchi8NYh {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#d8nsYxyPh7lcHoTK {
		font-size: calc(3.35375617em - var(--ffsd));
	}

	#ELugkCrhsZAmxoaj {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#ueRrGfE4wVgueoGt {
		min-width: 10.23110659rem;
	}

	#aXZUTVOOdAdtC3Vw {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#XxiLMbXAvwIanOG5 {
		grid-template-columns: 0 0.67720011rem 10.16600242rem 0.67720011rem;
		grid-template-rows: 0 minmax(0.67720011rem, max-content) minmax(5.66345842rem, max-content) minmax(0.67720011rem, max-content);
	}

	#tS81KE5Rcn2QKSxO {
		grid-area: 26 / 29 / 36 / 34;
		grid-template-columns: 0 11.52040265rem;
		grid-template-rows: 0 minmax(7.01785865rem, max-content);
	}

	#S7qU5KJYk1eVtJA5 {
		grid-area: 26 / 29 / 36 / 34;
		position: relative;
	}

	#KrqNnFZ0UIxhION3 {
		stroke-width: calc(100rem * 4.457911601255084 / 768.0);
	}

	#OsISqtUJstEsYRfG {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#WscMkLBpM8dD8UiW {
		font-size: calc(3.35375617em - var(--ffsd));
	}

	#RKcG0Qp5pw5GyuIV {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#BNLm32gEMenNBav0 {
		min-width: 10.23110659rem;
	}

	#EN1LHn87g78DtoPK {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#nTOFQixz6fgxoAav {
		grid-template-columns: 0 0.67720011rem 10.16600242rem 0.67720011rem;
		grid-template-rows: 0 minmax(0.67720011rem, max-content) minmax(5.66345842rem, max-content) minmax(0.67720011rem, max-content);
	}

	#CtnzpmscK8aNO1rt {
		grid-area: 27 / 18 / 37 / 23;
		grid-template-columns: 0 11.52040265rem;
		grid-template-rows: 0 minmax(7.01785865rem, max-content);
	}

	#mRfiAAgVSN6DXzlS {
		grid-area: 27 / 18 / 37 / 23;
		position: relative;
	}

	#q08wngjnlMogU6O7 {
		grid-area: 28 / 32 / 32 / 38;
		position: relative;
	}

	#meBqLPJvjQ8guX8N {
		grid-area: 29 / 10 / 33 / 19;
		position: relative;
	}

	#yT6e7k3zwC2ZhEZB {
		grid-area: 30 / 21 / 34 / 30;
		position: relative;
	}

	#bjiwT6n0eEvP3AL2 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.51389323em - var(--ffsd)));
	}

	#XgZ35s1n4uX7dHq7 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.51389323em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#LnWGuNxmhGGIADzm {
		min-width: 91.73177083rem;
	}

	#bLIpGnn9dfE3fTZL {
		grid-area: 38 / 4 / 39 / 41;
		position: relative;
	}

	#ZAT3ONIo6ol1EBRK {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.51389323em - var(--ffsd)));
	}

	#KlNpbQ0JZO8toHNj {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.51389323em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#Xn2JAL6I0PxjoTrM {
		min-width: 91.73177083rem;
	}

	#sAebCSIQptDj5wa4 {
		grid-area: 40 / 5 / 41 / 42;
		position: relative;
	}

	#kgS5Q5htFjObvksW {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.51389323em - var(--ffsd)));
	}

	#BWJudQgPUxg1KJdG {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.51389323em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#l1oqJzovwzrxiZCh {
		min-width: 91.73177083rem;
	}

	#MKXkDi6tk3E1t8T7 {
		grid-area: 42 / 6 / 43 / 43;
		position: relative;
	}

	#GhY6Ca0LFKImBmOZ {
		grid-template-columns: 4.16666667rem 0 0 0 0 1.02491337rem 0 0.05046301rem 6.92591059rem 4.54402904rem 0 14.10734557rem 3.68178626rem 0 0.19060341rem 0 0.98186792rem 1.11622369rem 2.10226536rem 2.96936237rem 4.1600799rem 1.17247133rem 13.65572225rem 0.49466877rem 3.67909372rem 0 0.19062121rem 0.98185012rem 0.28734859rem 4.53470356rem 1.26920375rem 4.25667542rem 1.17247133rem 15.41960753rem 2.50389283rem 0.00000503rem 0.09673784rem 0.09674287rem 0 0 0 0 4.16666667rem;
		grid-template-rows: minmax(9.40847458rem, max-content) minmax(17.39651042rem, max-content) minmax(9.25365583rem, max-content) 0 minmax(0.03008962rem, max-content) minmax(2.85001104rem, max-content) minmax(0.00000003rem, max-content) minmax(0.01020908rem, max-content) minmax(0.01141787rem, max-content) minmax(0.17450141rem, max-content) minmax(0.00570284rem, max-content) minmax(0.01456271rem, max-content) minmax(3.92136405rem, max-content) minmax(0.00639655rem, max-content) minmax(0.02369307rem, max-content) minmax(2.29813589rem, max-content) 0 0 minmax(3.9664578rem, max-content) minmax(1.14483429rem, max-content) minmax(2.82162351rem, max-content) 0 minmax(2.37669419rem, max-content) minmax(8.57045958rem, max-content) 0 minmax(0.14441869rem, max-content) minmax(2.78207658rem, max-content) 0 0 minmax(0.09802409rem, max-content) minmax(0.09546166rem, max-content) minmax(0.00128121rem, max-content) 0 minmax(3.89659642rem, max-content) 0 minmax(0.14441869rem, max-content) minmax(2.9725683rem, max-content) minmax(5.33854167rem, max-content) minmax(4.16666667rem, max-content) minmax(5.41667188rem, max-content) minmax(4.16666667rem, max-content) minmax(5.41667188rem, max-content) minmax(9.40847458rem, max-content);
	}

	#gZet15UmlwsvfI1A {
		min-height: calc(8.55878836 * var(--1vh, 1vh));
	}

	#jRCRvtwjSMdf7dIv {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#rUzZEQ49fMRslBaD {
		grid-template-columns: 0 390.39582914%;
		left: -145.19791457%;
		grid-template-rows: 0 100%;
	}

	#pERlgE2a6DOamg6l {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.48618356em - var(--ffsd)));
	}

	#BEYU8ezFNskJdWkQ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.48618356em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#IbJmVidWel9B0IPZ {
		min-width: 91.73177083rem;
	}

	#T4fZl6W85JwWkmDH {
		grid-area: 2 / 2 / 3 / 9;
		position: relative;
	}

	#rYbzezNQVmwN8rwy {
		stroke-width: calc(100rem * 2.0 / 768.0);
	}

	#PDFkidM4k8ElFqJF {
		display: block;
	}

	#MUtTzzmY3wYNkqu9 {
		stroke-width: calc(100rem * 2.0 / 768.0);
	}

	#QtX0JYR7w7rndoZ5 {
		display: none;
	}

	#OTGewPxk07Zd90R6 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#oncK24U5jVqFCrdR {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.74304687em - var(--ffsd)));
	}

	#ZDFzxS6yqsQjtUYd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.74304687em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#zbMFIGzzq8ZFzfcn {
		min-width: 45.63802083rem;
	}

	#zQSnqfE5AUwppsqQ {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#ONx5rCSDHvJt6fyo {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(9.72222656em - var(--ffsd)));
	}

	#hfaM6HCpkz1KTcrg {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 9.72222656em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#LTgRR7rIEa4qPPSg {
		min-width: 45.63802083rem;
	}

	#IdK3IYQ5gJ2E9vGc {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#wue0L8NHiWgYHjue {
		grid-area: 4 / 3 / 5 / 6;
		grid-template-columns: 0 2.27864583rem 0 45.57291667rem 0 2.27864583rem;
		grid-template-rows: 0 minmax(20.80119528rem, max-content) minmax(3.22743229rem, max-content) minmax(5.2675967rem, max-content) minmax(11.58854167rem, max-content) minmax(20.80119528rem, max-content);
	}

	#lvxty16L5kF1dCCb {
		grid-area: 4 / 3 / 5 / 6;
		position: relative;
	}

	#YjTkzhc6SZ0L6Avv {
		stroke-width: calc(100rem * 2.0 / 768.0);
	}

	#AdUikw940oRh1NmJ {
		display: block;
	}

	#vwWQnIHe3A9wyvst {
		stroke-width: calc(100rem * 2.0 / 768.0);
	}

	#kOCJPLER4qSmdRM7 {
		display: none;
	}

	#l88C7xESGFGWgt0W {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#cFsKtSFex865II4H {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.74304687em - var(--ffsd)));
	}

	#HHbP8BOU0jGyE06x {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.74304687em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#a1LUNrAChs6Vb2uV {
		min-width: 45.63802083rem;
	}

	#U6h52VD3Dj4WZdWw {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#eER1JQoWpDTt7q37 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(9.72222656em - var(--ffsd)));
	}

	#UJ961bvHYI3OriqG {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 9.72222656em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#TGSKVP6ICt7lSThL {
		min-width: 45.63802083rem;
	}

	#btkfbwdo0qn6m1Zt {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#RMgCheHCmyErjAQe {
		grid-area: 6 / 4 / 7 / 7;
		grid-template-columns: 0 2.27864583rem 0 45.57291667rem 0 2.27864583rem;
		grid-template-rows: 0 minmax(20.80119528rem, max-content) minmax(3.22743229rem, max-content) minmax(5.2675967rem, max-content) minmax(11.58854167rem, max-content) minmax(20.80119528rem, max-content);
	}

	#Yfrnbcageco4Gcuq {
		grid-area: 6 / 4 / 7 / 7;
		position: relative;
	}

	#DIu6XcaVsIuQQPBK {
		stroke-width: calc(100rem * 2.0 / 768.0);
	}

	#GcQ4LsbvJsRrMHSn {
		display: block;
	}

	#K1TmHAQ3NTsS6CIe {
		stroke-width: calc(100rem * 2.0 / 768.0);
	}

	#Q8N5fGVL069S5m2o {
		display: none;
	}

	#sjN2e2nPj0nSVyq7 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#CBHM7m8mUtjuOrEs {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.74304687em - var(--ffsd)));
	}

	#XXZxi89lGk9b8ejT {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.74304687em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#SLjhKolKiVzXVrsT {
		min-width: 45.63802083rem;
	}

	#hYLhMa3KXE2s1Jbu {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#yNzssimMPjkn4EDf {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(9.72222656em - var(--ffsd)));
	}

	#P1ktvJOBoIXGIm5k {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 9.72222656em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#TNKIZVhxUC29gE96 {
		min-width: 45.63802083rem;
	}

	#UYh7MZfXbNvpGa1x {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#bUFcaRcAwsloJUS1 {
		grid-area: 8 / 5 / 9 / 8;
		grid-template-columns: 0 2.27864583rem 0 45.57291667rem 0 2.27864583rem;
		grid-template-rows: 0 minmax(20.80119528rem, max-content) minmax(3.22743229rem, max-content) minmax(5.2675967rem, max-content) minmax(11.58854167rem, max-content) minmax(20.80119528rem, max-content);
	}

	#ctNvYifBfbOcadsC {
		grid-area: 8 / 5 / 9 / 8;
		position: relative;
	}

	#GUXT0duiXwA8EfRP {
		grid-template-columns: 4.16666667rem 20.76822917rem 0 0 50.13020833rem 0 0 20.76822917rem 4.16666667rem;
		grid-template-rows: minmax(5.6397359rem, max-content) minmax(7.6810018rem, max-content) minmax(7.13879023rem, max-content) minmax(61.68596122rem, max-content) minmax(4.16666667rem, max-content) minmax(61.68596122rem, max-content) minmax(4.16666667rem, max-content) minmax(61.68596122rem, max-content) minmax(5.6397359rem, max-content);
	}

	#aPvYMDHfcpxuVvZR {
		min-height: calc(17.34066989 * var(--1vh, 1vh));
	}

	#iPa1wvEJHh7MXB61 {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#L7Ny0815ayHKHP5q {
		grid-template-columns: 0 208.00796902%;
		left: -54.00398451%;
		grid-template-rows: 0 100%;
	}

	#jGOsWVsi010iQYBh {
		grid-area: 2 / 2 / 3 / 7;
		position: relative;
	}

	#O8BHPgjUtD0pmoqA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.86110677em - var(--ffsd)));
	}

	#d5AggWJARNgm3nDZ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.86110677em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#O64IhSkVydVH3KqG {
		min-width: 68.92946352rem;
	}

	#dQU1i5c0LdjIwoHb {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#GeGJujlNl6iyMkqN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.125em - var(--ffsd)));
	}

	#H1by1XvAIV7Avdbp {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.125em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#B7wY3lpXLCMJPR6r {
		min-width: 25.1405587rem;
	}

	#tiOrR4NtAKmPXQ6Z {
		grid-area: 3 / 2 / 4 / 3;
		position: relative;
	}

	#ObHuCP0zAYEk9Y0Q {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.90972656em - var(--ffsd)));
	}

	#H9F8tLkkfPNO9nNe {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.90972656em - var(--ffsd)));
	}

	#PejqGcvSTOEQWUub {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.90972656em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.25);
		margin-bottom: calc(var(--last-font-size) * -0.25);
	}

	#CcbMq47PBBoURPM4 {
		min-width: 39.53200607rem;
	}

	#y8ZVVD6eAwbW5CAN {
		grid-area: 2 / 4 / 5 / 5;
		position: relative;
	}

	#cqrdRbtvLdMhz6JK {
		grid-area: 6 / 4 / 7 / 6;
		grid-template-columns: 0 25.07545453rem 4.32200292rem 39.4669019rem;
		grid-template-rows: 0 minmax(0.46006836rem, max-content) minmax(3.75rem, max-content) minmax(4.21006836rem, max-content);
	}

	#TSeRaD7xZMnUgP0y {
		grid-area: 6 / 4 / 7 / 6;
		position: relative;
	}

	#YMYaoqrtVtrsfzDu {
		grid-template-columns: 8.64821647rem 6.91960385rem 0 68.86435936rem 0 6.91960385rem 8.64821647rem;
		grid-template-rows: minmax(4.16666667rem, max-content) minmax(82.70356705rem, max-content) minmax(4.16666667rem, max-content) minmax(5.78992969rem, max-content) minmax(7.53374591rem, max-content) minmax(8.42013672rem, max-content) minmax(4.16666667rem, max-content);
	}

	#nuN8I7IXOi48cTmn {
		min-height: calc(9.23933418 * var(--1vh, 1vh));
	}
}

@media (min-width: 768.05px) and (max-width: 1024px) {
	#z2W0t9LO5y0FgQWr {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#SL9cu5l5y0CTwRB4 {
		grid-template-columns: 0 187.45594957%;
		left: -43.72797478%;
		grid-template-rows: 0 100%;
	}

	#iPU6c5lFlFiI4WQx {
		font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), calc(1.04166992em - var(--ffsd)));
	}

	#SQw9hne1KHFmnPeO {
		--first-font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), 1.04166992em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#qGOK3VZjCeecOc2P {
		min-width: 5.53389262rem;
	}

	#NJRAWD1XDTwmEvpu {
		grid-area: 2 / 2 / 6 / 6;
		position: relative;
	}

	#NG5fK23CeU4hquvT {
		font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), calc(1.04166992em - var(--ffsd)));
	}

	#RYk5nRdTfy6ghNrP {
		--first-font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), 1.04166992em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#yXwa2mb3SldnynWe {
		min-width: 7.75206968rem;
	}

	#lGhmYDkXcM7f0GRU {
		grid-area: 3 / 7 / 7 / 10;
		position: relative;
	}

	#X18nBTuSzk3edEEL {
		font-size: calc(1.04166992em - var(--ffsd));
	}

	#u8V3KhdVr5emw0QB {
		--first-font-size: 1.04166992em;
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#h55kBAYDwPDbvnyF {
		min-width: 4.2980153rem;
	}

	#n5vKQHfYbPI0DpT4 {
		grid-area: 4 / 11 / 8 / 18;
		position: relative;
	}

	#uGUwMlcLNrFRmbfk {
		font-size: calc(1.04166992em - var(--ffsd));
	}

	#gUi6RKbWsjEA1j6H {
		--first-font-size: 1.04166992em;
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#TACHjxHiq8ZGGcif {
		min-width: 19.38061515rem;
	}

	#N7rN3NSSYMuShTjx {
		grid-area: 5 / 17 / 9 / 22;
		position: relative;
	}

	#Du8Ium0Vz4SQucN5 {
		grid-area: 10 / 23 / 11 / 27;
		position: relative;
	}

	#mpywhKD1DfgwfA57 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.20772786em - var(--ffsd)));
	}

	#LJWAD7W2Vy2MyEXR {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.20772786em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#pWiO0UiOKN5cFTrY {
		min-width: 93.79882812rem;
	}

	#GK7xmNvRcJq3bFP7 {
		grid-area: 12 / 3 / 13 / 31;
		position: relative;
	}

	#p9KIzIrs63ntBWqD {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375em - var(--ffsd)));
	}

	#cCeEHicukaekaMlL {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#JDp9Rf0T8htahMtZ {
		min-width: 9.18731689rem;
	}

	#i9oe8d3Evx1TMcmk {
		grid-area: 3 / 2 / 4 / 3;
		position: relative;
	}

	#WA8YdHf3se5bPZ2S {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.734375em - var(--ffsd)));
	}

	#jxiPMGfYUmhtOpQ7 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.734375em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#rE7BUE1FKSY2WRFm {
		min-width: 38.40365908rem;
	}

	#lvv5wDelwCqsVXyx {
		grid-area: 2 / 4 / 5 / 5;
		position: relative;
	}

	#QXaeiBUaUbPUHBcN {
		grid-area: 14 / 19 / 15 / 29;
		grid-template-columns: 0 9.13848877rem 0.76917925rem 38.35483095rem;
		grid-template-rows: 0 minmax(0.29296875rem, max-content) minmax(2.734375rem, max-content) minmax(0.234375rem, max-content);
	}

	#TxIwfBhHAvKZFMNQ {
		grid-area: 14 / 19 / 15 / 29;
		position: relative;
	}

	#iY93JfbuXazRIRcr {
		grid-area: 16 / 24 / 17 / 26;
		position: relative;
	}

	#HmV1sKHCsxFDFLKN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.6875em - var(--ffsd)));
	}

	#zDiUMWCVlE67rNVl {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.6875em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.068));
		margin-top: calc(var(--first-font-size) * -0.125);
		margin-bottom: calc(var(--last-font-size) * -0.125);
	}

	#vpFqBZe5ZomsHCMJ {
		min-width: 18.17581376rem;
	}

	#LStX5ZKEr57y950h {
		grid-area: 18 / 4 / 20 / 12;
		position: relative;
	}

	#i8lQhV5BBOWvqgZN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.6875em - var(--ffsd)));
	}

	#tLI0tPlrLmGOu16t {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.6875em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.0405));
		margin-top: calc(var(--first-font-size) * -0.125);
		margin-bottom: calc(var(--last-font-size) * -0.125);
	}

	#G1G7iCYeTi4AzcZC {
		min-width: 15.58062675rem;
	}

	#bIOwFZ0ypWmOBhXD {
		grid-area: 19 / 13 / 21 / 21;
		position: relative;
	}

	#YAmPSU77N2RJl4eA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.82291992em - var(--ffsd)));
	}

	#Oa8LdBBjIm5fsdV8 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.82291992em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#lp7dJl6SjNX87ORs {
		min-width: 18.57757568rem;
	}

	#VdkX9cESQH4i2k40 {
		grid-area: 23 / 5 / 24 / 14;
		position: relative;
	}

	#jNYg8u9bxzq2JDKz {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.60416992em - var(--ffsd)));
	}

	#ls0rVOMmpEllDERc {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.60416992em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#NAlqVZoTiEShReua {
		min-width: 7.3092577rem;
	}

	#BoTYHdTPWrLpcyE5 {
		grid-area: 22 / 15 / 25 / 20;
		position: relative;
	}

	#FC92Yx3X7HcMrmUQ {
		grid-area: 26 / 16 / 30 / 30;
		position: relative;
	}

	#WsUk8J16AAC9Ru2i {
		grid-area: 27 / 8 / 29 / 25;
		position: relative;
	}

	#yvFwVFLgHWnfiHHi {
		grid-area: 28 / 9 / 31 / 28;
		position: relative;
	}

	#io8xHR69844PRI5U {
		grid-template-columns: 3.125rem 0 0 0 5.4850645rem 1.48203533rem 0.87200605rem 5.28323023rem 1.54800528rem 2.28602008rem 1.17062417rem 0 0.40176192rem 0 0.93584813rem 0.05994757rem 1.68100539rem 1.37361915rem 3.21000933rem 7.86960713rem 5.19754602rem 4.73667401rem 0.1911694rem 3.62034098rem 2.56131163rem 0.1911694rem 2.5307492rem 18.15392186rem 15.06922735rem 7.83910588rem 3.125rem;
		grid-template-rows: minmax(2.70028221rem, max-content) 0 0 0 minmax(1.18055924rem, max-content) 0 0 0 minmax(2.83019471rem, max-content) minmax(6.58773097rem, max-content) minmax(3.125rem, max-content) minmax(3.82636507rem, max-content) minmax(0.48164705rem, max-content) minmax(3.26171875rem, max-content) minmax(3.125rem, max-content) minmax(5.89819059rem, max-content) minmax(5.0456967rem, max-content) 0 minmax(5.56640625rem, max-content) 0 minmax(0.74249545rem, max-content) minmax(0.48828125rem, max-content) minmax(2.1484375rem, max-content) minmax(0.48828125rem, max-content) minmax(3.125rem, max-content) minmax(10.68091927rem, max-content) minmax(0.23531994rem, max-content) minmax(39.54708378rem, max-content) minmax(0.23531994rem, max-content) 0 minmax(2.70028221rem, max-content);
	}

	#u3SFqEqUiq3Yd9AS {
		min-height: calc(10.79420416 * var(--1vh, 1vh));
	}

	#fSeIbSOK25LfAyr9 {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#oxBQLPEYTzQnRNow {
		grid-template-columns: 0 135.92571364%;
		left: -17.96285682%;
		grid-template-rows: 0 100%;
	}

	#K4GjhCJlLhRsOHpE {
		grid-area: 2 / 8 / 3 / 10;
		position: relative;
	}

	#LjGKy3aqwD5f0kvO {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.57549805em - var(--ffsd)));
	}

	#Cy3Bx7uQW1irPtaW {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.57549805em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.015));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Xfkrn6HXr1JZUQoh {
		min-width: 30.78134047rem;
	}

	#ldgibYz5xXvUb0Rt {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#zzVPHxti09lpPp1q {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08492188em - var(--ffsd)));
	}

	#S4VRXPXyi8rvnwCL {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08492188em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#LLDeg5iIHwe3XlTN {
		min-width: 30.78134047rem;
	}

	#ckBv54l5HJrE5emB {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#hC7hJ5xZDnCcU29U {
		grid-template-columns: 0 0 30.73251234rem 0;
		grid-template-rows: 0 minmax(3.00052539rem, max-content) minmax(1.14236064rem, max-content) minmax(2.41504687rem, max-content);
	}

	#GZRDoTechanZzenm {
		grid-area: 4 / 5 / 5 / 12;
		position: relative;
	}

	#BAjQbjJ9VkJtrsgg {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.61291402em - var(--ffsd)));
	}

	#YzmjkTygXoFPnsZE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.61291402em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#J55E6XMFl1MtYrvm {
		min-width: 93.79882812rem;
	}

	#QSANUOBl4gQFbcpZ {
		grid-area: 6 / 2 / 7 / 14;
		position: relative;
	}

	#RhjdCAi1nq6z8qTW {
		grid-area: 8 / 4 / 9 / 13;
		position: relative;
	}

	#AKeXGtwqL9JUwDFh {
		grid-area: 10 / 7 / 11 / 9;
		position: relative;
	}

	#YAWsPgICRzktSn4r {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.57549805em - var(--ffsd)));
	}

	#SN3hAOnt3jCuw0R6 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.57549805em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.015));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#wWkeSV5YmODu4QGB {
		min-width: 26.31684276rem;
	}

	#tqIWfLEWsUWNb7Md {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#N70HGv8vxl1N2K22 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08492187em - var(--ffsd)));
	}

	#o4iJlppSTa6Pl1cE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08492187em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#CDqOtP9HmgK4cCL5 {
		min-width: 26.31684276rem;
	}

	#bpR1AVV9gcXSUIxq {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#W7BJ9Ea8WABbO7iA {
		grid-template-columns: 0 0 26.26801463rem 0;
		grid-template-rows: 0 minmax(3.00052539rem, max-content) minmax(1.14236064rem, max-content) minmax(2.41504688rem, max-content);
	}

	#P9GlGLj3YwKgduri {
		grid-area: 12 / 6 / 13 / 11;
		position: relative;
	}

	#v2sELbSjXLn37lbM {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.47622571em - var(--ffsd)));
	}

	#MKClK3FDAeuDU0wa {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.47622571em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#v2QlpyFCJWkdSM91 {
		min-width: 93.79882812rem;
	}

	#x17FaXp1woE5u6dY {
		grid-area: 14 / 3 / 15 / 15;
		position: relative;
	}

	#Zpybw2QrKoj7XLAc {
		grid-template-columns: 3.125rem 0 20.1953125rem 11.31343133rem 0.49035776rem 5.21925096rem 1.7418911rem 14.08762162rem 1.7418911rem 3.47735986rem 3.97413995rem 11.31343133rem 20.1953125rem 0 3.125rem;
		grid-template-rows: minmax(5.00251082rem, max-content) minmax(25.16731336rem, max-content) minmax(2.01736009rem, max-content) minmax(6.55793291rem, max-content) minmax(1.3671875rem, max-content) minmax(12.77424312rem, max-content) minmax(3.43395483rem, max-content) minmax(0.93378906rem, max-content) minmax(5.05608423rem, max-content) minmax(25.16731336rem, max-content) minmax(2.01736009rem, max-content) minmax(6.55793291rem, max-content) minmax(1.3671875rem, max-content) minmax(16.38793585rem, max-content) minmax(5.00251082rem, max-content);
	}

	#xONz65JYPuhP63HY {
		min-height: calc(12.32900822 * var(--1vh, 1vh));
	}

	#WMNyFVvdW8JDT3mq {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#lNDWuvQZOk10eZIb {
		grid-template-columns: 0 216.08981583%;
		left: -58.04490792%;
		grid-template-rows: 0 100%;
	}

	#GWKBOWWbMlMRE34y {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.55729492em - var(--ffsd)));
	}

	#gGhy3uiz4vkiQuRd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.55729492em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#fQuImrbrFCP5809S {
		min-width: 93.79882812rem;
	}

	#KuCcgaAAVItGKIlN {
		grid-area: 2 / 2 / 3 / 31;
		position: relative;
	}

	#w38lT8HHxpTCtpO0 {
		grid-area: 4 / 3 / 7 / 14;
		position: relative;
	}

	#PzoWd5qDgVaLmUDZ {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375977em - var(--ffsd)));
	}

	#QPGnTaypMoAPltwt {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375977em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#hzvp0OYD4RCo7uEv {
		min-width: 34.23331337rem;
	}

	#IrHTNhp1QPNAKOhS {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#B9n7zRcs29V0crDQ {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08334961em - var(--ffsd)));
	}

	#NbIX2BmwIHowCLEB {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08334961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#iBASHVu12sWYgEA5 {
		min-width: 34.23331337rem;
	}

	#rObDKWScOEXbYZWx {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#d2nPwGoAmEq2u0uY {
		grid-area: 5 / 18 / 6 / 30;
		grid-template-columns: 0 0 34.18448525rem 0;
		grid-template-rows: 0 minmax(2.7539043rem, max-content) minmax(0.6922728rem, max-content) minmax(2.41536133rem, max-content);
	}

	#FwOEASN75NL9LIvV {
		grid-area: 5 / 18 / 6 / 30;
		position: relative;
	}

	#ZSRJPzhhA4UvXUXg {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#fumR30kYPtTqjQ7h {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375977em - var(--ffsd)));
	}

	#eJ0nuio3tOE3eLlK {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375977em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#fiDbBM8aiSeoBZTJ {
		min-width: 34.23331337rem;
	}

	#vwLPE9pIxvZev4hm {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#N5r1DfopvjC11lWp {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08334961em - var(--ffsd)));
	}

	#cbrdsrvGgor9AVFQ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08334961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#wRhGvAHLO2UPdJVv {
		min-width: 34.23331337rem;
	}

	#K8i8CthCuAW2RNVt {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#ubW4cp95SqILM3ze {
		grid-area: 8 / 4 / 9 / 19;
		grid-template-columns: 0 16.91454659rem 2.4824794rem 0 34.18448525rem 0;
		grid-template-rows: 0 minmax(5.52647025rem, max-content) minmax(2.7539043rem, max-content) minmax(0.6922728rem, max-content) minmax(2.41536133rem, max-content) minmax(5.52647025rem, max-content);
	}

	#C9SXFJ76ZOq9YNLA {
		grid-area: 8 / 4 / 9 / 19;
		position: relative;
	}

	#sz2N5padSkeqtao1 {
		grid-area: 10 / 5 / 15 / 15;
		position: relative;
	}

	#ouKG2QSRbQ1FNpl5 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375977em - var(--ffsd)));
	}

	#F24kL3rMx9bV6DWO {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375977em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#SAmtaymZzgUuj19C {
		min-width: 34.23331337rem;
	}

	#FMbqVJ1UD4kpThwJ {
		grid-area: 11 / 16 / 12 / 20;
		position: relative;
	}

	#LkfhKA5raUua92yA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08334961em - var(--ffsd)));
	}

	#qTRcZhmLjAMOPuFK {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08334961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#X79zQPuPWaOeSbE0 {
		min-width: 34.23331337rem;
	}

	#EUF1plnsAfhFY5RZ {
		grid-area: 13 / 17 / 14 / 21;
		position: relative;
	}

	#pFXXZ92FZMvZaXz8 {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#CxXylKhIAu0bJefE {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375977em - var(--ffsd)));
	}

	#tkVhyhsakD1AvZFY {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375977em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#PXhSK1K0HwBWdIqu {
		min-width: 34.23331337rem;
	}

	#UVCSVdyuM0GGAXVs {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#l6ITaRwTVeHdFnXq {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08334961em - var(--ffsd)));
	}

	#di4U2TujvKayvhoE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08334961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#JJWbfo5XyQBWA34h {
		min-width: 34.23331337rem;
	}

	#LWy405G9NYzJL9s0 {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#NZGOgBKl8st2sJvf {
		grid-area: 16 / 6 / 17 / 22;
		grid-template-columns: 0 16.91454659rem 2.4824794rem 0 34.18448525rem 0;
		grid-template-rows: 0 minmax(5.52647025rem, max-content) minmax(2.7539043rem, max-content) minmax(0.6922728rem, max-content) minmax(2.41536133rem, max-content) minmax(5.52647025rem, max-content);
	}

	#hg1OQdqVYKdTpgJ6 {
		grid-area: 16 / 6 / 17 / 22;
		position: relative;
	}

	#EwKJj23G47qe68By {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#tCQQkKsjqoO3PcjD {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375977em - var(--ffsd)));
	}

	#wrAhpxnibQN5XUU9 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375977em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#qbKRJZpydbRkKZTi {
		min-width: 34.23331337rem;
	}

	#EQ7LVJMnL1stNRz9 {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#qCVKgoHQmemlevzc {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08334961em - var(--ffsd)));
	}

	#rkHryV8anWx6KcaB {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08334961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#NOstk5h63Mb5TskG {
		min-width: 34.23331337rem;
	}

	#P27AihfcFDm7QolQ {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#SRp2YwMGW6KaXDqO {
		grid-area: 18 / 7 / 19 / 23;
		grid-template-columns: 0 16.91454659rem 2.4824794rem 0 34.18448525rem 0;
		grid-template-rows: 0 minmax(5.52647025rem, max-content) minmax(2.7539043rem, max-content) minmax(0.6922728rem, max-content) minmax(2.41536133rem, max-content) minmax(5.52647025rem, max-content);
	}

	#n3fwGSOdmcnKv76e {
		grid-area: 18 / 7 / 19 / 23;
		position: relative;
	}

	#QwdmCYYrcB9VsoEs {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#ro5hkpCIhXWtLKAk {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375977em - var(--ffsd)));
	}

	#nO52FgQ0qwqpzMAV {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375977em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#tcIDBuAJ0zGrAvGU {
		min-width: 34.23331337rem;
	}

	#ak4Oe9anQe2Mqjfh {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#DbDreVUMXOPDATC9 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08334961em - var(--ffsd)));
	}

	#IY332BYlxOCtW5Tz {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08334961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Ypqp8yRB1YpgaSeB {
		min-width: 34.23331337rem;
	}

	#PEd3GzvZJTdwOH13 {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#GYVW5frJXIOIEqLY {
		grid-area: 20 / 8 / 21 / 24;
		grid-template-columns: 0 16.91454659rem 2.4824794rem 0 34.18448525rem 0;
		grid-template-rows: 0 minmax(5.52647025rem, max-content) minmax(2.7539043rem, max-content) minmax(0.6922728rem, max-content) minmax(2.41536133rem, max-content) minmax(5.52647025rem, max-content);
	}

	#FvOvPrzOppzjtJfX {
		grid-area: 20 / 8 / 21 / 24;
		position: relative;
	}

	#lzsWmCKR22NVpshS {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#S5Q960MpVpDBtBWM {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375977em - var(--ffsd)));
	}

	#l88ff6BreOyRZtkh {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375977em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#bpRWcRq4en3NAnPE {
		min-width: 34.23331337rem;
	}

	#a2wC9qJJyef20GhG {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#MNMuiPNzwRISHc8i {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08334961em - var(--ffsd)));
	}

	#bvqwQ1cXjbvHUCUd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08334961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#x1iKD5GVybBnVqmr {
		min-width: 34.23331337rem;
	}

	#UtvVMqVRRA3ZTwkM {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#helZKrihA0xCp3xD {
		grid-area: 22 / 9 / 23 / 25;
		grid-template-columns: 0 16.91454659rem 2.4824794rem 0 34.18448525rem 0;
		grid-template-rows: 0 minmax(5.52647025rem, max-content) minmax(2.7539043rem, max-content) minmax(0.6922728rem, max-content) minmax(2.41536133rem, max-content) minmax(5.52647025rem, max-content);
	}

	#DW1PWvIrj05MYTzA {
		grid-area: 22 / 9 / 23 / 25;
		position: relative;
	}

	#eaYJnouYhH8AeTXs {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#isxsKvHrpBiedV0f {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375977em - var(--ffsd)));
	}

	#UoamxoILsO10EG5q {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375977em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#tgdsaEtbxSUDVtjW {
		min-width: 34.23331337rem;
	}

	#lPBGxfXq4O81nS1I {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#K2Xke32ojoiwTvRX {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08334961em - var(--ffsd)));
	}

	#addOTvYUpqkiGMBY {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08334961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#cn95RVlW3BppVxbB {
		min-width: 34.23331337rem;
	}

	#qt6Fy2LMKhfmHiWO {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#CcYiAMGi67m1j3jt {
		grid-area: 24 / 10 / 25 / 26;
		grid-template-columns: 0 16.91454659rem 2.4824794rem 0 34.18448525rem 0;
		grid-template-rows: 0 minmax(5.52647025rem, max-content) minmax(2.7539043rem, max-content) minmax(0.6922728rem, max-content) minmax(2.41536133rem, max-content) minmax(5.52647025rem, max-content);
	}

	#bKS3hojd0vWCsG3G {
		grid-area: 24 / 10 / 25 / 26;
		position: relative;
	}

	#LCgfLLbcF7R1C5Ky {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#RRlSofbn2exyjhSo {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375977em - var(--ffsd)));
	}

	#pIV69ietZi13B1dT {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375977em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#K1yEwqXyFsPf1HK2 {
		min-width: 34.23331337rem;
	}

	#yGXLNKXlE0Lx4hng {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#Z1FtDDBo5h4wMoRV {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08334961em - var(--ffsd)));
	}

	#Y6bPOOQDE0l0RJ3I {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08334961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#oJGsidyPS9GBmdjA {
		min-width: 34.23331337rem;
	}

	#WCYVRqjPYrdeqHy2 {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#MCREdEQaeq1a3hMP {
		grid-area: 26 / 11 / 27 / 27;
		grid-template-columns: 0 16.91454659rem 2.4824794rem 0 34.18448525rem 0;
		grid-template-rows: 0 minmax(5.52647025rem, max-content) minmax(2.7539043rem, max-content) minmax(0.6922728rem, max-content) minmax(2.41536133rem, max-content) minmax(5.52647025rem, max-content);
	}

	#QLcfpqVrH3H1mvpy {
		grid-area: 26 / 11 / 27 / 27;
		position: relative;
	}

	#mU73NrSzR8LyStZH {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#u4CN5ZtTl2KDneKG {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375977em - var(--ffsd)));
	}

	#WvRywGLPll6BU7wk {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375977em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Zzvv5ImtUTITuzQn {
		min-width: 34.23331337rem;
	}

	#xBm3VRHfGheG2x42 {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#LnpNv0pmu6wvkbvS {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08334961em - var(--ffsd)));
	}

	#UMsUl7dcFKO4BRG1 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08334961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#BX9VZoh3BmRroik7 {
		min-width: 34.23331337rem;
	}

	#FvMjt8b0eWx8TBYx {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#sZHhnQgWODExXYwG {
		grid-area: 28 / 12 / 29 / 28;
		grid-template-columns: 0 16.91454659rem 2.4824794rem 0 34.18448525rem 0;
		grid-template-rows: 0 minmax(5.52647025rem, max-content) minmax(2.7539043rem, max-content) minmax(0.6922728rem, max-content) minmax(2.41536133rem, max-content) minmax(5.52647025rem, max-content);
	}

	#otiEaCfYPF6XZBOs {
		grid-area: 28 / 12 / 29 / 28;
		position: relative;
	}

	#pzMKaFw2GamEqzIy {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#hNUWSf5vHG23PS3z {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375977em - var(--ffsd)));
	}

	#TQtVepH5l0TIWSzI {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375977em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#vYc7XNQkg7SIdiVs {
		min-width: 34.23331337rem;
	}

	#cctJXIHRRhmwZh6O {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#aUveYXKfXDgnTBey {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.08334961em - var(--ffsd)));
	}

	#piQVsorWHZwkaZOq {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.08334961em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#YER3aBDStQlIEHTA {
		min-width: 34.23331337rem;
	}

	#ajwEh7aIxhCT1eVz {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#ViYwSgKN4trQebbn {
		grid-area: 30 / 13 / 31 / 29;
		grid-template-columns: 0 16.91454659rem 2.4824794rem 0 34.18448525rem 0;
		grid-template-rows: 0 minmax(5.52647025rem, max-content) minmax(2.7539043rem, max-content) minmax(0.6922728rem, max-content) minmax(2.41536133rem, max-content) minmax(5.52647025rem, max-content);
	}

	#r5IyhZrlvmrMTAxN {
		grid-area: 30 / 13 / 31 / 29;
		position: relative;
	}

	#n4a0cHUfK1HSTCGF {
		grid-template-columns: 3.125rem 20.00054551rem 0.08369887rem 0 0 0 0 0 0 0 0 0 16.83084772rem 0.08369887rem 2.4824794rem 0 0.08369887rem 34.10078638rem 0 0 0 0 0 0 0 0 0 0 0.08369887rem 20.00054551rem 3.125rem;
		grid-template-rows: minmax(4.78515625rem, max-content) minmax(5.46875rem, max-content) minmax(4.02485379rem, max-content) minmax(5.52647025rem, max-content) minmax(5.86153842rem, max-content) minmax(5.52647025rem, max-content) minmax(3.125rem, max-content) minmax(16.91447893rem, max-content) minmax(4.00390625rem, max-content) minmax(5.52647025rem, max-content) minmax(2.7539043rem, max-content) minmax(0.6922728rem, max-content) minmax(2.41536133rem, max-content) minmax(5.52647025rem, max-content) minmax(3.125rem, max-content) minmax(16.91447893rem, max-content) minmax(4.00390625rem, max-content) minmax(16.91447893rem, max-content) minmax(3.125rem, max-content) minmax(16.91447893rem, max-content) minmax(4.00390625rem, max-content) minmax(16.91447893rem, max-content) minmax(3.125rem, max-content) minmax(16.91447893rem, max-content) minmax(4.00390625rem, max-content) minmax(16.91447893rem, max-content) minmax(3.125rem, max-content) minmax(16.91447893rem, max-content) minmax(4.00390625rem, max-content) minmax(16.91447893rem, max-content) minmax(4.78515625rem, max-content);
	}

	#wX2VRa0JC2UPOxM0 {
		min-height: calc(24.98452777 * var(--1vh, 1vh));
	}

	#S8kA0ZtQhjOQOKwj {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#YnBX1w065wi4uN4L {
		grid-template-columns: 0 317.85856324%;
		left: -108.92928162%;
		grid-template-rows: 0 100%;
	}

	#BIyN9FNt9OkkECvl {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.1385893em - var(--ffsd)));
	}

	#RwCegoRE1rKV4jS1 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.1385893em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.01));
		margin-top: calc(var(--first-font-size) * 0.05);
		margin-bottom: calc(var(--last-font-size) * 0.05);
	}

	#KhQaKh5oM3fMkcAn {
		min-width: 93.79882812rem;
	}

	#NFmxBSeb5ywYlty6 {
		grid-area: 2 / 2 / 3 / 9;
		position: relative;
	}

	#kj3ETsDcUZlOhSvk {
		display: block;
	}

	#MsSDKJfvbQdX1ZDD {
		display: none;
	}

	#t9ODRQGA8cKjRez5 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#ZuDkwwAUgk04AJ2w {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#CF1kYwEbzZQkz1dz {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#gZibwNpUF5S5So4n {
		grid-template-columns: 0 0.83448402rem 24.32248362rem 0.83448402rem;
		grid-template-rows: 0 minmax(0.83453267rem, max-content) minmax(24.32238633rem, max-content) minmax(0.83453267rem, max-content);
	}

	#DGdFCTYYFCGRfuLt {
		grid-template-columns: 0 25.99145167rem;
		grid-template-rows: 0 minmax(25.99145167rem, max-content);
	}

	#fxObjboe2MIoW7di {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.734375em - var(--ffsd)));
	}

	#W9H8dVYPp2W8dmjw {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.734375em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#gEQRasMlCYlwIzfa {
		min-width: 33.96671607rem;
	}

	#QFPWF4UKRxWTTLdT {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#SeypdGqfELB5RHl9 {
		grid-area: 4 / 3 / 5 / 6;
		grid-template-columns: 0 2.957962rem 3.10548059rem 25.99145167rem 4.82095569rem 1.24248691rem;
		grid-template-rows: 0 minmax(3.17751671rem, max-content) minmax(25.99145167rem, max-content) minmax(3.09640144rem, max-content) minmax(3.26171875rem, max-content) minmax(12.14364303rem, max-content);
	}

	#XIFNPdWiGGPXVg6A {
		grid-area: 4 / 3 / 5 / 6;
		position: relative;
	}

	#CKMIPKAXamZbG3Go {
		display: block;
	}

	#k86WiYPZcta16CE9 {
		display: none;
	}

	#i7KgHSW2144mi1Li {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#AUVv7WdTmylWb1yh {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#UQoc6JyZzEgKohPS {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#BPky5TaRtu6hUNtl {
		grid-template-columns: 0 0.83448402rem 24.32248362rem 0.83448402rem;
		grid-template-rows: 0 minmax(0.83453267rem, max-content) minmax(24.32238633rem, max-content) minmax(0.83453267rem, max-content);
	}

	#fvh4EVCDc8wFidp6 {
		grid-template-columns: 0 25.99145167rem;
		grid-template-rows: 0 minmax(25.99145167rem, max-content);
	}

	#BTHywGdE1TJza1E2 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.734375em - var(--ffsd)));
	}

	#lVDcLMcaMgEIaSmp {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.734375em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#O59aprSV0Sz3FX7f {
		min-width: 33.96671607rem;
	}

	#MJ7fklYwLMVRwUVd {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#SmtITIiN4OkX9KGF {
		grid-area: 6 / 4 / 7 / 7;
		grid-template-columns: 0 2.10022446rem 3.96321814rem 25.99145167rem 3.96321814rem 2.10022446rem;
		grid-template-rows: 0 minmax(3.17751671rem, max-content) minmax(25.99145167rem, max-content) minmax(2.41280769rem, max-content) minmax(7.0703125rem, max-content) minmax(9.01864303rem, max-content);
	}

	#njrbbX2MIZ8ACucA {
		grid-area: 6 / 4 / 7 / 7;
		position: relative;
	}

	#BJ56qU9IrfHKWEXS {
		display: block;
	}

	#fP9uOK7sNZdwTEie {
		display: none;
	}

	#ONnBplxbFRBxwQWD {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#OyJrHIjj9L40dH67 {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#nFBTSV5Qg3f3G0yu {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#DJumzARuTyfZ5TcT {
		grid-template-columns: 0 0.83448402rem 24.32248362rem 0.83448402rem;
		grid-template-rows: 0 minmax(0.83453267rem, max-content) minmax(24.32238633rem, max-content) minmax(0.83453267rem, max-content);
	}

	#QYluzNfhXHF1wj7k {
		grid-template-columns: 0 25.99145167rem;
		grid-template-rows: 0 minmax(25.99145167rem, max-content);
	}

	#Y5R0b5dzA8Q6in1f {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.734375em - var(--ffsd)));
	}

	#UL33FtBMoc9uKClH {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.734375em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#xIQ052HO4W1UWtzn {
		min-width: 33.96671607rem;
	}

	#EjvTHHYfq6J4DxNd {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#rhBP5TtNEKB13PR5 {
		grid-area: 8 / 5 / 9 / 8;
		grid-template-columns: 0 2.10022446rem 3.96321814rem 25.99145167rem 3.96321814rem 2.10022446rem;
		grid-template-rows: 0 minmax(3.17751671rem, max-content) minmax(25.99145167rem, max-content) minmax(2.21749519rem, max-content) minmax(10.87890625rem, max-content) minmax(5.40536178rem, max-content);
	}

	#wP5w8HIdiGqngKxo {
		grid-area: 8 / 5 / 9 / 8;
		position: relative;
	}

	#ylGXbSvVUSJkKvSi {
		grid-template-columns: 3.125rem 27.81583157rem 0 0 38.11833686rem 0 0 27.81583157rem 3.125rem;
		grid-template-rows: minmax(3.87668887rem, max-content) minmax(5.65244823rem, max-content) minmax(4.8708998rem, max-content) minmax(47.67073161rem, max-content) minmax(3.125rem, max-content) minmax(47.67073161rem, max-content) minmax(3.125rem, max-content) minmax(47.67073161rem, max-content) minmax(3.87668887rem, max-content);
	}

	#trPu9NDNn3GsxH2i {
		min-height: calc(17.38555687 * var(--1vh, 1vh));
	}

	#kHTr4UMRKuBnmI2s {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#Ss1yn1mETT9jLgzw {
		grid-template-columns: 0 136.77550107%;
		left: -18.38775053%;
		grid-template-rows: 0 100%;
	}

	#yOyv0g931HWP8K5w {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.52369141em - var(--ffsd)));
	}

	#adtT21cbrs3OWsBi {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(6.52369141em - var(--ffsd)));
	}

	#U2o7tnUjIVGm7rT4 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 6.52369141em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#zVyrooSEco4MLXWd {
		min-width: 93.79882812rem;
	}

	#Ty2xMlXlERNrWhyE {
		grid-area: 2 / 2 / 3 / 43;
		position: relative;
	}

	#oqCLFGq0U0ONLB4j {
		stroke-width: calc(100rem * 6.000000000000002 / 1024.0);
	}

	#Z79YFQyvL6A7jGzp {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#Z5ZTkUL0Ig3DZLjq {
		font-size: calc(3.38541992em - var(--ffsd));
	}

	#GdcocGV83UV2GJAx {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#s8AJLcuBl5zakdRa {
		min-width: 10.31081064rem;
	}

	#DI5Uu8GnvsTdMf3D {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#T8d5WFIxemZpAfwI {
		grid-template-columns: 0 0.68359375rem 10.26198251rem 0.68359375rem;
		grid-template-rows: 0 minmax(0.68359375rem, max-content) minmax(5.72338567rem, max-content) minmax(0.68359375rem, max-content);
	}

	#n7J1efHR2vqf1rmi {
		grid-area: 4 / 5 / 15 / 9;
		grid-template-columns: 0 11.62917001rem;
		grid-template-rows: 0 minmax(7.09057317rem, max-content);
	}

	#lBKAzfbKtQiOdvnt {
		grid-area: 4 / 5 / 15 / 9;
		position: relative;
	}

	#fd7DnM6VGWagSWZz {
		stroke-width: calc(100rem * 5.999999999999991 / 1024.0);
	}

	#ZkEx1ZIMnCrNc8xV {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#VZFNOjVakBwpupN5 {
		font-size: calc(3.38541992em - var(--ffsd));
	}

	#ZlhIdLtFTKa2t6CN {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#p1jBVyW4SVentMnI {
		min-width: 10.31081064rem;
	}

	#QwE6X2n2GBgM7VkT {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#OGSoeKRLoF8GZsJ0 {
		grid-template-columns: 0 0.68359375rem 10.26198251rem 0.68359375rem;
		grid-template-rows: 0 minmax(0.68359375rem, max-content) minmax(5.71692873rem, max-content) minmax(0.68359375rem, max-content);
	}

	#FdjeAv9k94ie8Hm7 {
		grid-area: 5 / 14 / 14 / 22;
		grid-template-columns: 0 11.62917001rem;
		grid-template-rows: 0 minmax(7.08411623rem, max-content);
	}

	#GtJfgCuMkrhj1Q8V {
		grid-area: 5 / 14 / 14 / 22;
		position: relative;
	}

	#OgCJtlbWkRiQYND9 {
		stroke-width: calc(100rem * 6.000000000000002 / 1024.0);
	}

	#yp9yqr9m3tlFfmJE {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#WcJrTy8ZzZLbIWkM {
		font-size: calc(3.38541992em - var(--ffsd));
	}

	#u2WknSR2ixrKnkck {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#kKel268v0eLrEjK5 {
		min-width: 10.31081064rem;
	}

	#VuraA5m92j3CCFSt {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#C98T03WjsWcp9Naq {
		grid-template-columns: 0 0.68359375rem 10.26198251rem 0.68359375rem;
		grid-template-rows: 0 minmax(0.68359375rem, max-content) minmax(5.71692873rem, max-content) minmax(0.68359375rem, max-content);
	}

	#n17msLyZGzQZY6fS {
		grid-area: 6 / 28 / 16 / 35;
		grid-template-columns: 0 11.62917001rem;
		grid-template-rows: 0 minmax(7.08411623rem, max-content);
	}

	#NiwwiVOybqaY1G8L {
		grid-area: 6 / 28 / 16 / 35;
		position: relative;
	}

	#GhJoiNdyc2YK5h9U {
		grid-area: 7 / 20 / 11 / 29;
		position: relative;
	}

	#sl7v8yV5XWSPHbhU {
		grid-area: 8 / 7 / 12 / 15;
		position: relative;
	}

	#Nrsym2i8EB1IfRhY {
		grid-area: 9 / 39 / 31 / 42;
		position: relative;
	}

	#uCXPiiT1S0JJBJ5s {
		grid-area: 10 / 33 / 13 / 40;
		position: relative;
	}

	#lIBf666LxGtI2SbB {
		font-size: calc(3.38541992em - var(--ffsd));
	}

	#aWqmrTrjK6vno2h5 {
		--first-font-size: 3.38541992em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#p58b2GPPiCvdMdNp {
		min-width: 26.953125rem;
	}

	#uQtFKQYrNz0iB93Z {
		grid-area: 17 / 3 / 22 / 11;
		position: relative;
	}

	#e6wXpRmulXDPT0u0 {
		font-size: calc(3.38541992em - var(--ffsd));
	}

	#fDc8qWFKZszTq4Yj {
		--first-font-size: 3.38541992em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#kzR5Fx3oJ6GLRhRT {
		min-width: 26.953125rem;
	}

	#johfP1Pxott9vEEM {
		grid-area: 18 / 16 / 20 / 26;
		position: relative;
	}

	#TmT1hYbB6GVMF1ld {
		font-size: calc(3.38541992em - var(--ffsd));
	}

	#ZXPctumbRe0FlOvq {
		--first-font-size: 3.38541992em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#wRVdpMXlteyerwko {
		min-width: 28.23430658rem;
	}

	#QfubwHlgOUkIDLKo {
		grid-area: 19 / 30 / 23 / 38;
		position: relative;
	}

	#mE358ak8TuBfPFmW {
		font-size: calc(2.08333008em - var(--ffsd));
	}

	#FZfpR6RMLnUi7Vdn {
		--first-font-size: 2.08333008em;
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Ai0HO0vCRAKv5G3L {
		min-width: 26.45378592rem;
	}

	#gGj211OtpCfkR6kL {
		grid-area: 21 / 17 / 24 / 25;
		position: relative;
	}

	#uS6qp7fPuFhX50yO {
		stroke-width: calc(100rem * 5.999999999999998 / 1024.0);
	}

	#vocqBlV3eDAZhjKe {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#zK3sCCVcD7dmKKm2 {
		font-size: calc(3.38541992em - var(--ffsd));
	}

	#KbIIjrYbW7veptRY {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#mFh0wHMPNvn6BCF5 {
		min-width: 10.31081064rem;
	}

	#QyApyhorWpS58HkO {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#ox3LXDzAhHnOZYgN {
		grid-template-columns: 0 0.68359375rem 10.26198251rem 0.68359375rem;
		grid-template-rows: 0 minmax(0.68359375rem, max-content) minmax(5.71692873rem, max-content) minmax(0.68359375rem, max-content);
	}

	#T82aFK7WnfXqzfpW {
		grid-area: 25 / 6 / 35 / 10;
		grid-template-columns: 0 11.62917001rem;
		grid-template-rows: 0 minmax(7.08411623rem, max-content);
	}

	#OO4yQCeKx191j6uX {
		grid-area: 25 / 6 / 35 / 10;
		position: relative;
	}

	#LqaJvKuc3Dk7x7QU {
		stroke-width: calc(100rem * 6.000000000000002 / 1024.0);
	}

	#orI8ZDwTqchi8NYh {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#d8nsYxyPh7lcHoTK {
		font-size: calc(3.38541992em - var(--ffsd));
	}

	#ELugkCrhsZAmxoaj {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#ueRrGfE4wVgueoGt {
		min-width: 10.31081064rem;
	}

	#aXZUTVOOdAdtC3Vw {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#XxiLMbXAvwIanOG5 {
		grid-template-columns: 0 0.68359375rem 10.26198251rem 0.68359375rem;
		grid-template-rows: 0 minmax(0.68359375rem, max-content) minmax(5.71692873rem, max-content) minmax(0.68359375rem, max-content);
	}

	#tS81KE5Rcn2QKSxO {
		grid-area: 26 / 31 / 36 / 36;
		grid-template-columns: 0 11.62917001rem;
		grid-template-rows: 0 minmax(7.08411623rem, max-content);
	}

	#S7qU5KJYk1eVtJA5 {
		grid-area: 26 / 31 / 36 / 36;
		position: relative;
	}

	#KrqNnFZ0UIxhION3 {
		stroke-width: calc(100rem * 5.999999999999991 / 1024.0);
	}

	#OsISqtUJstEsYRfG {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#WscMkLBpM8dD8UiW {
		font-size: calc(3.38541992em - var(--ffsd));
	}

	#RKcG0Qp5pw5GyuIV {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#BNLm32gEMenNBav0 {
		min-width: 10.31081064rem;
	}

	#EN1LHn87g78DtoPK {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#nTOFQixz6fgxoAav {
		grid-template-columns: 0 0.68359375rem 10.26198251rem 0.68359375rem;
		grid-template-rows: 0 minmax(0.68359375rem, max-content) minmax(5.71692873rem, max-content) minmax(0.68359375rem, max-content);
	}

	#CtnzpmscK8aNO1rt {
		grid-area: 27 / 18 / 37 / 23;
		grid-template-columns: 0 11.62917001rem;
		grid-template-rows: 0 minmax(7.08411623rem, max-content);
	}

	#mRfiAAgVSN6DXzlS {
		grid-area: 27 / 18 / 37 / 23;
		position: relative;
	}

	#q08wngjnlMogU6O7 {
		grid-area: 28 / 34 / 32 / 41;
		position: relative;
	}

	#meBqLPJvjQ8guX8N {
		grid-area: 29 / 8 / 33 / 19;
		position: relative;
	}

	#yT6e7k3zwC2ZhEZB {
		grid-area: 30 / 21 / 34 / 32;
		position: relative;
	}

	#bjiwT6n0eEvP3AL2 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.38541992em - var(--ffsd)));
	}

	#XgZ35s1n4uX7dHq7 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.38541992em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#LnWGuNxmhGGIADzm {
		min-width: 26.953125rem;
	}

	#bLIpGnn9dfE3fTZL {
		grid-area: 38 / 4 / 41 / 12;
		position: relative;
	}

	#ZAT3ONIo6ol1EBRK {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.38541992em - var(--ffsd)));
	}

	#KlNpbQ0JZO8toHNj {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.38541992em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#Xn2JAL6I0PxjoTrM {
		min-width: 26.953125rem;
	}

	#sAebCSIQptDj5wa4 {
		grid-area: 39 / 13 / 42 / 24;
		position: relative;
	}

	#kgS5Q5htFjObvksW {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.38541992em - var(--ffsd)));
	}

	#BWJudQgPUxg1KJdG {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.38541992em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#l1oqJzovwzrxiZCh {
		min-width: 26.953125rem;
	}

	#MKXkDi6tk3E1t8T7 {
		grid-area: 40 / 27 / 43 / 37;
		position: relative;
	}

	#GhY6Ca0LFKImBmOZ {
		grid-template-columns: 3.125rem 0.60894126rem 0 1.03458987rem 0 0.05093945rem 6.99130007rem 4.5869305rem 0 14.240537rem 0 3.06953129rem 0.64701575rem 0 0.19240295rem 0 0.99113801rem 1.12676227rem 2.12211344rem 2.99739695rem 4.19935638rem 1.18354097rem 13.44457015rem 0.34007962rem 0.49933908rem 2.96930074rem 0.74452834rem 0 0.19242092rem 0.99112005rem 0.29006153rem 4.57751697rem 1.28118666rem 4.29686389rem 1.18354097rem 13.34705756rem 2.21813083rem 2.52753279rem 0.00000508rem 0.09765117rem 0.09765625rem 0.60894126rem 3.125rem;
		grid-template-rows: minmax(7.07768802rem, max-content) minmax(13.04738281rem, max-content) minmax(6.94024187rem, max-content) 0 minmax(0.0303737rem, max-content) minmax(2.8769188rem, max-content) minmax(0.00000003rem, max-content) minmax(0.01030547rem, max-content) minmax(0.01152567rem, max-content) minmax(0.17614892rem, max-content) minmax(0.00575668rem, max-content) minmax(0.0147002rem, max-content) minmax(3.95838676rem, max-content) minmax(0.00645694rem, max-content) minmax(0.02391676rem, max-content) minmax(2.31983324rem, max-content) 0 0 minmax(4.00390625rem, max-content) minmax(1.155643rem, max-content) minmax(2.84826325rem, max-content) 0 minmax(2.39913323rem, max-content) minmax(8.6513757rem, max-content) 0 minmax(0.14578219rem, max-content) minmax(2.80834294rem, max-content) 0 0 minmax(0.09894956rem, max-content) minmax(0.09636294rem, max-content) minmax(0.00129331rem, max-content) 0 minmax(3.93338529rem, max-content) 0 minmax(0.14578219rem, max-content) minmax(2.22942622rem, max-content) 0 0 minmax(4.00390625rem, max-content) minmax(4.00390625rem, max-content) 0 minmax(7.07768802rem, max-content);
	}

	#gZet15UmlwsvfI1A {
		min-height: calc(8.31228633 * var(--1vh, 1vh));
	}

	#jRCRvtwjSMdf7dIv {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#rUzZEQ49fMRslBaD {
		grid-template-columns: 0 292.9722884%;
		left: -96.4861442%;
		grid-template-rows: 0 100%;
	}

	#pERlgE2a6DOamg6l {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.94791992em - var(--ffsd)));
	}

	#BEYU8ezFNskJdWkQ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.94791992em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#IbJmVidWel9B0IPZ {
		min-width: 93.79882812rem;
	}

	#T4fZl6W85JwWkmDH {
		grid-area: 2 / 2 / 3 / 9;
		position: relative;
	}

	#rYbzezNQVmwN8rwy {
		stroke-width: calc(100rem * 2.0000000000000004 / 1024.0);
	}

	#PDFkidM4k8ElFqJF {
		display: block;
	}

	#MUtTzzmY3wYNkqu9 {
		stroke-width: calc(100rem * 2.0000000000000004 / 1024.0);
	}

	#QtX0JYR7w7rndoZ5 {
		display: none;
	}

	#OTGewPxk07Zd90R6 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#oncK24U5jVqFCrdR {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.05728516em - var(--ffsd)));
	}

	#ZDFzxS6yqsQjtUYd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.05728516em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#zbMFIGzzq8ZFzfcn {
		min-width: 34.22851562rem;
	}

	#zQSnqfE5AUwppsqQ {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#ONx5rCSDHvJt6fyo {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.29166992em - var(--ffsd)));
	}

	#hfaM6HCpkz1KTcrg {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.29166992em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#LTgRR7rIEa4qPPSg {
		min-width: 34.22851562rem;
	}

	#IdK3IYQ5gJ2E9vGc {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#wue0L8NHiWgYHjue {
		grid-area: 4 / 3 / 5 / 6;
		grid-template-columns: 0 1.70898438rem 0 34.1796875rem 0 1.70898438rem;
		grid-template-rows: 0 minmax(15.60089646rem, max-content) minmax(2.42057422rem, max-content) minmax(3.95069752rem, max-content) minmax(8.69140625rem, max-content) minmax(15.60089646rem, max-content);
	}

	#lvxty16L5kF1dCCb {
		grid-area: 4 / 3 / 5 / 6;
		position: relative;
	}

	#YjTkzhc6SZ0L6Avv {
		stroke-width: calc(100rem * 2.0000000000000004 / 1024.0);
	}

	#AdUikw940oRh1NmJ {
		display: block;
	}

	#vwWQnIHe3A9wyvst {
		stroke-width: calc(100rem * 2.0000000000000004 / 1024.0);
	}

	#kOCJPLER4qSmdRM7 {
		display: none;
	}

	#l88C7xESGFGWgt0W {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#cFsKtSFex865II4H {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.05728516em - var(--ffsd)));
	}

	#HHbP8BOU0jGyE06x {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.05728516em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#a1LUNrAChs6Vb2uV {
		min-width: 34.22851562rem;
	}

	#U6h52VD3Dj4WZdWw {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#eER1JQoWpDTt7q37 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.29166992em - var(--ffsd)));
	}

	#UJ961bvHYI3OriqG {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.29166992em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#TGSKVP6ICt7lSThL {
		min-width: 34.22851562rem;
	}

	#btkfbwdo0qn6m1Zt {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#RMgCheHCmyErjAQe {
		grid-area: 6 / 4 / 7 / 7;
		grid-template-columns: 0 1.70898438rem 0 34.1796875rem 0 1.70898438rem;
		grid-template-rows: 0 minmax(15.60089646rem, max-content) minmax(2.42057422rem, max-content) minmax(3.95069752rem, max-content) minmax(8.69140625rem, max-content) minmax(15.60089646rem, max-content);
	}

	#Yfrnbcageco4Gcuq {
		grid-area: 6 / 4 / 7 / 7;
		position: relative;
	}

	#DIu6XcaVsIuQQPBK {
		stroke-width: calc(100rem * 2.0000000000000004 / 1024.0);
	}

	#GcQ4LsbvJsRrMHSn {
		display: block;
	}

	#K1TmHAQ3NTsS6CIe {
		stroke-width: calc(100rem * 2.0000000000000004 / 1024.0);
	}

	#Q8N5fGVL069S5m2o {
		display: none;
	}

	#sjN2e2nPj0nSVyq7 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#CBHM7m8mUtjuOrEs {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.05728516em - var(--ffsd)));
	}

	#XXZxi89lGk9b8ejT {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.05728516em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#SLjhKolKiVzXVrsT {
		min-width: 34.22851562rem;
	}

	#hYLhMa3KXE2s1Jbu {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#yNzssimMPjkn4EDf {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(7.29166992em - var(--ffsd)));
	}

	#P1ktvJOBoIXGIm5k {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 7.29166992em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#TNKIZVhxUC29gE96 {
		min-width: 34.22851562rem;
	}

	#UYh7MZfXbNvpGa1x {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#bUFcaRcAwsloJUS1 {
		grid-area: 8 / 5 / 9 / 8;
		grid-template-columns: 0 1.70898438rem 0 34.1796875rem 0 1.70898438rem;
		grid-template-rows: 0 minmax(15.60089646rem, max-content) minmax(2.42057422rem, max-content) minmax(3.95069752rem, max-content) minmax(8.69140625rem, max-content) minmax(15.60089646rem, max-content);
	}

	#ctNvYifBfbOcadsC {
		grid-area: 8 / 5 / 9 / 8;
		position: relative;
	}

	#GUXT0duiXwA8EfRP {
		grid-template-columns: 3.125rem 28.07617188rem 0 0 37.59765625rem 0 0 28.07617188rem 3.125rem;
		grid-template-rows: minmax(4.22980192rem, max-content) minmax(5.859375rem, max-content) minmax(5.35409267rem, max-content) minmax(46.26447091rem, max-content) minmax(3.125rem, max-content) minmax(46.26447091rem, max-content) minmax(3.125rem, max-content) minmax(46.26447091rem, max-content) minmax(4.22980192rem, max-content);
	}

	#aPvYMDHfcpxuVvZR {
		min-height: calc(17.09267193 * var(--1vh, 1vh));
	}

	#iPa1wvEJHh7MXB61 {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#L7Ny0815ayHKHP5q {
		grid-template-columns: 0 156.00597677%;
		left: -28.00298838%;
		grid-template-rows: 0 100%;
	}

	#jGOsWVsi010iQYBh {
		grid-area: 2 / 2 / 3 / 7;
		position: relative;
	}

	#O8BHPgjUtD0pmoqA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.64583008em - var(--ffsd)));
	}

	#d5AggWJARNgm3nDZ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.64583008em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#O64IhSkVydVH3KqG {
		min-width: 51.69709764rem;
	}

	#dQU1i5c0LdjIwoHb {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#GeGJujlNl6iyMkqN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.34375em - var(--ffsd)));
	}

	#H1by1XvAIV7Avdbp {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.34375em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#B7wY3lpXLCMJPR6r {
		min-width: 18.85541902rem;
	}

	#tiOrR4NtAKmPXQ6Z {
		grid-area: 3 / 2 / 4 / 3;
		position: relative;
	}

	#ObHuCP0zAYEk9Y0Q {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.43229492em - var(--ffsd)));
	}

	#H9F8tLkkfPNO9nNe {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.43229492em - var(--ffsd)));
	}

	#PejqGcvSTOEQWUub {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.43229492em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.25);
		margin-bottom: calc(var(--last-font-size) * -0.25);
	}

	#CcbMq47PBBoURPM4 {
		min-width: 29.64900455rem;
	}

	#y8ZVVD6eAwbW5CAN {
		grid-area: 2 / 4 / 5 / 5;
		position: relative;
	}

	#cqrdRbtvLdMhz6JK {
		grid-area: 6 / 4 / 7 / 6;
		grid-template-columns: 0 18.8065909rem 3.24150219rem 29.60017643rem;
		grid-template-rows: 0 minmax(0.34505127rem, max-content) minmax(2.8125rem, max-content) minmax(3.15755127rem, max-content);
	}

	#TSeRaD7xZMnUgP0y {
		grid-area: 6 / 4 / 7 / 6;
		position: relative;
	}

	#YMYaoqrtVtrsfzDu {
		grid-template-columns: 18.98616236rem 5.18970289rem 0 51.64826952rem 0 5.18970289rem 18.98616236rem;
		grid-template-rows: minmax(3.125rem, max-content) minmax(62.02767529rem, max-content) minmax(3.125rem, max-content) minmax(4.34244727rem, max-content) minmax(5.65030943rem, max-content) minmax(6.31510254rem, max-content) minmax(3.125rem, max-content);
	}

	#nuN8I7IXOi48cTmn {
		min-height: calc(9.10174472 * var(--1vh, 1vh));
	}
}

@media (min-width: 1024.05px) {
	#z2W0t9LO5y0FgQWr {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#SL9cu5l5y0CTwRB4 {
		grid-template-columns: 0 100%;
		grid-template-rows: 0 100%;
	}

	#iPU6c5lFlFiI4WQx {
		font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), calc(0.78087116em - var(--ffsd)));
	}

	#SQw9hne1KHFmnPeO {
		--first-font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), 0.78087116em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#qGOK3VZjCeecOc2P {
		min-width: 4.14839389rem;
	}

	#NJRAWD1XDTwmEvpu {
		grid-area: 2 / 3 / 6 / 6;
		position: relative;
	}

	#NG5fK23CeU4hquvT {
		font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), calc(0.78087116em - var(--ffsd)));
	}

	#RYk5nRdTfy6ghNrP {
		--first-font-size: max(calc(10.6667px * var(--rfso, var(--bfso, 1))), 0.78087116em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#yXwa2mb3SldnynWe {
		min-width: 5.81121475rem;
	}

	#lGhmYDkXcM7f0GRU {
		grid-area: 3 / 8 / 7 / 10;
		position: relative;
	}

	#X18nBTuSzk3edEEL {
		font-size: calc(0.78087116em - var(--ffsd));
	}

	#u8V3KhdVr5emw0QB {
		--first-font-size: 0.78087116em;
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#h55kBAYDwPDbvnyF {
		min-width: 3.22193826rem;
	}

	#n5vKQHfYbPI0DpT4 {
		grid-area: 4 / 11 / 8 / 15;
		position: relative;
	}

	#uGUwMlcLNrFRmbfk {
		font-size: calc(0.78087116em - var(--ffsd));
	}

	#gUi6RKbWsjEA1j6H {
		--first-font-size: 0.78087116em;
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.042));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#TACHjxHiq8ZGGcif {
		min-width: 14.52836744rem;
	}

	#N7rN3NSSYMuShTjx {
		grid-area: 5 / 12 / 9 / 20;
		position: relative;
	}

	#Du8Ium0Vz4SQucN5 {
		grid-area: 10 / 2 / 16 / 7;
		position: relative;
	}

	#mpywhKD1DfgwfA57 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.73303807em - var(--ffsd)));
	}

	#LJWAD7W2Vy2MyEXR {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.73303807em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#pWiO0UiOKN5cFTrY {
		min-width: 79.91317954rem;
	}

	#GK7xmNvRcJq3bFP7 {
		grid-area: 12 / 9 / 13 / 28;
		position: relative;
	}

	#p9KIzIrs63ntBWqD {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75695461em - var(--ffsd)));
	}

	#cCeEHicukaekaMlL {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75695461em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#JDp9Rf0T8htahMtZ {
		min-width: 6.88712482rem;
	}

	#i9oe8d3Evx1TMcmk {
		grid-area: 3 / 2 / 4 / 3;
		position: relative;
	}

	#WA8YdHf3se5bPZ2S {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.04978038em - var(--ffsd)));
	}

	#jxiPMGfYUmhtOpQ7 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.04978038em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#rE7BUE1FKSY2WRFm {
		min-width: 28.78868733rem;
	}

	#lvv5wDelwCqsVXyx {
		grid-area: 2 / 4 / 5 / 5;
		position: relative;
	}

	#QXaeiBUaUbPUHBcN {
		grid-area: 14 / 21 / 17 / 26;
		grid-template-columns: 0 6.8505216rem 0.57660289rem 28.75208411rem;
		grid-template-rows: 0 minmax(0.21961933rem, max-content) minmax(2.04978038rem, max-content) minmax(0.17569546rem, max-content);
	}

	#TxIwfBhHAvKZFMNQ {
		grid-area: 14 / 21 / 17 / 26;
		position: relative;
	}

	#iY93JfbuXazRIRcr {
		grid-area: 11 / 29 / 15 / 31;
		position: relative;
	}

	#HmV1sKHCsxFDFLKN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.51390922em - var(--ffsd)));
	}

	#zDiUMWCVlE67rNVl {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.51390922em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.068));
		margin-top: calc(var(--first-font-size) * -0.125);
		margin-bottom: calc(var(--last-font-size) * -0.125);
	}

	#vpFqBZe5ZomsHCMJ {
		min-width: 13.62520739rem;
	}

	#LStX5ZKEr57y950h {
		grid-area: 21 / 4 / 23 / 13;
		position: relative;
	}

	#i8lQhV5BBOWvqgZN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.51390922em - var(--ffsd)));
	}

	#tLI0tPlrLmGOu16t {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.51390922em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.0405));
		margin-top: calc(var(--first-font-size) * -0.125);
		margin-bottom: calc(var(--last-font-size) * -0.125);
	}

	#G1G7iCYeTi4AzcZC {
		min-width: 11.67976705rem;
	}

	#bIOwFZ0ypWmOBhXD {
		grid-area: 22 / 14 / 24 / 19;
		position: relative;
	}

	#YAmPSU77N2RJl4eA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.36652269em - var(--ffsd)));
	}

	#Oa8LdBBjIm5fsdV8 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.36652269em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#lp7dJl6SjNX87ORs {
		min-width: 13.92638177rem;
	}

	#VdkX9cESQH4i2k40 {
		grid-area: 26 / 5 / 27 / 16;
		position: relative;
	}

	#jNYg8u9bxzq2JDKz {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.95217423em - var(--ffsd)));
	}

	#ls0rVOMmpEllDERc {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.95217423em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#NAlqVZoTiEShReua {
		min-width: 5.47926785rem;
	}

	#BoTYHdTPWrLpcyE5 {
		grid-area: 25 / 17 / 28 / 18;
		position: relative;
	}

	#FC92Yx3X7HcMrmUQ {
		grid-area: 18 / 24 / 30 / 30;
		position: relative;
	}

	#WsUk8J16AAC9Ru2i {
		grid-area: 19 / 22 / 29 / 25;
		position: relative;
	}

	#yvFwVFLgHWnfiHHi {
		grid-area: 20 / 23 / 31 / 27;
		position: relative;
	}

	#io8xHR69844PRI5U {
		grid-template-columns: 2.15228792rem 0.35660412rem 2.10369134rem 0.96104733rem 1.04705199rem 0.45219614rem 0.65878789rem 2.48815078rem 3.28646075rem 1.71367831rem 1.9251963rem 1.05603468rem 0 0.20410406rem 1.05811765rem 0 5.44266463rem 4.93827749rem 1.7925657rem 0.27347862rem 5.46012436rem 3.96048884rem 4.75437321rem 20.94471233rem 1.05950985rem 2.90097899rem 19.10581053rem 3.11734148rem 2.68204916rem 1.95192762rem 2.15228792rem;
		grid-template-rows: minmax(1.70584038rem, max-content) 0 0 0 minmax(0.88498731rem, max-content) 0 0 0 minmax(2.12161009rem, max-content) minmax(0.25845145rem, max-content) minmax(0.58067832rem, max-content) minmax(3.26012738rem, max-content) minmax(0.36105899rem, max-content) minmax(0.21961933rem, max-content) minmax(0.25845145rem, max-content) minmax(1.96702439rem, max-content) minmax(3.78242564rem, max-content) minmax(8.00677989rem, max-content) minmax(0.17640382rem, max-content) minmax(0.62579657rem, max-content) 0 minmax(4.1727672rem, max-content) 0 minmax(0.55659981rem, max-content) minmax(0.36603221rem, max-content) minmax(1.61054173rem, max-content) minmax(0.36603221rem, max-content) minmax(21.9480676rem, max-content) minmax(0.17640382rem, max-content) 0 minmax(2.08478358rem, max-content);
	}

	#u3SFqEqUiq3Yd9AS {
		min-height: calc(11.60796325 * var(--1vh, 1vh));
	}

	#fSeIbSOK25LfAyr9 {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#oxBQLPEYTzQnRNow {
		grid-template-columns: 0 100%;
		grid-template-rows: 0 100%;
	}

	#K4GjhCJlLhRsOHpE {
		grid-area: 2 / 7 / 3 / 9;
		position: relative;
	}

	#LjGKy3aqwD5f0kvO {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.93068082em - var(--ffsd)));
	}

	#Cy3Bx7uQW1irPtaW {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.93068082em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.015));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Xfkrn6HXr1JZUQoh {
		min-width: 23.07473839rem;
	}

	#ldgibYz5xXvUb0Rt {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#zzVPHxti09lpPp1q {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56292826em - var(--ffsd)));
	}

	#S4VRXPXyi8rvnwCL {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56292826em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#LLDeg5iIHwe3XlTN {
		min-width: 23.07473839rem;
	}

	#ckBv54l5HJrE5emB {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#hC7hJ5xZDnCcU29U {
		grid-template-columns: 0 0 23.03813517rem 0;
		grid-template-rows: 0 minmax(2.24929575rem, max-content) minmax(0.85635234rem, max-content) minmax(1.81040117rem, max-content);
	}

	#GZRDoTechanZzenm {
		grid-area: 4 / 5 / 5 / 12;
		position: relative;
	}

	#BAjQbjJ9VkJtrsgg {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.73303807em - var(--ffsd)));
	}

	#YzmjkTygXoFPnsZE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.73303807em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#J55E6XMFl1MtYrvm {
		min-width: 70.95506889rem;
	}

	#QSANUOBl4gQFbcpZ {
		grid-area: 6 / 2 / 7 / 14;
		position: relative;
	}

	#RhjdCAi1nq6z8qTW {
		grid-area: 8 / 4 / 9 / 13;
		position: relative;
	}

	#AKeXGtwqL9JUwDFh {
		grid-area: 10 / 8 / 11 / 10;
		position: relative;
	}

	#YAWsPgICRzktSn4r {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.93068082em - var(--ffsd)));
	}

	#SN3hAOnt3jCuw0R6 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.93068082em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.015));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#wWkeSV5YmODu4QGB {
		min-width: 19.72799926rem;
	}

	#tqIWfLEWsUWNb7Md {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#N70HGv8vxl1N2K22 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56292826em - var(--ffsd)));
	}

	#o4iJlppSTa6Pl1cE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56292826em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#CDqOtP9HmgK4cCL5 {
		min-width: 19.72799926rem;
	}

	#bpR1AVV9gcXSUIxq {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#W7BJ9Ea8WABbO7iA {
		grid-template-columns: 0 0 19.69139604rem 0;
		grid-template-rows: 0 minmax(2.24929575rem, max-content) minmax(0.85635234rem, max-content) minmax(1.81040117rem, max-content);
	}

	#P9GlGLj3YwKgduri {
		grid-area: 12 / 6 / 13 / 11;
		position: relative;
	}

	#v2sELbSjXLn37lbM {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.73303807em - var(--ffsd)));
	}

	#MKClK3FDAeuDU0wa {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.73303807em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.02));
		margin-top: 0;
		margin-bottom: 0;
	}

	#v2QlpyFCJWkdSM91 {
		min-width: 73.74364645rem;
	}

	#x17FaXp1woE5u6dY {
		grid-area: 14 / 3 / 15 / 15;
		position: relative;
	}

	#Zpybw2QrKoj7XLAc {
		grid-template-columns: 14.54076716rem 0 15.45923284rem 8.48093242rem 1.67336957rem 3.9125278rem 0 11.86634043rem 0 3.9125278rem 1.67336957rem 8.48093242rem 15.45923284rem 2.78857756rem 11.75218961rem;
		grid-template-rows: minmax(2.96308278rem, max-content) minmax(18.86627298rem, max-content) minmax(1.51228165rem, max-content) minmax(4.91604927rem, max-content) minmax(1.02489019rem, max-content) minmax(9.66325037rem, max-content) minmax(2.57420919rem, max-content) minmax(0.7rem, max-content) minmax(3.79021248rem, max-content) minmax(18.86627298rem, max-content) minmax(1.51228165rem, max-content) minmax(4.91604927rem, max-content) minmax(1.02489019rem, max-content) minmax(12.88433382rem, max-content) minmax(2.19441514rem, max-content);
	}

	#xONz65JYPuhP63HY {
		min-height: calc(18.2848392 * var(--1vh, 1vh));
	}

	#WMNyFVvdW8JDT3mq {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#lNDWuvQZOk10eZIb {
		grid-template-columns: 0 100%;
		grid-template-rows: 0 100%;
	}

	#GWKBOWWbMlMRE34y {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.41630307em - var(--ffsd)));
	}

	#gGhy3uiz4vkiQuRd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.41630307em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#fQuImrbrFCP5809S {
		min-width: 57.32353324rem;
	}

	#KuCcgaAAVItGKIlN {
		grid-area: 2 / 2 / 3 / 26;
		position: relative;
	}

	#w38lT8HHxpTCtpO0 {
		grid-area: 4 / 3 / 8 / 9;
		position: relative;
	}

	#PzoWd5qDgVaLmUDZ {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75696193em - var(--ffsd)));
	}

	#QPGnTaypMoAPltwt {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75696193em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#hzvp0OYD4RCo7uEv {
		min-width: 25.66245453rem;
	}

	#IrHTNhp1QPNAKOhS {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#B9n7zRcs29V0crDQ {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56174963em - var(--ffsd)));
	}

	#NbIX2BmwIHowCLEB {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56174963em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#iBASHVu12sWYgEA5 {
		min-width: 25.66245453rem;
	}

	#rObDKWScOEXbYZWx {
		grid-area: 4 / 3 / 5 / 5;
		position: relative;
	}

	#d2nPwGoAmEq2u0uY {
		grid-area: 6 / 13 / 7 / 19;
		grid-template-columns: 0 0 25.62585131rem 0;
		grid-template-rows: 0 minmax(2.0644202rem, max-content) minmax(0.5189512rem, max-content) minmax(1.8106369rem, max-content);
	}

	#FwOEASN75NL9LIvV {
		grid-area: 6 / 13 / 7 / 19;
		position: relative;
	}

	#ZSRJPzhhA4UvXUXg {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#fumR30kYPtTqjQ7h {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75696193em - var(--ffsd)));
	}

	#eJ0nuio3tOE3eLlK {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75696193em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#fiDbBM8aiSeoBZTJ {
		min-width: 25.66245453rem;
	}

	#vwLPE9pIxvZev4hm {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#N5r1DfopvjC11lWp {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56174963em - var(--ffsd)));
	}

	#cbrdsrvGgor9AVFQ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56174963em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#wRhGvAHLO2UPdJVv {
		min-width: 25.66245453rem;
	}

	#K8i8CthCuAW2RNVt {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#ubW4cp95SqILM3ze {
		grid-area: 5 / 21 / 9 / 27;
		grid-template-columns: 0 12.67971867rem 1.86095089rem 0 25.62585131rem 0;
		grid-template-rows: 0 minmax(4.14282982rem, max-content) minmax(2.0644202rem, max-content) minmax(0.5189512rem, max-content) minmax(1.8106369rem, max-content) minmax(4.14282982rem, max-content);
	}

	#C9SXFJ76ZOq9YNLA {
		grid-area: 5 / 21 / 9 / 27;
		position: relative;
	}

	#sz2N5padSkeqtao1 {
		grid-area: 10 / 5 / 16 / 10;
		position: relative;
	}

	#ouKG2QSRbQ1FNpl5 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75696193em - var(--ffsd)));
	}

	#F24kL3rMx9bV6DWO {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75696193em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#SAmtaymZzgUuj19C {
		min-width: 25.66245453rem;
	}

	#FMbqVJ1UD4kpThwJ {
		grid-area: 12 / 11 / 13 / 15;
		position: relative;
	}

	#LkfhKA5raUua92yA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56174963em - var(--ffsd)));
	}

	#qTRcZhmLjAMOPuFK {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56174963em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#X79zQPuPWaOeSbE0 {
		min-width: 25.66245453rem;
	}

	#EUF1plnsAfhFY5RZ {
		grid-area: 14 / 12 / 15 / 16;
		position: relative;
	}

	#pFXXZ92FZMvZaXz8 {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#CxXylKhIAu0bJefE {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75696193em - var(--ffsd)));
	}

	#tkVhyhsakD1AvZFY {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75696193em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#PXhSK1K0HwBWdIqu {
		min-width: 25.66245453rem;
	}

	#UVCSVdyuM0GGAXVs {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#l6ITaRwTVeHdFnXq {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56174963em - var(--ffsd)));
	}

	#di4U2TujvKayvhoE {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56174963em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#JJWbfo5XyQBWA34h {
		min-width: 25.66245453rem;
	}

	#LWy405G9NYzJL9s0 {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#NZGOgBKl8st2sJvf {
		grid-area: 11 / 22 / 17 / 28;
		grid-template-columns: 0 12.67971867rem 1.86095089rem 0 25.62585131rem 0;
		grid-template-rows: 0 minmax(4.14282982rem, max-content) minmax(2.0644202rem, max-content) minmax(0.5189512rem, max-content) minmax(1.8106369rem, max-content) minmax(4.14282982rem, max-content);
	}

	#hg1OQdqVYKdTpgJ6 {
		grid-area: 11 / 22 / 17 / 28;
		position: relative;
	}

	#EwKJj23G47qe68By {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#tCQQkKsjqoO3PcjD {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75696193em - var(--ffsd)));
	}

	#wrAhpxnibQN5XUU9 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75696193em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#qbKRJZpydbRkKZTi {
		min-width: 25.66245453rem;
	}

	#EQ7LVJMnL1stNRz9 {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#qCVKgoHQmemlevzc {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56174963em - var(--ffsd)));
	}

	#rkHryV8anWx6KcaB {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56174963em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#NOstk5h63Mb5TskG {
		min-width: 25.66245453rem;
	}

	#P27AihfcFDm7QolQ {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#SRp2YwMGW6KaXDqO {
		grid-area: 18 / 6 / 20 / 17;
		grid-template-columns: 0 12.67971867rem 1.86095089rem 0 25.62585131rem 0;
		grid-template-rows: 0 minmax(4.14282982rem, max-content) minmax(2.0644202rem, max-content) minmax(0.5189512rem, max-content) minmax(1.8106369rem, max-content) minmax(4.14282982rem, max-content);
	}

	#n3fwGSOdmcnKv76e {
		grid-area: 18 / 6 / 20 / 17;
		position: relative;
	}

	#QwdmCYYrcB9VsoEs {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#ro5hkpCIhXWtLKAk {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75696193em - var(--ffsd)));
	}

	#nO52FgQ0qwqpzMAV {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75696193em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#tcIDBuAJ0zGrAvGU {
		min-width: 25.66245453rem;
	}

	#ak4Oe9anQe2Mqjfh {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#DbDreVUMXOPDATC9 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56174963em - var(--ffsd)));
	}

	#IY332BYlxOCtW5Tz {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56174963em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Ypqp8yRB1YpgaSeB {
		min-width: 25.66245453rem;
	}

	#PEd3GzvZJTdwOH13 {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#GYVW5frJXIOIEqLY {
		grid-area: 19 / 23 / 21 / 29;
		grid-template-columns: 0 12.67971867rem 1.86095089rem 0 25.62585131rem 0;
		grid-template-rows: 0 minmax(4.14282982rem, max-content) minmax(2.0644202rem, max-content) minmax(0.5189512rem, max-content) minmax(1.8106369rem, max-content) minmax(4.14282982rem, max-content);
	}

	#FvOvPrzOppzjtJfX {
		grid-area: 19 / 23 / 21 / 29;
		position: relative;
	}

	#lzsWmCKR22NVpshS {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#S5Q960MpVpDBtBWM {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75696193em - var(--ffsd)));
	}

	#l88ff6BreOyRZtkh {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75696193em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#bpRWcRq4en3NAnPE {
		min-width: 25.66245453rem;
	}

	#a2wC9qJJyef20GhG {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#MNMuiPNzwRISHc8i {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56174963em - var(--ffsd)));
	}

	#bvqwQ1cXjbvHUCUd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56174963em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#x1iKD5GVybBnVqmr {
		min-width: 25.66245453rem;
	}

	#UtvVMqVRRA3ZTwkM {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#helZKrihA0xCp3xD {
		grid-area: 22 / 8 / 24 / 20;
		grid-template-columns: 0 12.67971867rem 1.86095089rem 0 25.62585131rem 0;
		grid-template-rows: 0 minmax(4.14282982rem, max-content) minmax(2.0644202rem, max-content) minmax(0.5189512rem, max-content) minmax(1.8106369rem, max-content) minmax(4.14282982rem, max-content);
	}

	#DW1PWvIrj05MYTzA {
		grid-area: 22 / 8 / 24 / 20;
		position: relative;
	}

	#eaYJnouYhH8AeTXs {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#isxsKvHrpBiedV0f {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75696193em - var(--ffsd)));
	}

	#UoamxoILsO10EG5q {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75696193em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#tgdsaEtbxSUDVtjW {
		min-width: 25.66245453rem;
	}

	#lPBGxfXq4O81nS1I {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#K2Xke32ojoiwTvRX {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56174963em - var(--ffsd)));
	}

	#addOTvYUpqkiGMBY {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56174963em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#cn95RVlW3BppVxbB {
		min-width: 25.66245453rem;
	}

	#qt6Fy2LMKhfmHiWO {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#CcYiAMGi67m1j3jt {
		grid-area: 23 / 25 / 25 / 31;
		grid-template-columns: 0 12.67971867rem 1.86095089rem 0 25.62585131rem 0;
		grid-template-rows: 0 minmax(4.14282982rem, max-content) minmax(2.0644202rem, max-content) minmax(0.5189512rem, max-content) minmax(1.8106369rem, max-content) minmax(4.14282982rem, max-content);
	}

	#bKS3hojd0vWCsG3G {
		grid-area: 23 / 25 / 25 / 31;
		position: relative;
	}

	#LCgfLLbcF7R1C5Ky {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#RRlSofbn2exyjhSo {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75696193em - var(--ffsd)));
	}

	#pIV69ietZi13B1dT {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75696193em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#K1yEwqXyFsPf1HK2 {
		min-width: 25.66245453rem;
	}

	#yGXLNKXlE0Lx4hng {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#Z1FtDDBo5h4wMoRV {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56174963em - var(--ffsd)));
	}

	#Y6bPOOQDE0l0RJ3I {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56174963em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#oJGsidyPS9GBmdjA {
		min-width: 25.66245453rem;
	}

	#WCYVRqjPYrdeqHy2 {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#MCREdEQaeq1a3hMP {
		grid-area: 26 / 7 / 28 / 18;
		grid-template-columns: 0 12.67971867rem 1.86095089rem 0 25.62585131rem 0;
		grid-template-rows: 0 minmax(4.14282982rem, max-content) minmax(2.0644202rem, max-content) minmax(0.5189512rem, max-content) minmax(1.8106369rem, max-content) minmax(4.14282982rem, max-content);
	}

	#QLcfpqVrH3H1mvpy {
		grid-area: 26 / 7 / 28 / 18;
		position: relative;
	}

	#mU73NrSzR8LyStZH {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#u4CN5ZtTl2KDneKG {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75696193em - var(--ffsd)));
	}

	#WvRywGLPll6BU7wk {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75696193em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Zzvv5ImtUTITuzQn {
		min-width: 25.66245453rem;
	}

	#xBm3VRHfGheG2x42 {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#LnpNv0pmu6wvkbvS {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56174963em - var(--ffsd)));
	}

	#UMsUl7dcFKO4BRG1 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56174963em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#BX9VZoh3BmRroik7 {
		min-width: 25.66245453rem;
	}

	#FvMjt8b0eWx8TBYx {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#sZHhnQgWODExXYwG {
		grid-area: 27 / 24 / 29 / 30;
		grid-template-columns: 0 12.67971867rem 1.86095089rem 0 25.62585131rem 0;
		grid-template-rows: 0 minmax(4.14282982rem, max-content) minmax(2.0644202rem, max-content) minmax(0.5189512rem, max-content) minmax(1.8106369rem, max-content) minmax(4.14282982rem, max-content);
	}

	#otiEaCfYPF6XZBOs {
		grid-area: 27 / 24 / 29 / 30;
		position: relative;
	}

	#pzMKaFw2GamEqzIy {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#hNUWSf5vHG23PS3z {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75696193em - var(--ffsd)));
	}

	#TQtVepH5l0TIWSzI {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75696193em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#vYc7XNQkg7SIdiVs {
		min-width: 25.66245453rem;
	}

	#cctJXIHRRhmwZh6O {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#aUveYXKfXDgnTBey {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.56174963em - var(--ffsd)));
	}

	#piQVsorWHZwkaZOq {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.56174963em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#YER3aBDStQlIEHTA {
		min-width: 25.66245453rem;
	}

	#ajwEh7aIxhCT1eVz {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#ViYwSgKN4trQebbn {
		grid-area: 30 / 4 / 31 / 14;
		grid-template-columns: 0 12.67971867rem 1.86095089rem 0 25.62585131rem 0;
		grid-template-rows: 0 minmax(4.14282982rem, max-content) minmax(2.0644202rem, max-content) minmax(0.5189512rem, max-content) minmax(1.8106369rem, max-content) minmax(4.14282982rem, max-content);
	}

	#r5IyhZrlvmrMTAxN {
		grid-area: 30 / 4 / 31 / 14;
		position: relative;
	}

	#n4a0cHUfK1HSTCGF {
		grid-template-columns: 5.62225476rem 0.83037508rem 0 0.06274352rem 0 0 0.06274352rem 12.55423164rem 0.06274352rem 1.86095089rem 0 0.06274352rem 25.50036428rem 0.06274352rem 0 0 0 0.06274352rem 0 6.63621156rem 0.06274352rem 0 0 0.06274352rem 9.40284845rem 30.63818538rem 0.06274352rem 0 0 0.06274352rem 6.32714281rem;
		grid-template-rows: minmax(4.83162518rem, max-content) minmax(4.09956076rem, max-content) minmax(3.01716711rem, max-content) 0 minmax(4.14282982rem, max-content) minmax(4.3940083rem, max-content) minmax(4.14282982rem, max-content) 0 minmax(3.00146413rem, max-content) 0 minmax(4.14282982rem, max-content) minmax(2.0644202rem, max-content) minmax(0.5189512rem, max-content) minmax(1.8106369rem, max-content) minmax(4.14282982rem, max-content) 0 minmax(3.00146413rem, max-content) 0 minmax(12.67966795rem, max-content) 0 minmax(3.00146413rem, max-content) 0 minmax(12.67966795rem, max-content) 0 minmax(3.00146413rem, max-content) 0 minmax(12.67966795rem, max-content) 0 minmax(3.00146413rem, max-content) minmax(12.67966795rem, max-content) minmax(8.38652357rem, max-content);
	}

	#wX2VRa0JC2UPOxM0 {
		min-height: calc(23.30781011 * var(--1vh, 1vh));
	}

	#S8kA0ZtQhjOQOKwj {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#YnBX1w065wi4uN4L {
		grid-template-columns: 0 100%;
		grid-template-rows: 0 100%;
	}

	#BIyN9FNt9OkkECvl {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.85206109em - var(--ffsd)));
	}

	#RwCegoRE1rKV4jS1 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.85206109em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.01));
		margin-top: calc(var(--first-font-size) * 0.05);
		margin-bottom: calc(var(--last-font-size) * 0.05);
	}

	#KhQaKh5oM3fMkcAn {
		min-width: 28.61140333rem;
	}

	#NFmxBSeb5ywYlty6 {
		grid-area: 2 / 2 / 3 / 4;
		position: relative;
	}

	#kj3ETsDcUZlOhSvk {
		display: block;
	}

	#MsSDKJfvbQdX1ZDD {
		display: none;
	}

	#t9ODRQGA8cKjRez5 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#ZuDkwwAUgk04AJ2w {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#CF1kYwEbzZQkz1dz {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#gZibwNpUF5S5So4n {
		grid-template-columns: 0 0.62555757rem 18.2329599rem 0.62555757rem;
		grid-template-rows: 0 minmax(0.62559404rem, max-content) minmax(18.23288697rem, max-content) minmax(0.62559404rem, max-content);
	}

	#DGdFCTYYFCGRfuLt {
		grid-template-columns: 0 19.48407504rem;
		grid-template-rows: 0 minmax(19.48407504rem, max-content);
	}

	#fxObjboe2MIoW7di {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.04978038em - var(--ffsd)));
	}

	#W9H8dVYPp2W8dmjw {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.04978038em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#gEQRasMlCYlwIzfa {
		min-width: 25.46260414rem;
	}

	#QFPWF4UKRxWTTLdT {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#SeypdGqfELB5RHl9 {
		grid-area: 4 / 3 / 7 / 5;
		grid-template-columns: 0 2.21738879rem 2.32797374rem 19.48407504rem 3.61395214rem 0.93141039rem;
		grid-template-rows: 0 minmax(2.38197446rem, max-content) minmax(19.48407504rem, max-content) minmax(2.3211677rem, max-content) minmax(2.44509517rem, max-content) minmax(9.10328731rem, max-content);
	}

	#XIFNPdWiGGPXVg6A {
		grid-area: 4 / 3 / 7 / 5;
		position: relative;
	}

	#CKMIPKAXamZbG3Go {
		display: block;
	}

	#k86WiYPZcta16CE9 {
		display: none;
	}

	#i7KgHSW2144mi1Li {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#AUVv7WdTmylWb1yh {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#UQoc6JyZzEgKohPS {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#BPky5TaRtu6hUNtl {
		grid-template-columns: 0 0.62555757rem 18.2329599rem 0.62555757rem;
		grid-template-rows: 0 minmax(0.62559404rem, max-content) minmax(18.23288697rem, max-content) minmax(0.62559404rem, max-content);
	}

	#fvh4EVCDc8wFidp6 {
		grid-template-columns: 0 19.48407504rem;
		grid-template-rows: 0 minmax(19.48407504rem, max-content);
	}

	#BTHywGdE1TJza1E2 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.04978038em - var(--ffsd)));
	}

	#lVDcLMcaMgEIaSmp {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.04978038em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#O59aprSV0Sz3FX7f {
		min-width: 25.46260414rem;
	}

	#MJ7fklYwLMVRwUVd {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#SmtITIiN4OkX9KGF {
		grid-area: 5 / 6 / 8 / 7;
		grid-template-columns: 0 1.57439959rem 2.97096294rem 19.48407504rem 2.97096294rem 1.57439959rem;
		grid-template-rows: 0 minmax(2.38197446rem, max-content) minmax(19.48407504rem, max-content) minmax(1.80872261rem, max-content) minmax(5.30014641rem, max-content) minmax(6.76068116rem, max-content);
	}

	#njrbbX2MIZ8ACucA {
		grid-area: 5 / 6 / 8 / 7;
		position: relative;
	}

	#BJ56qU9IrfHKWEXS {
		display: block;
	}

	#fP9uOK7sNZdwTEie {
		display: none;
	}

	#ONnBplxbFRBxwQWD {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#OyJrHIjj9L40dH67 {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#nFBTSV5Qg3f3G0yu {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#DJumzARuTyfZ5TcT {
		grid-template-columns: 0 0.62555757rem 18.2329599rem 0.62555757rem;
		grid-template-rows: 0 minmax(0.62559404rem, max-content) minmax(18.23288697rem, max-content) minmax(0.62559404rem, max-content);
	}

	#QYluzNfhXHF1wj7k {
		grid-template-columns: 0 19.48407504rem;
		grid-template-rows: 0 minmax(19.48407504rem, max-content);
	}

	#Y5R0b5dzA8Q6in1f {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.04978038em - var(--ffsd)));
	}

	#UL33FtBMoc9uKClH {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.04978038em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.01));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#xIQ052HO4W1UWtzn {
		min-width: 25.46260414rem;
	}

	#EjvTHHYfq6J4DxNd {
		grid-area: 5 / 3 / 6 / 6;
		position: relative;
	}

	#rhBP5TtNEKB13PR5 {
		grid-area: 6 / 8 / 9 / 9;
		grid-template-columns: 0 1.57439959rem 2.97096294rem 19.48407504rem 2.97096294rem 1.57439959rem;
		grid-template-rows: 0 minmax(2.38197446rem, max-content) minmax(19.48407504rem, max-content) minmax(1.66230972rem, max-content) minmax(8.15519766rem, max-content) minmax(4.0520428rem, max-content);
	}

	#wP5w8HIdiGqngKxo {
		grid-area: 6 / 8 / 9 / 9;
		position: relative;
	}

	#ylGXbSvVUSJkKvSi {
		grid-template-columns: 5.60848362rem 0 28.5748001rem 0 1.54532543rem 28.5748001rem 1.51330701rem 28.5748001rem 5.60848362rem;
		grid-template-rows: minmax(3.46958917rem, max-content) minmax(4.58119373rem, max-content) minmax(3.65139194rem, max-content) 0 0 minmax(35.73559968rem, max-content) 0 0 minmax(5.27086384rem, max-content);
	}

	#trPu9NDNn3GsxH2i {
		min-height: calc(11.02603369 * var(--1vh, 1vh));
	}

	#kHTr4UMRKuBnmI2s {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#Ss1yn1mETT9jLgzw {
		grid-template-columns: 0 100%;
		grid-template-rows: 0 100%;
	}

	#yOyv0g931HWP8K5w {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.89038067em - var(--ffsd)));
	}

	#adtT21cbrs3OWsBi {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(4.89038067em - var(--ffsd)));
	}

	#U2o7tnUjIVGm7rT4 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 4.89038067em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#zVyrooSEco4MLXWd {
		min-width: 38.96503624rem;
	}

	#Ty2xMlXlERNrWhyE {
		grid-area: 2 / 2 / 3 / 22;
		position: relative;
	}

	#oqCLFGq0U0ONLB4j {
		stroke-width: calc(100rem * 6.0 / 1366.0);
	}

	#Z79YFQyvL6A7jGzp {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#Z5ZTkUL0Ig3DZLjq {
		font-size: calc(2.53782577em - var(--ffsd));
	}

	#GdcocGV83UV2GJAx {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#s8AJLcuBl5zakdRa {
		min-width: 7.72933389rem;
	}

	#DI5Uu8GnvsTdMf3D {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#T8d5WFIxemZpAfwI {
		grid-template-columns: 0 0.5124451rem 7.69273067rem 0.5124451rem;
		grid-template-rows: 0 minmax(0.5124451rem, max-content) minmax(4.29044431rem, max-content) minmax(0.5124451rem, max-content);
	}

	#n7J1efHR2vqf1rmi {
		grid-area: 4 / 4 / 15 / 9;
		grid-template-columns: 0 8.71762086rem;
		grid-template-rows: 0 minmax(5.3153345rem, max-content);
	}

	#lBKAzfbKtQiOdvnt {
		grid-area: 4 / 4 / 15 / 9;
		position: relative;
	}

	#fd7DnM6VGWagSWZz {
		stroke-width: calc(100rem * 5.999999999999999 / 1366.0);
	}

	#ZkEx1ZIMnCrNc8xV {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#VZFNOjVakBwpupN5 {
		font-size: calc(2.53782577em - var(--ffsd));
	}

	#ZlhIdLtFTKa2t6CN {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#p1jBVyW4SVentMnI {
		min-width: 7.72933389rem;
	}

	#QwE6X2n2GBgM7VkT {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#OGSoeKRLoF8GZsJ0 {
		grid-template-columns: 0 0.5124451rem 7.69273067rem 0.5124451rem;
		grid-template-rows: 0 minmax(0.5124451rem, max-content) minmax(4.28560397rem, max-content) minmax(0.5124451rem, max-content);
	}

	#FdjeAv9k94ie8Hm7 {
		grid-area: 5 / 13 / 14 / 23;
		grid-template-columns: 0 8.71762086rem;
		grid-template-rows: 0 minmax(5.31049416rem, max-content);
	}

	#GtJfgCuMkrhj1Q8V {
		grid-area: 5 / 13 / 14 / 23;
		position: relative;
	}

	#OgCJtlbWkRiQYND9 {
		stroke-width: calc(100rem * 5.999999999999999 / 1366.0);
	}

	#yp9yqr9m3tlFfmJE {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#WcJrTy8ZzZLbIWkM {
		font-size: calc(2.53782577em - var(--ffsd));
	}

	#u2WknSR2ixrKnkck {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#kKel268v0eLrEjK5 {
		min-width: 7.72933389rem;
	}

	#VuraA5m92j3CCFSt {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#C98T03WjsWcp9Naq {
		grid-template-columns: 0 0.5124451rem 7.69273067rem 0.5124451rem;
		grid-template-rows: 0 minmax(0.5124451rem, max-content) minmax(4.28560397rem, max-content) minmax(0.5124451rem, max-content);
	}

	#n17msLyZGzQZY6fS {
		grid-area: 6 / 28 / 16 / 36;
		grid-template-columns: 0 8.71762086rem;
		grid-template-rows: 0 minmax(5.31049416rem, max-content);
	}

	#NiwwiVOybqaY1G8L {
		grid-area: 6 / 28 / 16 / 36;
		position: relative;
	}

	#GhJoiNdyc2YK5h9U {
		grid-area: 7 / 20 / 11 / 29;
		position: relative;
	}

	#sl7v8yV5XWSPHbhU {
		grid-area: 8 / 6 / 12 / 14;
		position: relative;
	}

	#Nrsym2i8EB1IfRhY {
		grid-area: 9 / 40 / 31 / 43;
		position: relative;
	}

	#uCXPiiT1S0JJBJ5s {
		grid-area: 10 / 34 / 13 / 41;
		position: relative;
	}

	#lIBf666LxGtI2SbB {
		font-size: calc(2.53782577em - var(--ffsd));
	}

	#aWqmrTrjK6vno2h5 {
		--first-font-size: 2.53782577em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#p58b2GPPiCvdMdNp {
		min-width: 20.20497804rem;
	}

	#uQtFKQYrNz0iB93Z {
		grid-area: 17 / 3 / 22 / 11;
		position: relative;
	}

	#e6wXpRmulXDPT0u0 {
		font-size: calc(2.53782577em - var(--ffsd));
	}

	#fDc8qWFKZszTq4Yj {
		--first-font-size: 2.53782577em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#kzR5Fx3oJ6GLRhRT {
		min-width: 20.20497804rem;
	}

	#johfP1Pxott9vEEM {
		grid-area: 18 / 15 / 20 / 26;
		position: relative;
	}

	#TmT1hYbB6GVMF1ld {
		font-size: calc(2.53782577em - var(--ffsd));
	}

	#ZXPctumbRe0FlOvq {
		--first-font-size: 2.53782577em;
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#wRVdpMXlteyerwko {
		min-width: 21.16539527rem;
	}

	#QfubwHlgOUkIDLKo {
		grid-area: 19 / 30 / 23 / 38;
		position: relative;
	}

	#mE358ak8TuBfPFmW {
		font-size: calc(1.56173499em - var(--ffsd));
	}

	#FZfpR6RMLnUi7Vdn {
		--first-font-size: 1.56173499em;
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#Ai0HO0vCRAKv5G3L {
		min-width: 19.8306565rem;
	}

	#gGj211OtpCfkR6kL {
		grid-area: 21 / 16 / 24 / 25;
		position: relative;
	}

	#uS6qp7fPuFhX50yO {
		stroke-width: calc(100rem * 5.999999999999999 / 1366.0);
	}

	#vocqBlV3eDAZhjKe {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#zK3sCCVcD7dmKKm2 {
		font-size: calc(2.53782577em - var(--ffsd));
	}

	#KbIIjrYbW7veptRY {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#mFh0wHMPNvn6BCF5 {
		min-width: 7.72933389rem;
	}

	#QyApyhorWpS58HkO {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#ox3LXDzAhHnOZYgN {
		grid-template-columns: 0 0.5124451rem 7.69273067rem 0.5124451rem;
		grid-template-rows: 0 minmax(0.5124451rem, max-content) minmax(4.28560397rem, max-content) minmax(0.5124451rem, max-content);
	}

	#T82aFK7WnfXqzfpW {
		grid-area: 25 / 5 / 35 / 10;
		grid-template-columns: 0 8.71762086rem;
		grid-template-rows: 0 minmax(5.31049416rem, max-content);
	}

	#OO4yQCeKx191j6uX {
		grid-area: 25 / 5 / 35 / 10;
		position: relative;
	}

	#LqaJvKuc3Dk7x7QU {
		stroke-width: calc(100rem * 5.999999999999999 / 1366.0);
	}

	#orI8ZDwTqchi8NYh {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#d8nsYxyPh7lcHoTK {
		font-size: calc(2.53782577em - var(--ffsd));
	}

	#ELugkCrhsZAmxoaj {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#ueRrGfE4wVgueoGt {
		min-width: 7.72933389rem;
	}

	#aXZUTVOOdAdtC3Vw {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#XxiLMbXAvwIanOG5 {
		grid-template-columns: 0 0.5124451rem 7.69273067rem 0.5124451rem;
		grid-template-rows: 0 minmax(0.5124451rem, max-content) minmax(4.28560397rem, max-content) minmax(0.5124451rem, max-content);
	}

	#tS81KE5Rcn2QKSxO {
		grid-area: 26 / 31 / 36 / 37;
		grid-template-columns: 0 8.71762086rem;
		grid-template-rows: 0 minmax(5.31049416rem, max-content);
	}

	#S7qU5KJYk1eVtJA5 {
		grid-area: 26 / 31 / 36 / 37;
		position: relative;
	}

	#KrqNnFZ0UIxhION3 {
		stroke-width: calc(100rem * 5.999999999999999 / 1366.0);
	}

	#OsISqtUJstEsYRfG {
		grid-area: 2 / 2 / 5 / 5;
		position: relative;
	}

	#WscMkLBpM8dD8UiW {
		font-size: calc(2.53782577em - var(--ffsd));
	}

	#RKcG0Qp5pw5GyuIV {
		transform: translateX(calc(var(--first-font-size) * -0.02));
	}

	#BNLm32gEMenNBav0 {
		min-width: 7.72933389rem;
	}

	#EN1LHn87g78DtoPK {
		grid-area: 3 / 3 / 4 / 4;
		position: relative;
	}

	#nTOFQixz6fgxoAav {
		grid-template-columns: 0 0.5124451rem 7.69273067rem 0.5124451rem;
		grid-template-rows: 0 minmax(0.5124451rem, max-content) minmax(4.28560397rem, max-content) minmax(0.5124451rem, max-content);
	}

	#CtnzpmscK8aNO1rt {
		grid-area: 27 / 17 / 37 / 24;
		grid-template-columns: 0 8.71762086rem;
		grid-template-rows: 0 minmax(5.31049416rem, max-content);
	}

	#mRfiAAgVSN6DXzlS {
		grid-area: 27 / 17 / 37 / 24;
		position: relative;
	}

	#q08wngjnlMogU6O7 {
		grid-area: 28 / 35 / 32 / 42;
		position: relative;
	}

	#meBqLPJvjQ8guX8N {
		grid-area: 29 / 8 / 33 / 19;
		position: relative;
	}

	#yT6e7k3zwC2ZhEZB {
		grid-area: 30 / 21 / 34 / 32;
		position: relative;
	}

	#bjiwT6n0eEvP3AL2 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.53782577em - var(--ffsd)));
	}

	#XgZ35s1n4uX7dHq7 {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.53782577em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#LnWGuNxmhGGIADzm {
		min-width: 20.20497804rem;
	}

	#bLIpGnn9dfE3fTZL {
		grid-area: 38 / 7 / 41 / 12;
		position: relative;
	}

	#ZAT3ONIo6ol1EBRK {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.53782577em - var(--ffsd)));
	}

	#KlNpbQ0JZO8toHNj {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.53782577em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#Xn2JAL6I0PxjoTrM {
		min-width: 20.20497804rem;
	}

	#sAebCSIQptDj5wa4 {
		grid-area: 39 / 18 / 42 / 27;
		position: relative;
	}

	#kgS5Q5htFjObvksW {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.53782577em - var(--ffsd)));
	}

	#BWJudQgPUxg1KJdG {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.53782577em);
		--last-font-size: var(--first-font-size);
		margin-top: 0;
		margin-bottom: 0;
	}

	#l1oqJzovwzrxiZCh {
		min-width: 20.20497804rem;
	}

	#MKXkDi6tk3E1t8T7 {
		grid-area: 40 / 33 / 43 / 39;
		position: relative;
	}

	#GhY6Ca0LFKImBmOZ {
		grid-template-columns: 5.3331465rem 9.98424431rem 0.77556371rem 0 0.03818594rem 0.84903657rem 4.39187944rem 3.43851891rem 0 10.67519025rem 1.66278622rem 1.12326369rem 0 0.14423179rem 0 0.74299072rem 0.29053872rem 0.55412055rem 1.59080832rem 2.24695057rem 0.42012332rem 2.72785686rem 0.88722251rem 10.3334417rem 0.37432154rem 1.03352945rem 1.75048298rem 0 0.14424526rem 0.74297725rem 0.21743997rem 0 3.43146221rem 0.96042104rem 3.22107513rem 0.88722251rem 11.66819393rem 0 1.89472443rem 0.00000381rem 0.07320263rem 0.07320644rem 15.31739081rem;
		grid-template-rows: minmax(4.22916837rem, max-content) minmax(10.64075458rem, max-content) minmax(5.20264105rem, max-content) 0 minmax(0.02276916rem, max-content) minmax(2.15663605rem, max-content) minmax(0.00000002rem, max-content) minmax(0.00772533rem, max-content) minmax(0.00864003rem, max-content) minmax(0.13204722rem, max-content) minmax(0.00431541rem, max-content) minmax(0.01101977rem, max-content) minmax(2.96734117rem, max-content) minmax(0.00484034rem, max-content) minmax(0.01792882rem, max-content) minmax(1.7390258rem, max-content) 0 0 minmax(3.00146413rem, max-content) minmax(0.86630924rem, max-content) minmax(2.13515488rem, max-content) 0 minmax(1.79847176rem, max-content) minmax(6.48536509rem, max-content) 0 minmax(0.10928328rem, max-content) minmax(2.10522926rem, max-content) 0 0 minmax(0.07417595rem, max-content) minmax(0.07223693rem, max-content) minmax(0.00096951rem, max-content) 0 minmax(2.94859922rem, max-content) 0 minmax(0.10928328rem, max-content) minmax(1.67125363rem, max-content) 0 0 minmax(3.00146413rem, max-content) minmax(3.00146413rem, max-content) 0 minmax(4.03957619rem, max-content);
	}

	#gZet15UmlwsvfI1A {
		min-height: calc(12.25114855 * var(--1vh, 1vh));
	}

	#jRCRvtwjSMdf7dIv {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#rUzZEQ49fMRslBaD {
		grid-template-columns: 0 100%;
		grid-template-rows: 0 100%;
	}

	#pERlgE2a6DOamg6l {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(3.70912884em - var(--ffsd)));
	}

	#BEYU8ezFNskJdWkQ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 3.70912884em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#IbJmVidWel9B0IPZ {
		min-width: 52.45625401rem;
	}

	#T4fZl6W85JwWkmDH {
		grid-area: 2 / 3 / 3 / 8;
		position: relative;
	}

	#rYbzezNQVmwN8rwy {
		stroke-width: calc(100rem * 2.0 / 1366.0);
	}

	#PDFkidM4k8ElFqJF {
		display: block;
	}

	#MUtTzzmY3wYNkqu9 {
		stroke-width: calc(100rem * 2.0 / 1366.0);
	}

	#QtX0JYR7w7rndoZ5 {
		display: none;
	}

	#OTGewPxk07Zd90R6 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#oncK24U5jVqFCrdR {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.54221083em - var(--ffsd)));
	}

	#ZDFzxS6yqsQjtUYd {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.54221083em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#zbMFIGzzq8ZFzfcn {
		min-width: 25.65885798rem;
	}

	#zQSnqfE5AUwppsqQ {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#ONx5rCSDHvJt6fyo {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.46608346em - var(--ffsd)));
	}

	#hfaM6HCpkz1KTcrg {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.46608346em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#LTgRR7rIEa4qPPSg {
		min-width: 25.65885798rem;
	}

	#IdK3IYQ5gJ2E9vGc {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#wue0L8NHiWgYHjue {
		grid-area: 4 / 2 / 7 / 4;
		grid-template-columns: 0 1.28111274rem 0 25.62225476rem 0 1.28111274rem;
		grid-template-rows: 0 minmax(11.69496191rem, max-content) minmax(1.81454466rem, max-content) minmax(2.96157706rem, max-content) minmax(6.51537335rem, max-content) minmax(11.69496191rem, max-content);
	}

	#lvxty16L5kF1dCCb {
		grid-area: 4 / 2 / 7 / 4;
		position: relative;
	}

	#YjTkzhc6SZ0L6Avv {
		stroke-width: calc(100rem * 2.0 / 1366.0);
	}

	#AdUikw940oRh1NmJ {
		display: block;
	}

	#vwWQnIHe3A9wyvst {
		stroke-width: calc(100rem * 2.0 / 1366.0);
	}

	#kOCJPLER4qSmdRM7 {
		display: none;
	}

	#l88C7xESGFGWgt0W {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#cFsKtSFex865II4H {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.54221083em - var(--ffsd)));
	}

	#HHbP8BOU0jGyE06x {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.54221083em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#a1LUNrAChs6Vb2uV {
		min-width: 25.65885798rem;
	}

	#U6h52VD3Dj4WZdWw {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#eER1JQoWpDTt7q37 {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.46608346em - var(--ffsd)));
	}

	#UJ961bvHYI3OriqG {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.46608346em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#TGSKVP6ICt7lSThL {
		min-width: 25.65885798rem;
	}

	#btkfbwdo0qn6m1Zt {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#RMgCheHCmyErjAQe {
		grid-area: 5 / 5 / 8 / 6;
		grid-template-columns: 0 1.28111274rem 0 25.62225476rem 0 1.28111274rem;
		grid-template-rows: 0 minmax(11.69496191rem, max-content) minmax(1.81454466rem, max-content) minmax(2.96157706rem, max-content) minmax(6.51537335rem, max-content) minmax(11.69496191rem, max-content);
	}

	#Yfrnbcageco4Gcuq {
		grid-area: 5 / 5 / 8 / 6;
		position: relative;
	}

	#DIu6XcaVsIuQQPBK {
		stroke-width: calc(100rem * 2.0 / 1366.0);
	}

	#GcQ4LsbvJsRrMHSn {
		display: block;
	}

	#K1TmHAQ3NTsS6CIe {
		stroke-width: calc(100rem * 2.0 / 1366.0);
	}

	#Q8N5fGVL069S5m2o {
		display: none;
	}

	#sjN2e2nPj0nSVyq7 {
		grid-area: 2 / 2 / 7 / 7;
		position: relative;
	}

	#CBHM7m8mUtjuOrEs {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.54221083em - var(--ffsd)));
	}

	#XXZxi89lGk9b8ejT {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.54221083em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * 0.036));
		margin-top: calc(var(--first-font-size) * -0.1);
		margin-bottom: calc(var(--last-font-size) * -0.1);
	}

	#SLjhKolKiVzXVrsT {
		min-width: 25.65885798rem;
	}

	#hYLhMa3KXE2s1Jbu {
		grid-area: 3 / 3 / 4 / 5;
		position: relative;
	}

	#yNzssimMPjkn4EDf {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(5.46608346em - var(--ffsd)));
	}

	#P1ktvJOBoIXGIm5k {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 5.46608346em);
		--last-font-size: var(--first-font-size);
		transform: translateX(calc(var(--first-font-size) * -0.0205));
		margin-top: 0;
		margin-bottom: 0;
	}

	#TNKIZVhxUC29gE96 {
		min-width: 25.65885798rem;
	}

	#UYh7MZfXbNvpGa1x {
		grid-area: 5 / 4 / 6 / 6;
		position: relative;
	}

	#bUFcaRcAwsloJUS1 {
		grid-area: 6 / 7 / 9 / 9;
		grid-template-columns: 0 1.28111274rem 0 25.62225476rem 0 1.28111274rem;
		grid-template-rows: 0 minmax(11.69496191rem, max-content) minmax(1.81454466rem, max-content) minmax(2.96157706rem, max-content) minmax(6.51537335rem, max-content) minmax(11.69496191rem, max-content);
	}

	#ctNvYifBfbOcadsC {
		grid-area: 6 / 7 / 9 / 9;
		position: relative;
	}

	#GUXT0duiXwA8EfRP {
		grid-template-columns: 5.62225476rem 17.93690306rem 10.24757717rem 2.10102489rem 28.18448023rem 2.10102489rem 9.7855436rem 18.39893663rem 5.62225476rem;
		grid-template-rows: minmax(3.99900025rem, max-content) minmax(4.39238653rem, max-content) minmax(4.01360973rem, max-content) 0 0 minmax(34.6814189rem, max-content) 0 0 minmax(9.13613218rem, max-content);
	}

	#aPvYMDHfcpxuVvZR {
		min-height: calc(11.7611026 * var(--1vh, 1vh));
	}

	#iPa1wvEJHh7MXB61 {
		grid-area: 2 / 2 / 3 / 3;
		position: relative;
	}

	#L7Ny0815ayHKHP5q {
		grid-template-columns: 0 100%;
		grid-template-rows: 0 100%;
	}

	#jGOsWVsi010iQYBh {
		grid-area: 2 / 2 / 7 / 3;
		position: relative;
	}

	#O8BHPgjUtD0pmoqA {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(2.73303807em - var(--ffsd)));
	}

	#d5AggWJARNgm3nDZ {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 2.73303807em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#O64IhSkVydVH3KqG {
		min-width: 38.75390043rem;
	}

	#dQU1i5c0LdjIwoHb {
		grid-area: 3 / 4 / 4 / 6;
		position: relative;
	}

	#GeGJujlNl6iyMkqN {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.75695461em - var(--ffsd)));
	}

	#H1by1XvAIV7Avdbp {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.75695461em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * 0.1);
		margin-bottom: calc(var(--last-font-size) * 0.1);
	}

	#B7wY3lpXLCMJPR6r {
		min-width: 14.13466258rem;
	}

	#tiOrR4NtAKmPXQ6Z {
		grid-area: 3 / 2 / 4 / 3;
		position: relative;
	}

	#ObHuCP0zAYEk9Y0Q {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.07369693em - var(--ffsd)));
	}

	#H9F8tLkkfPNO9nNe {
		font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), calc(1.07369693em - var(--ffsd)));
	}

	#PejqGcvSTOEQWUub {
		--first-font-size: max(calc(12px * var(--rfso, var(--bfso, 1))), 1.07369693em);
		--last-font-size: var(--first-font-size);
		margin-top: calc(var(--first-font-size) * -0.25);
		margin-bottom: calc(var(--last-font-size) * -0.25);
	}

	#CcbMq47PBBoURPM4 {
		min-width: 22.22590092rem;
	}

	#y8ZVVD6eAwbW5CAN {
		grid-area: 2 / 4 / 5 / 5;
		position: relative;
	}

	#cqrdRbtvLdMhz6JK {
		grid-area: 5 / 5 / 6 / 7;
		grid-template-columns: 0 14.09805936rem 2.42994015rem 22.1892977rem;
		grid-template-rows: 0 minmax(0.25866215rem, max-content) minmax(2.10834553rem, max-content) minmax(2.36700769rem, max-content);
	}

	#TSeRaD7xZMnUgP0y {
		grid-area: 5 / 5 / 6 / 7;
		position: relative;
	}

	#YMYaoqrtVtrsfzDu {
		grid-template-columns: 3.58902316rem 46.49805234rem 4.27704986rem 0 38.71729721rem 0 6.91857744rem;
		grid-template-rows: minmax(5.32201523rem, max-content) minmax(8.29837986rem, max-content) minmax(3.25524597rem, max-content) minmax(4.23566388rem, max-content) minmax(4.73401537rem, max-content) minmax(25.97474725rem, max-content) minmax(4.40248002rem, max-content);
	}

	#nuN8I7IXOi48cTmn {
		min-height: calc(11.7611026 * var(--1vh, 1vh));
	}
}

@keyframes pulse {
	0% {
		background-color: rgba(226, 226, 226, 0.05);
	}

	50% {
		background-color: rgba(226, 226, 226, 0.1);
	}

	100% {
		background-color: rgba(226, 226, 226, 0.05);
	}
}